import { createTheme } from "@mui/material/styles";
import glacialBold from "../fonts/glacialindifference-bold-webfont.woff2";
import glacialRegular from "../fonts/glacialindifference-regular-webfont.woff2";
import glacialItalic from "../fonts/glacialindifference-italic-webfont.woff2"; 

const theme = createTheme({
    palette: {
        primary: {
            light: "#F8A880",
            main: "#F26722",
            dark: "#DF530D",
            contrastText: "#fff",
        },
        secondary: {
            light: "#F8CB9D",
            main: "#F2A254",
            dark: "#EF8C29",
            contrastText: "#FFF",
        },
        tertiary: {
            light: "#868689",
            main: "#2E2E2F",
            dark: "#272728",
            contrastText: "#FFF",
        },

        error: {
            light: "#FFA79C",
            main: "#FF6550",
            dark: "#FF3B22",
        },
        warning: {
            light: "#FFD78D",
            main: "#FFB836",
            dark: "#FFAA0C",
        },
        info: {
            light: "#82CBFF",
            main: "#26A4FF",
            dark: "#0091F9",
        },
        info2: {
            light: "#B4A5FF",
            main: "#7B61FF",
            dark: "#512FFF",
        },
        success: {
            light: "#9FE2D0",
            main: "#56CDAD",
            dark: "#39C09C",
        },

        textColor: {
            brands: "#2E2E2F",
            neutral60: "#7C8493",
            neutral80: "#515B6F",
            neutral100: "#25324B",
        },
        google: {
            main: "#FFFFFF",
        },
    },
    borderColor: '#cfcfcf',
    accent: {
        yellow: "#FFB836",
        green: "#56CDAD",
        red: "#FF6550",
        blue: "#26A4FF",
        purple: "#7B61FF",
    },
    error: {
        red: "#F44336",
    },
    typography: {
        fontFamily: "glacial_indifferenceregular",
    },
    surface: {
        background: "#F8F8FD",
    },
    green: {
        main: "#56CDAD",
    },
    neutral: {
        n100: "#25324B",
        n80: "#515B6F",
        n60: "#7C8493",
        n40: "#A8ADB7",
        n20: "#D6DDEB",
        n10i: "#F8F8FD",
        n0: "#F8F8FD",
        nf: "#FFF",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
      @font-face {
        font-family: 'glacial_indifferencebold';
        src: url(${glacialBold}) format('woff2');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'glacial_indifferenceitalic';
        src: url(${glacialItalic}) format('woff2');
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'glacial_indifferenceregular';
        src: url(${glacialRegular}) format('woff2');
        font-weight: normal;
        font-style: normal;
      }
      `,
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: ({ theme }) => ({
                    color: theme.palette.textColor.neutral80,
                    fontWeight: "600",
                    fontSize: "14px",
                    flex: 1,
                }),
            },
        },
    },
    
});

export default theme;
