import React, { useState } from "react";

// Material UI
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

// Material Icons
import {
  ChatOutlined,
  // DesignServicesOutlined,
  ExpandMore,
  // CheckCircleOutline,
  // CancelOutlined,
  // HelpOutline,
} from "@mui/icons-material";

// Components
// import { MatchRate } from "../../components";
// import trackClickEvent from "../../utils/trackClickEvent";
// import { useFeedback } from "./../../contexts/FeedbackProvider";

function OptimizerDetail({
  data: {
    // job_title,
    // age_match,
    // commonskill_match,
    // education_match,
    // gpa_match,
    // hardskill_match,
    // joblevel_match,
    // jobtitle_match,
    // total_score,
    items,
    params,
  },
}) {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // let [key, value] = Object.entries(params ?? {});
  // let commonskills = Object.entries(params ?? {});

  // const { openFeedback } = useFeedback();
  // console.log(params)
  return (
    <div style={{ marginBottom: "50px" }}>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MatchRate value={total_score} />
      </Box> */}

      {/* Description */}
      {/* <Typography
        sx={{
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "10px",
          color: (theme) => theme.palette.textColor.neutral60,
        }}
      >
        Semakin tinggi nilai kecocokan (MatchRate) CV dan Judul & Deskripsi
        Pekerjaan, maka semakin besar kemungkinan untuk dipanggil ke tahap
        seleksi kerja berikutnya.
      </Typography> */}

      {/* Button Konsultasi CV */}
      <Button
        sx={{ textTransform: "capitalize" }}
        startIcon={<ChatOutlined />}
        variant="contained"
        fullWidth
        disabled
      >
        Konsultasikan CV{" "}
        <span
          style={{
            textTransform: "none",
            fontSize: 10,
            marginLeft: 4,
            fontFamily: "Glacial Indifference",
          }}
        >
          (coming soon)
        </span>
      </Button>

      {/* Panel */}
      <Box sx={{ marginTop: "16px" }}>
        {/* Basic Requirements */}
        <Accordion
          sx={{
            backgroundColor: (theme) => theme.neutral.n10i,
          }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1"
            id="panel1"
          >
            <Typography>Items</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* TOP */}
            <Box
              sx={{
                marginBottom: "10px",
                color: (theme) => theme.neutral.n40,
              }}
            >
              {items?.map((item, idx) => {
                if (params?.useJobCollection) {
                  return (
                    <div
                      key={idx}
                      style={{
                        borderBottom: "1px",
                        borderBottomStyle: "dashed",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          marginBottom: "6px",
                        }}
                      >
                        {item?.jobTitle}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          marginBottom: "10px",
                        }}
                      >
                        {item?.company}
                      </Typography>
                      {item?.salaryRange_min > 0 &&
                      item?.salaryRange_max > 0 ? (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            marginBottom: "6px",
                          }}
                        >
                          {item?.salaryRange_min} - {item?.salaryRange_max}
                        </Typography>
                      ) : null}
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          marginBottom: "10px",
                        }}
                      >
                        {item?.text}
                      </Typography>
                    </div>
                  );
                }

                return (
                  <Typography
                    key={item}
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    {item}
                  </Typography>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* params */}

        <Accordion
          sx={{
            backgroundColor: (theme) => theme.neutral.n10i,
          }}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1"
            id="panel1"
          >
            <Typography>Params</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* TOP */}
            <Box
              sx={{
                marginBottom: "10px",
                color: (theme) => theme.neutral.n40,
              }}
            >
              <FormControl component="fieldset">
                <FormLabel>Pertimbangkan hal berikut:</FormLabel>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    key={params?.useEdu}
                    name={params?.useEdu}
                    value={params?.useEdu}
                    control={<Checkbox checked={params?.useEdu} />}
                    label="Pendidikan"
                    // onChange={handleChecked}
                    // labelPlacement="top"
                    disabled
                  />
                  <FormControlLabel
                    key={params?.useSkill}
                    name={params?.useSkill}
                    value={params?.useSkill}
                    control={<Checkbox checked={params?.useSkill} />}
                    label="Skills"
                    // onChange={handleChecked}
                    // labelPlacement="top"
                    disabled
                  />
                  <FormControlLabel
                    key={params?.useProyek}
                    name={params?.useProyek}
                    value={params?.useProyek}
                    control={<Checkbox checked={params?.useProyek} />}
                    label="Proyek"
                    // onChange={handleChecked}
                    // labelPlacement="top"
                    disabled
                  />
                  <FormControlLabel
                    key={params?.useCourse}
                    name={params?.useCourse}
                    value={params?.useCourse}
                    control={<Checkbox checked={params?.useCourse} />}
                    label="Kursus"
                    // onChange={handleChecked}
                    // labelPlacement="top"
                    disabled
                  />

                  <FormControlLabel
                    key={params?.useAward}
                    name={params?.useAward}
                    value={params?.useAward}
                    control={<Checkbox checked={params?.useAward} />}
                    label="Award"
                    // onChange={handleChecked}
                    // labelPlacement="top"
                    disabled
                  />
                  <FormControlLabel
                    key={params?.useWorkExp}
                    name={params?.useWorkExp}
                    value={params?.useWorkExp}
                    control={<Checkbox checked={params?.useWorkExp} />}
                    label="Pengalaman Kerja"
                    // onChange={handleChecked}
                    // labelPlacement="top"
                    disabled
                  />
                  <FormControlLabel
                    key={params?.useCert}
                    name={params?.useCert}
                    value={params?.useCert}
                    control={<Checkbox checked={params?.useCert} />}
                    label="Sertifikasi"
                    // onChange={handleChecked}
                    // labelPlacement="top"
                    disabled
                  />
                  <FormControlLabel
                    key={params?.useOrg}
                    name={params?.useOrg}
                    value={params?.useOrg}
                    control={<Checkbox checked={params?.useOrg} />}
                    label="Organisasi"
                    // onChange={handleChecked}
                    // labelPlacement="top"
                    disabled
                  />
                  <FormControlLabel
                    key={params?.useSummary}
                    name={params?.useSummary}
                    value={params?.useSummary}
                    control={<Checkbox checked={params?.useSummary} />}
                    label="Summary"
                    // onChange={handleChecked}
                    // labelPlacement="top"
                    disabled
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Button Desain CV */}
      {/* <Button
        sx={{ textTransform: "capitalize", marginTop: "20px" }}
        onClick={() => {
          window.location.href = "https://karir.ai/matchrate-score/";
          trackClickEvent(`MatchRate tips button clicked`);
        }}
        startIcon={<DesignServicesOutlined />}
        variant="contained"
        fullWidth
      >
        Tingkatkan MatchRate
      </Button> */}
    </div>
  );
}

export default OptimizerDetail;
