import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogTitle, FormControlLabel, Grid, Radio, Typography, useTheme, Menu, MenuItem, ListItemIcon, ListItemText, } from "@mui/material";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { useOutletContext, useParams } from "react-router-dom";
import { Navigation } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import allthumbs from "../../assets/cv-thumbs";
import { SwitchCustom } from "../../components";
import { cvsCollectionRef } from "../../contexts/FirebaseProvider";
import banner from '../../assets/icon/banner-cv-maker.png'
// import SendIcon from '@mui/icons-material/Send';
// React Router Dom
import { useLocation, } from "react-router-dom";
import { Article, Download, PictureAsPdf } from "@mui/icons-material";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { functions, useFirebase } from "../../contexts/FirebaseProvider";
import { saveAs } from "file-saver";
import trackClickEvent from "../../utils/trackClickEvent";
import { usePaywall } from "../../contexts/PaywallProvider";
import formatDate from "../../utils/formatDate";
//import axios from "axios";
import { useSnackbar } from "notistack";

export default function DownloadPage() {
    const { enqueueSnackbar } = useSnackbar();
    // const { setBackdropLoading } = useOutletContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const location = useLocation();
    console.log(location.pathname.split('/')?.[3])
    const theme = useTheme();
    const params = useParams();
    const [withFoto, setWithFoto] = useState(false);
    const [design, setDesign] = useState(1);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { basicInfo: data, setBackdropLoading, access } = useOutletContext();
    const ref = doc(cvsCollectionRef, params.cvId);
    const { mutate: updateBasicInfo, isLoading: updateLoading } = useFirestoreDocumentMutation(ref, { merge: true });
    console.log(data)

    // firebase and firestore things
    const { user, openSignInDialog } = useFirebase();
    const { mutate: cvDownload, isLoading: downloading } = useFunctionsCall(functions, 'cv-download');
    const { mutateAsync: purchaseCvAccess, isLoading: purchasing } = useFunctionsCall(functions, 'cv-purchaseCvAccess');
    // paywall
    const { setBuyDialog, setTopUpDialog } = usePaywall();

    useEffect(() => {

        setWithFoto(data?.download?.withFoto ?? false);


        setDesign(data?.download?.design ?? 1);

    }, [data?.download?.withFoto, data?.download?.design]);

    useEffect(() => {
        setBuyDialog(curr => ({ ...curr, loading: purchasing }))
    }, [setBuyDialog, purchasing])

    useEffect(() => {

        setBackdropLoading(downloading);

    }, [downloading, setBackdropLoading])

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }
    const handleOpenDialog = () => {
        setDialogOpen(true);
    }

    const handleSwitchChange = (event) => {
        updateBasicInfo({
            download: {
                withFoto: event.target.checked
            }
        })
    };

    const handleDesignChange = (event) => {

        updateBasicInfo({
            download: {
                design: parseInt(event.target.value)
            }
        }, {
            onSuccess() {
                handleCloseDialog()
            }
        })


    }

    const [setDialogGuide] = useState(false);
    const thumbs = allthumbs?.[data?.lang?.value ?? "ID"] ?? [];
    const designIndex = thumbs?.findIndex((thumb) => thumb.id === design);
    const selectedDesign = thumbs?.[designIndex];

    const handleCloseDownload = () => {
        setAnchorEl(null);
    }
    const handleDownload = (event) => {
        if (!user?.uid) {
            return openSignInDialog();
        }
        setAnchorEl(event.currentTarget);

    }
    const handleDownloadPDF = async () => {
        if (!data?.summary) {
            enqueueSnackbar('isi summary terlebih dahulu', { variant: 'warning' })
        } else {
            cvDownload({
                cvId: params.cvId,
                type: 'pdf'
            },
                {
                    onSuccess(data) {
                        fetch(`data:application/pdf;base64,${data.base64Design}`)
                            .then(res => res.blob())
                            .then((blob) => {
                                saveAs(blob, `${data?.title ?? "cv-download"}.pdf`);
                            })

                    }
                }
            )
        }


        handleCloseDownload()
        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_downloadPDF&email=${userData?.email}`);

        trackClickEvent("Download CV PDF Clicked", {
            cvId: params.cvId,
            type: 'pdf'
        })
    }
    const handleDownloadDocx = async () => {
        if (!data?.summary) {
            enqueueSnackbar('isi summary terlebih dahulu', { variant: 'warning' })
        } else {
            cvDownload({
                cvId: params.cvId,
                type: 'docx'
            },
                {
                    onSuccess(data) {
                        fetch(`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${data.base64Design}`)
                            .then(res => res.blob())
                            .then((blob) => {
                                saveAs(blob, `${data?.title ?? "cv-download"}.docx`);
                            })
                    }
                }
            )
        }

        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_downloadDocx&email=${userData?.email}`);


        trackClickEvent("Download CV DOCX Clicked", {
            cvId: params.cvId,
            type: 'docx'
        })
    }

    const handleBuyDownloadDocx = async () => {
        try {
            const purchaseReq = await purchaseCvAccess({
                cvId: params.cvId,
                type: 'docx'
            })

            if (purchaseReq.status === 'success') {
                handleDownloadDocx();
                setBuyDialog({
                    open: false,
                    name: "",
                    price: 0,
                });
            }
        } catch (e) {
            setBuyDialog({
                open: false,
                name: "",
                price: 0,
            });
            setTopUpDialog(true);
        }
    }

    const handleClickDownloadDocx = () => {
        if (!access?.docx) {
            setBuyDialog({ open: true, name: `Akses download file DOCX untuk ${data?.title ?? "Untitled CV "} (${formatDate(data.createdAt?.toDate?.())}) selamanya.`, price: 10, callFunction: handleBuyDownloadDocx, loading: purchasing, enable: access?.docx })
        } else {
            handleDownloadDocx();
        }
        handleCloseDownload();
    }

    return <>
        <Box>
            <Box sx={{ backgroundColor: '#F8F8FD', width: 'fit-content', padding: '10px', margin: '20px auto 40px' }}>
                <Typography sx={{
                    textAlign: 'center',
                    fontFamily: 'glacial_indifferenceregular',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '160%',
                    color: theme.palette.textColor.neutral60,
                    margin: '10px 0 0',
                }}>
                    Sempurnakan CV anda dan raih pekerjaan impian anda sekarang!
                </Typography>
                <div onClick={() => {
                    console.log('clicked')
                    window.location.href = 'https://hack.karir.ai'
                    setDialogGuide(true)
                }} style={{
                    // backgroundImage: `url(${tutorialBanner})`,
                    // width: '100%',
                    height: 'fit-content',
                    cursor: 'pointer',

                }}>
                    <img
                        src={banner}
                        width='100%'
                        alt=''
                    />
                </div>
                {/* <Button href="https://hack.karir.ai" startIcon={<SendIcon />} sx={{ width:'fit-content',display: 'flex', margin: '10px auto' }} variant="contained">Kirim CV kamu ke ratusan perusahaan dengan sekali klik</Button> */}
            </Box>

            {/* <Button
                sx={{ marginY: 2, borderRadius: 0 }}
                variant="contained"
                fullWidth
                startIcon={<CheckCircleOutline />}
                component={Link}
                to={`/optimizer?cvId=${params.cvId}`}

            >
                Coba CV Optimizer
            </Button> */}
            <Grid container spacing={2}>
                {/* <Grid item xs={6} textAlign="center">
                    <FormControlLabel
                        value="top"
                        control={<SwitchCustom />}
                        label="Tampilkan Icon"
                        labelPlacement="top"
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{
                        backgroundColor: '#fafafa', padding: '0 16px 16px',
                        '@media(max-width:600px)': { backgroundColor: '#fff' }
                    }}>
                        <Grid item xs={12} md={2} textAlign="center">
                            <FormControlLabel
                                disabled={updateLoading}
                                control={<SwitchCustom

                                    checked={withFoto}
                                    onChange={handleSwitchChange}
                                />}
                                label="Tampilkan Foto"
                                labelPlacement="top"
                                sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                                classes={{ root: `{ display: 'flex' }` }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button onClick={handleOpenDialog} fullWidth variant="outlined">
                                Pilih Template
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button
                                onClick={handleDownload}
                                variant="contained"
                                fullWidth
                                startIcon={<Download />}
                                disabled={downloading}
                            >
                                Download
                            </Button>
                            <Menu
                                id="download-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseDownload}
                                MenuListProps={{
                                    'aria-labelledby': 'download-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={handleClickDownloadDocx}>
                                    <ListItemIcon>
                                        <Article />
                                    </ListItemIcon>
                                    <ListItemText>
                                        DOCX Document ({access?.docx ? 'Purchased' : `10 Kredit`})
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleDownloadPDF} >
                                    <ListItemIcon>
                                        <PictureAsPdf />
                                    </ListItemIcon>
                                    <ListItemText>
                                        PDF Document
                                    </ListItemText>

                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography>{selectedDesign.label}</Typography>
                        <Box sx={{ border: 'solid 1px #ddd', display: 'block', padding: '15px', margin: '20px auto 60px' }}>
                            <img
                                src={withFoto ? selectedDesign.withImg : selectedDesign.withoutImg}
                                loading="lazy"
                                alt={selectedDesign.label}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={4}>

                </Grid>
                <Grid item xs={4}>

                </Grid>

            </Grid>
        </Box>
        <Dialog

            open={dialogOpen}
            onClose={handleCloseDialog}
        >
            <DialogTitle sx={{ textAlign: 'center' }}>Pilih Template</DialogTitle>
            <Swiper
                navigation={true}
                modules={[Navigation]}
                initialSlide={designIndex}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            >
                {
                    thumbs?.map?.((thumb) => {
                        return <SwiperSlide key={thumb.id}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <label htmlFor={`design-${thumb.id}`}>
                                    <Box sx={{ border: 'solid 1px #ddd', display: 'block' }}>
                                        <img
                                            src={withFoto ? thumb.withImg : thumb.withoutImg}
                                            loading="lazy"
                                            alt={thumb.label}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                                        <Radio
                                            disabled={updateLoading}
                                            checked={design === thumb.id}
                                            onChange={handleDesignChange}
                                            value={thumb.id}
                                            id={`design-${thumb.id}`}
                                        />
                                        <Typography sx={{ textAlign: 'center' }}> {thumb.label}</Typography>
                                    </Box>
                                </label>

                            </Box>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </Dialog>
    </>
}