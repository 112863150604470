import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardActions, CardHeader, Grid, Menu, MenuItem, MenuList, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFirestoreCollectionMutation, useFirestoreDocumentDeletion, useFirestoreQueryData } from "@react-query-firebase/firestore";
import { doc, orderBy, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { cvsCollectionRef, firestore, useFirebase } from "../../contexts/FirebaseProvider";
import formatDate from "../../utils/formatDate";
import trackClickEvent from "../../utils/trackClickEvent";
import noData from "../../assets/no-cv.png";
import { YouTube } from "@mui/icons-material";
import DialogImportCv from "../home/DialogImportCv";
import MenuIcon from '@mui/icons-material/Menu';
import AlertDialog from "../../components/AlertDialog";

function CardPlaceholder() {
    return (
        <Card
            elevation={0}
            sx={{ marginY: 2, bgcolor: "#F8F8FD", borderRadius: 2 }}
        >
            <CardHeader
                avatar={
                    <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                    />
                }
                title={
                    <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                    />
                }
                subheader={<Skeleton animation="wave" height={10} width="40%" />}
            />
            <CardActions sx={{ justifyContent: "right", padding: 2 }}>
                <Skeleton animation="wave" height={15} width="10%" />
                <Skeleton animation="wave" height={15} width="10%" />
                <Skeleton animation="wave" height={15} width="10%" />
            </CardActions>
        </Card>
    );
}
export default function CVMenu() {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState("panel1")
    // const classes = useStyles();
    const handleChange2 = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    // state
    const [selectedCVId, setSelectedCVId] = useState("default");
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    // firebase and firestore things
    const { user, openSignInDialog, } = useFirebase();
    let q = query(
        cvsCollectionRef,
        where("tempUID", "==", user?.tempUID),
        where("userId", "==", user?.uid),
        orderBy("updatedAt", "desc")
    );
    const { data: list, isLoading } = useFirestoreQueryData(
        [
            "cvs",
            {
                tempUID: user?.tempUID,
                userId: user?.uid,
            },
        ],
        q,
        { subscribe: true, idField: "id" }
    );

    // console.log(list)
    const mutation = useFirestoreCollectionMutation(cvsCollectionRef);
    const docRef = doc(cvsCollectionRef, selectedCVId);
    const deleteMutation = useFirestoreDocumentDeletion(docRef);
    const handleCreateCV = () => {
        mutation.mutate(
            {
                userId: user?.uid,
                tempUID: user?.tempUID,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
            },
            {
                onSuccess(data) {
                    if (list?.length < 1) {
                        const settEmailDoc = doc(
                            firestore,
                            "users",
                            `${user?.uid}`,
                            "settings",
                            "default"
                        );

                        setDoc(
                            settEmailDoc,
                            {
                                cvId: data?.id,
                            },
                            { merge: true }
                        );
                    }
                    navigate(`/cv/${data.id}/basic-info`);
                    trackClickEvent("Create CV Button Clicked", {
                        userId: user?.uid,
                        tempUID: user?.tempUID,
                    });
                },

                onError(error) {
                    console.log(error.message);
                },
            }
        );
    };

    const handleCloseConfirmDelete = () => setConfirmDeleteOpen(false);

    const handleOpenConfirmDelete = (cv) => (e) => {
        setSelectedCVId(cv.id);
        setConfirmDeleteOpen(true);
    };
    const handleDeleteCV = () => {
        deleteMutation.mutate(
            {},
            {
                onSuccess() {
                    setConfirmDeleteOpen(false);
                },
            }
        );
    };

    // const [dialogGuide, setDialogGuide] = useState(false);

    const [dialogimport, setDialogImport] = useState(false);

    const [anchorEl2, setAnchorEl2] = React.useState(null)
    const openMe = Boolean(anchorEl2)
    const handleClick = event => {
        setAnchorEl2(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl2(null)
    }

    // const matches = useMediaQuery('(max-width: 600px)');




    return (
        <Grid container sx={{
            marginTop: '8vh',
            '@media(max-width:600px)': {
                marginTop: '20px',
            },
        }}>
            <Grid item xs={12} md={2} >
                {/* mobile nav */}
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', } }}>
                    <Button
                        sx={{ width: '100%' }}
                        id="basic-button"
                        aria-controls={openMe ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMe ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Menu</Typography>
                        <MenuIcon />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl2}
                        open={openMe}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuList
                            style={{
                                color: '#F26722',
                                marginRight: '25px',

                            }}
                        // autoFocusItem={open}
                        // id="composition-menu"
                        // aria-labelledby="composition-button"
                        // onKeyDown={handleListKeyDown}
                        >
                            <MenuItem sx={{
                                textAlign: 'right', fontSize: "14px", float: 'right', fontWeight: 'bold'
                            }} onClick={() => {
                                navigate('/optimizer')
                            }}>CV ATS OPTIMIZER</MenuItem>
                            <MenuItem sx={{
                                textAlign: 'right', fontSize: "14px", float: 'right', fontWeight: 'bold'
                            }} onClick={() => {
                                window.location.replace("https://hack.karir.ai")
                            }}>KIRIM LAMARAN OTOMATIS</MenuItem>
                            <MenuItem sx={{
                                textAlign: 'right', fontSize: "14px", float: 'right', fontWeight: 'bold'
                            }} onClick={() => {
                                setDialogImport(true);
                            }}>IMPORT CV LINKEDIN</MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
                {/* desktop nav */}
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', } }}>
                    <MenuList
                        style={{
                            color: '#F26722',
                            marginRight: '25px',

                        }}
                    // autoFocusItem={open}
                    // id="composition-menu"
                    // aria-labelledby="composition-button"
                    // onKeyDown={handleListKeyDown}
                    >
                        <MenuItem sx={{
                            textAlign: 'right', fontSize: "14px", float: 'right', fontWeight: 'bold'
                        }} onClick={() => {
                            navigate('/optimizer')
                        }}>CV ATS OPTIMIZER</MenuItem>
                        <MenuItem sx={{
                            textAlign: 'right', fontSize: "14px", float: 'right', fontWeight: 'bold'
                        }} onClick={() => {
                            window.location.replace("https://hack.karir.ai")
                        }}>KIRIM LAMARAN OTOMATIS</MenuItem>
                        <MenuItem sx={{
                            textAlign: 'right', fontSize: "14px", float: 'right', fontWeight: 'bold'
                        }} onClick={() => {
                            setDialogImport(true);
                        }}>IMPORT CV LINKEDIN</MenuItem>
                    </MenuList>
                </Box>

            </Grid>
            <Grid item xs={12} md={5}>
                <Grid sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                            fontStyle: "bold",
                            fontWeight: 700,
                            fontSize: "20px",
                            marginTop: '12px'
                        }}
                    >
                        Daftar CV Saya ({list?.length ?? 0})
                    </Typography>
                    <Button
                        onClick={handleCreateCV}
                    >
                        CREATE NEW CV
                    </Button>
                </Grid>
                {list?.length === 0 && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mt="50px"
                    >
                        <img
                            src={noData}
                            style={{ width: "150px", opacity: "50%" }}
                            alt=""
                        />
                        <Typography mt="20px" color="#aaa" fontSize="20px">
                            Belum ada CV
                        </Typography>
                        <Typography color="#aaa">
                            Silahkan{" "}
                            <span
                                style={{
                                    color: "#F26722",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={handleCreateCV}
                            >
                                buat CV baru
                            </span>
                            {!user?.uid && (
                                <>
                                    {" "}
                                    atau{" "}
                                    <span
                                        style={{
                                            color: "#F26722",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        }}
                                        onClick={openSignInDialog}
                                    >
                                        login
                                    </span>{" "}
                                    untuk menampilkan CV tersimpan
                                </>
                            )}
                        </Typography>
                    </Box>
                )}
                {isLoading
                    ? [...Array(5)].map((v, index) => {
                        return <CardPlaceholder key={index} />;
                    })
                    : list?.map((cv, index) => {
                        return (
                            <Card
                                key={cv.id}
                                sx={{
                                    marginY: 2,
                                    backgroundColor: "#F8F8FD",
                                    borderRadius: 2,
                                    // padding: '0px'
                                }}
                                elevation={0}

                            >
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            sx={{
                                                bgColor: "#F9FAFC",
                                            }}
                                            aria-label="recipe"
                                        >
                                            {index + 1}
                                        </Avatar>
                                    }
                                    title={cv?.title ?? "Untitled CV"}
                                    titleTypographyProps={{
                                        sx: { fontSize: 16 },
                                    }}
                                    subheaderTypographyProps={{
                                        sx: { fontSize: 14 },
                                    }}
                                    subheader={`Terakhir diubah pada ${formatDate(
                                        cv.updatedAt?.toDate?.()
                                    )}`}
                                />
                                <CardActions sx={{ justifyContent: "right" }}>
                                    <Button
                                        size="small"
                                        component={Link}
                                        to={`/cv/${cv.id}/basic-info`}
                                        onClick={() => trackClickEvent("Edit Button Clicked")}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={handleOpenConfirmDelete(cv)}
                                    >
                                        Delete
                                    </Button>
                                    {user?.uid ? (
                                        <Button
                                            size="small"
                                            component={Link}
                                            to={`/cv/${cv.id}/download`}
                                            onClick={() =>
                                                trackClickEvent("Download Button Clicked")
                                            }
                                        >
                                            Download
                                        </Button>
                                    ) : (
                                        <Button size="small" onClick={openSignInDialog}>
                                            Download
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        );
                    })}
            </Grid>
            <Grid item xs={12} md={5}>
                <div style={{ marginLeft: '10px' }}>

                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange2('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography sx={{ display: 'flex', alignItems: 'center', }}><YouTube sx={{ marginRight: '5px', color: '#f00' }} />Video Tutorial</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ width: '100%' }}>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/unq7MMxRXh0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </div>

            </Grid>
            <AlertDialog
                open={confirmDeleteOpen}
                handleClose={handleCloseConfirmDelete}
                handleYes={handleDeleteCV}
                title="Hapus CV"
                description="Anda yaking ingin menghapus cv ini?"
            />

            <DialogImportCv
                open={dialogimport}
                handleClose={() => {
                    setDialogImport(false);
                }}
            />
        </Grid>
    )
}