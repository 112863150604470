import { useAuthSignInWithCredential } from "@react-query-firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppLoading from "../../components/AppLoading";
import { auth, firestore, functions } from "../../contexts/FirebaseProvider";

export default function AuthHandler() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { mutate } = useAuthSignInWithCredential(auth);
    useEffect(() => {
        let query = {}
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            query[param] = value;
        }

        if (query.code) {
            const validateCode = httpsCallable(functions, "google-validateCode");
            validateCode({
                code: query.code,
                redirect_uri: `${window.location.origin}/__/auth/handler`
            }).then((res) => {
                // console.log(res.data);
                const credential = GoogleAuthProvider.credential(res.data.id_token);
                mutate(credential, {
                    onSuccess: async (data) => {
                        console.log(data.user);
                        await setDoc(doc(firestore, `credentials/${data.user.uid}`), {
                            google: {
                                ...res.data,
                                email: data.user.email,
                                is_extension: false,
                                redirect_uri: `${window.location.origin}/__/auth/handler`
                            }
                        }, { merge: true })

                        navigate(query.state || "/");
                    }
                })
            }).catch(e => {
                console.log(e)
            })
        }
    }, [searchParams, mutate, navigate]);

    return <AppLoading />


}