// React
import React, { useEffect, useRef, useState } from "react";

// React Router Dom
import {
  useNavigate,
  useOutletContext,
  //useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

// Firebase
import { httpsCallable } from "firebase/functions";
import {
  deleteDoc,
  serverTimestamp,
  doc,
  collection,
} from "firebase/firestore";

// React Query
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
  useFirestoreDocumentMutation,
  useFirestoreDocumentData,
} from "@react-query-firebase/firestore";

// Bottom Sheet
import { BottomSheet } from "react-spring-bottom-sheet";

// Notistack
import { useSnackbar } from "notistack";

// Draft JS
// import { EditorState, convertToRaw, ContentState } from "draft-js";

// HTML To Draft
// import htmlToDraft from "html-to-draftjs";

// Draft To HTML
// import draftToHtml from "draftjs-to-html";

// MUI
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Grid from "@mui/material/Grid";
// MUI Icons
//import Replay from "@mui/icons-material/Replay";
import PsychologyIcon from "@mui/icons-material/Psychology";
import Check from "@mui/icons-material/Check";
import { usePaywall } from "../../contexts/PaywallProvider";

// Components
import {
  InputCustom,
  DateRangeCustom,
  // RichTextEditorCustom,
  SavedItem,
  SwitchCustom,
} from "../../components";
import IcCredits from "../../assets/icon/credits";

// Typewriter Effect
import TypewriterComponent from "typewriter-effect";

// Context
import {
  functions,
  firestore,
  useFirebase,
  cvsCollectionRef,
} from "../../contexts/FirebaseProvider";
//import axios from "axios";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useMediaQuery } from "@mui/material";
import { dashboardBaseUrl } from "../../config/baseUrls";
import { useFunctionsCall } from "@react-query-firebase/functions";

// Initial State
let initialState = {
  jobTitle: "",
  companyName: "",
  startDate: null,
  endDate: null,
  // workingLocation: "",
  jobDescription: "", // EditorState.createEmpty(),
  isOnGoing: false,
};

let subCollection = "workExperiences";
const title = "Pengalaman Bekerja";
// accordion
const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0
  // },
  "&:before": {
    display: "none"
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}))

function WorkExperiencePage() {
  const { user, openSignInDialogAI } = useFirebase();
  const { setTopUpDialog } = usePaywall();
  const { setBackdropLoading } = useOutletContext();

  // Context
  //const { docRef } = useOutletContext();

  // media query mui
  const matches = useMediaQuery('(min-width:600px)');

  // Params
  const params = useParams();

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id") ?? "default";

  // Navigate
  const navigate = useNavigate();

  // Ref
  // const ref = subCollectionRef(params.cvId, subCollection);
  const cvDocRef = doc(cvsCollectionRef, params.cvId);
  const subCollectionRef = collection(cvDocRef, subCollection);
  const currDocRef = doc(subCollectionRef, id);
  const creditDocRef = doc(firestore, "koin_user", user?.uid ?? user?.tempUID);

  // Mutate
  const { mutate: addWorkExperience, isLoading: addLoading } =
    useFirestoreCollectionMutation(subCollectionRef);

  const { mutate: updateWorkExperience, isLoading: updateLoading } =
    useFirestoreDocumentMutation(currDocRef, {
      merge: true,
    });

  // Get Data
  const { data: list } = useFirestoreQueryData(
    [subCollection, params.cvId],
    subCollectionRef,
    {
      subscribe: true,
      idField: "id",
    }
  );
  const { data: item } = useFirestoreDocumentData(
    [subCollection, id],
    currDocRef,
    {
      subscribe: true,
      idField: "id",
    }
  );
  const creditData = useFirestoreDocumentData(
    ["koin_user", user?.uid],
    creditDocRef,
    {
      subscribe: true,
    }
  );

  // State
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loadingAI, setLoadingAI] = useState(false);
  const [mode, setMode] = useState("create");

  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);

  const [expanded, setExpanded] = React.useState("panel1")

  const handleChange2 = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  // Ref
  const bottomsheetRef = useRef();

  // snack
  const { enqueueSnackbar } = useSnackbar();

  // Handler
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
    setErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
  };

  const handleDateChange = (value, type) => {
    if (type === "start") {
      setForm((form) => ({
        ...form,
        startDate: value,
      }));
      setErrors((errors) => ({
        ...errors,
        startDate: "",
      }));
    } else {
      setForm((form) => ({
        ...form,
        endDate: value,
      }));
      setErrors((errors) => ({
        ...errors,
        endDate: "",
      }));
    }
  };

  const handleDismissButtomsheet = () => {
    navigate({
      search: "",
    });
  };

  const handleAdd = () => {
    if (addLoading) {
      return;
    }
    navigate({
      search: `?id=new`,
    });
  };

  const handleUpdate = async () => {
    if (updateLoading) {
      return;
    }



    const {
      jobTitle,
      companyName,
      startDate,
      endDate,
      // workingLocation,
      jobDescription,
      isOnGoing,
    } = form;

    let findErrors = {};
    if (!jobTitle) {
      findErrors.jobTitle = "Jabatan wajib diisi.";
    }

    if (!companyName) {
      findErrors.companyName = "Nama perusahaan wajib diisi.";
    }

    if (!startDate) {
      findErrors.startDate = "Tanggal mulai wajib diisi.";
    }

    if (!isOnGoing && !endDate) {
      findErrors.endDate = "Tanggal berhenti wajib diisi.";
    }

    if (!jobDescription) {
      findErrors.jobDescription = "Deskripsi pekerjaan wajib diisi.";
    }

    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      if (mode === "create") {
        addWorkExperience(
          {
            jobTitle,
            companyName,
            startDate,
            endDate,
            // workingLocation,
            jobDescription,
            isOnGoing,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess(data) {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
          }
        );
      } else {
        updateWorkExperience(
          {
            jobTitle,
            companyName,
            startDate,
            endDate,
            // workingLocation,
            jobDescription,
            isOnGoing,
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
          }
        );

        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_addWorkExperience&email=${userData?.email}`);

      }
    }
  };

  const handleSwitchChange = (e) => {
    const { checked, name } = e.target;
    setForm((form) => ({
      ...form,
      [name]: checked,
    }));
    if (name === "isOnGoing") {
      setErrors((errors) => ({
        ...errors,
        endDate: "",
      }));
    }
  };

  // UseEffect
  useEffect(() => {
    if (item) {
      const { startDate, endDate, ...prev } = item;

      // const contentBlock = htmlToDraft(jobDescription || "");
      // let savedEditorState = EditorState.createEmpty();
      // if (contentBlock) {
      //   const contentState = ContentState.createFromBlockArray(
      //     contentBlock.contentBlocks
      //   );
      //   savedEditorState = EditorState.createWithContent(contentState);
      // }

      setForm({
        ...initialState,
        ...prev,
        startDate: startDate?.toDate?.() ?? null,
        endDate: endDate?.toDate?.() ?? null,
      });
    }
  }, [item]);

  useEffect(() => {
    if (id === "new") {
      setMode("create");
    } else if (id !== "default") {
      setMode("update");
    }
  }, [id]);

  useEffect(() => {
    if (id !== "default") {
      setBottomSheetOpen(true);
    } else {
      setBottomSheetOpen(false);
    }
  }, [id]);

  const handleDelete = async (selectedId) => {
    return await deleteDoc(doc(subCollectionRef, selectedId));
  };

  // snackbar action
  const { mutateAsync: reqCustomToken, isLoading: isLoadingCustomToken } = useFunctionsCall(functions, "user-reqCustomToken");

  const action = snackbarId => (
    <>
      <Button onClick={async () => {
        const response = await reqCustomToken()

        window.location.href = `${dashboardBaseUrl}/login-by-token/${response?.customToken}/myCredit`
      }}>
        Riwayat Penggunaan Coin
      </Button>

    </>
  );
  // Handle AI Assistant
  const [aiJobdesc, setAiJobdesc] = useState("");
  const handleAi = async () => {
    try {
      setLoadingAI(true);
      setErrors({
        jobDescription: "",
      });

      const aiWriter = httpsCallable(functions, "cv-aiwriter_jobdesc");

      const res = await aiWriter({
        jobTitle: form.jobTitle,
        cvId: params.cvId,
      });

      setAiJobdesc(res?.data?.data?.jobdesc);

      // console.log(res?.data?.data?.jobdesc);

      if (res?.data?.data?.jobdesc) {
        setLoadingAI(false);
      }
      enqueueSnackbar(`Anda telah menggunakan 1 coin,`, {
        action,
        autoHideDuration: 5000
      })
    } catch (error) {
      if (!form?.jobTitle) {
        setErrors({
          ...error,
          jobTitle: "Jabatan belum diisi",
          jobDescription:
            "Kamu harus mengisi kolom jabatan terlebih dahulu untuk menggunakan asisten AI",
        });
      } else if (error.message === "Koin Anda Kurang") {
        setTopUpDialog(true);
      }
      console.log(error.message);

      setLoadingAI(false);
    }
  };

  const handleSetSummary = () => {
    setForm({
      ...form,
      jobDescription: form?.jobDescription.concat(
        form?.jobDescription ? "\n" + aiJobdesc : aiJobdesc
      ),
    });
    setAiJobdesc("");
  };

  useEffect(() => {
    if (isLoadingCustomToken) {
      setBackdropLoading(true)
    }
  }, [isLoadingCustomToken, setBackdropLoading])

  return (
    <>

      <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            {/* Saved Item */}
            <SavedItem
              type={title}
              list={list}
              titleField="jobTitle"
              subtitleField="companyName"
              // subtitleField={[["companyName", "string", ", "], ["startDate", "year", " - "], ["endDate", "year", ""]]}
              activeId={id}
              handleDelete={handleDelete}
              handleAdd={handleAdd}
              addLoading={addLoading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Accordion sx={{ margin: '20px 0 95px' }} expanded={expanded === 'panel1'} onChange={handleChange2('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography sx={{ display: 'flex', alignItems: 'center', }}><YouTubeIcon sx={{ marginRight: '5px', color: '#f00' }} />Video Tutorial</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ width: '100%' }}>
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/eZ8VDWBJ2Eo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
      </Grid>

      

      
      {/* <div style={{ paddingTop: '56.25%', position: 'relative', }}>
        <iframe title="pengalaman kerja" src="https://player.vdocipher.com/v2/?otp=20160313versASE323KQl0K0jWbz6azug7Q5lq5pFORdRFLTuWq050i5mIVwM5Xw&playbackInfo=eyJ2aWRlb0lkIjoiYjFiNDE1NzhmYTk2NGM4YjhjMjE5MTBhODU0ZmRhZjgifQ==" style={{ border: 0, maxWidth: '100%', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', }} allowFullScreen="true" allow="encrypted-media"></iframe>
      </div> */}
      {/* Bottom Sheet */}
      <BottomSheet
        initialFocusRef={false}
        open={bottomsheetOpen}
        ref={bottomsheetRef}
        onDismiss={handleDismissButtomsheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
        header={
          <Typography>
            {mode === "update" ? "Edit" : "Tambah"} {title}
          </Typography>
        }
        footer={
          <Button
            variant="contained"
            fullWidth
            className="w-full"
            onClick={handleUpdate}
            disabled={updateLoading || addLoading}
          >
            Simpan
          </Button>
        }
      >
        <Container maxWidth="xs" sx={{ paddingBottom: "50px" }}>
          {/* Input Jabatan */}
          <InputCustom
            title="Apakah Jabatan anda pada perusahaan?"
            placeholder="Web Designer"
            name="jobTitle"
            onChange={handleChange}
            value={form.jobTitle}
            required
            error={errors?.jobTitle ? true : false}
            helperText={errors?.jobTitle}
          />

          {/* Input Perusahaan */}
          <InputCustom
            title="Apa Nama perusahaan tempat anda bekerja?"
            placeholder="Google"
            name="companyName"
            onChange={handleChange}
            value={form.companyName}
            required
            error={errors?.companyName ? true : false}
            helperText={errors?.companyName}
          />

          {/* Input Range */}
          <DateRangeCustom
            title="Kapan anda mulai bekerja?"
            onChangeStart={(value) => handleDateChange(value, "start")}
            valueStart={form.startDate}
            onChangeEnd={(value) => handleDateChange(value, "end")}
            valueEnd={form.endDate}
            disabledEnd={form.isOnGoing}
            placeholderEnd={form.isOnGoing ? "Sekarang" : "End Date"}
            errorStart={errors?.startDate ? true : false}
            errorEnd={errors?.endDate ? true : false}
            helperTextStart={errors?.startDate}
            helperTextEnd={errors?.endDate}
          />

          {/* Switch isOnGoin */}
          <Box sx={{ marginTop: 1 }}>
            <FormControlLabel
              label="Saya masih bekerja di perusahaan ini."
              control={
                <SwitchCustom
                  sx={{ m: 1 }}
                  name="isOnGoing"
                  checked={form.isOnGoing}
                  onChange={handleSwitchChange}
                />
              }
            />
          </Box>

          {/* Input Alamat */}
          {/* <InputCustom
            title="Dimana alamat perusahaan anda?"
            placeholder="Jakarta"
            name="workingLocation"
            onChange={handleChange}
            value={form.workingLocation}
          /> */}

          {/* Input Rich Text */}
          {/* <RichTextEditorCustom
            title="Jelaskan apa saja yang anda kerjakan"
            editorState={form.jobDescription}
            onEditorStateChange={(value) => {
              setForm((form) => ({
                ...form,
                jobDescription: value,
              }));
            }}
          /> */}

          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "#993300",


            }}
          >
            <Button
              variant="contained"
              size="small"
              startIcon={
                loadingAI ? (
                  <CircularProgress size={18} color="inherit" />
                ) : (
                  <PsychologyIcon />
                )
              }
              disableElevation
              disabled={loadingAI}
              sx={{
                backgroundColor: "#993300",
                borderRadius: 0,
                "&:hover": {
                  backgroundColor: "#782800",
                },
                "&[disabled]": {
                  color: "#FFF",
                  backgroundColor: "#993300",
                },
              }}
              onClick={user?.uid ? handleAi : openSignInDialogAI}
            >
              Asisten AI
            </Button>
            {/* <div style={{ margin: '5px 5px 0px 3px', color: "#993300" }}> */}
            {
              user?.uid &&
              <><p style={{ fontSize: matches ? 15 : 10, marginRight: '-7px' }}>Harga = 1</p><IcCredits fontSize="10px" /> |

                <p style={{ fontSize: matches ? 15 : 10 }}>Coin Saya</p>
                <p style={{ fontSize: matches ? 15 : 10, marginRight: '-7px' }}>{creditData?.data?.coin || 0}</p>
                <IcCredits fontSize="10px" /></>
            }

            {/* </Box> */}

            {/* <p style={{ fontSize: 12 }}>Coin Saya</p> */}

          </Box>

          {aiJobdesc && (
            <div
              style={{
                userSelect: "none",
                color: "#7C8493",
                fontSize: "15px",
              }}
            >
              <TypewriterComponent
                options={{
                  strings: aiJobdesc,
                  autoStart: true,
                  // loop: true,
                  cursor: "",
                  delay: 50,
                }}
              />
            </div>
          )}
          <Button
            // variant="contained"
            size="small"
            startIcon={<Check />}
            color="success"
            disableElevation
            disabled={!aiJobdesc}
            sx={{
              marginLeft: "3px",
              borderRadius: 0,
            }}
            onClick={handleSetSummary}
          >
            Terapkan
          </Button>

          <InputCustom
            title="Jelaskan apa saja yang anda kerjakan!"
            placeholder="Membuat UI aplikasi..."
            name="jobDescription"
            onChange={handleChange}
            value={form.jobDescription}
            disabled={loadingAI}
            multiline
            rows={5}
            error={errors?.jobDescription ? true : false}
            helperText={errors?.jobDescription}
          />

        </Container>
      </BottomSheet>
    </>
  );
}

export default WorkExperiencePage;
