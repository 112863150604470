import { useEffect, useState } from "react";
import { IconButton, Typography, useTheme } from "@mui/material"
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { firestore } from "../contexts/FirebaseProvider";
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import GuideDialog from "./GuideDialog";


export default function Title({ content, value }) {
    const theme = useTheme();
    const [dialogGuide, setDialogGuide] = useState(false);


    const ref = doc(firestore, "tutorial", `cv_${value}`);

    const { data: item, isLoading: loading } = useFirestoreDocumentData(
        ['tutorial', `cv_${value}`],
        ref,
        {
            idField: "id",
        }
    );
    // console.log(item)

    // const options = {
    //     animationData: ideaIcon,
    //     loop: true
    // };

    useEffect(() => {
        if (!item?.src) {
            setDialogGuide(false)
        }
    }, [item?.src])

    return <div style={{
        display: 'flex',
        justifyContent: 'center'
    }}
    ><Typography
        sx={{
            fontFamily: 'glacial_indifferenceregular',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '120%',
            /* or 38px */
            textAlign: "center",
            /* Neutrals/60 */
            color: theme.palette.textColor.neutral60,
             margin: '20px 0',
        }}
    >
            {content}
        </Typography>
        {
            !loading && item?.src &&
            <IconButton
                onClick={() => {
                    setDialogGuide(true)
                }}
                sx={{
                    padding: 0,
                    marginBottom: '5px',
                    // float: 'right'
                    marginLeft: '5px'
                }}
            >
                <SmartDisplayIcon color="primary" />
                {/* <Lottie animationData={ideaIcon} loop={true} style={{
                    height: 50,
                    width: 40
                }} /> */}

                {/* {View} */}
                {/* <Lottie
                    options={defaultOptions}
                    height={50}
                    width={40}
                /> */}
            </IconButton>
        }

        <GuideDialog
            open={dialogGuide}
            handleClose={() => {
                setDialogGuide(false)
            }}
            content={content}
            idTutorial={value}
        />
    </div>

}