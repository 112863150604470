import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";
import theme from "./config/theme";
import FirebaseProvider from "./contexts/FirebaseProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import router from "./router";

// bottomsheet css
import "react-spring-bottom-sheet/dist/style.css";
import "./index.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { SnackbarProvider } from "notistack";
import PaywallProvider from "./contexts/PaywallProvider";
import FeedbackProvider from "./contexts/FeedbackProvider";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <FirebaseProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider maxSnack={20} autoHideDuration={3000}>
            <PaywallProvider>
              <FeedbackProvider>
                <RouterProvider router={router} />
              </FeedbackProvider>
            </PaywallProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </FirebaseProvider>
    </QueryClientProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
