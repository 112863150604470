import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  List,
  ListItem,
  Typography,
  ListItemAvatar,
  Avatar,
  ListItemText,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  // useFirestoreCollectionMutation,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import { query, where, orderBy, collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { InputCustom } from "../../components";
import Title from "../../components/Title";
import {
  cvsCollectionRef,
  firestore,
  functions,
  // jobTrendCollectionRef,
  // optimizerCollectionRef,
  useFirebase,
} from "../../contexts/FirebaseProvider";
import formatDate from "../../utils/formatDate";
import OptimizerDetail from "./OptimizerDetail";
import DialogJob from "./DialogJob";
import { usePaywall } from "../../contexts/PaywallProvider";
import { useFunctionsCall } from "@react-query-firebase/functions";
import trackClickEvent from "../../utils/trackClickEvent";
import ArticleIcon from "@mui/icons-material/Article";
import { useSnackbar } from "notistack";
//import axios from "axios";

export default function Recommender() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const { mutateAsync: cvOptimizer, isLoading: loading } = useFunctionsCall(
    functions,
    "cv-Recommender"
  );

  const cvId = searchParams.get("cvId") ?? null;

  const { user, openSignInDialog } = useFirebase();

  const { setBuyDialog, setTopUpDialog } = usePaywall();

  let q = query(
    cvsCollectionRef,
    where("tempUID", "==", user?.tempUID),
    where("userId", "==", user?.uid),
    orderBy("updatedAt", "desc")
  );

  const { data: list, isLoading } = useFirestoreQueryData(
    [
      "cvs",
      {
        tempUID: user?.tempUID,
        userId: user?.uid,
      },
    ],
    q,
    { subscribe: true, idField: "id" }
  );

  const refAtt = collection(
    firestore,
    "users",
    `${user?.uid}`,
    "recommendation"
  );

  const { data: listRecommender } = useFirestoreQueryData(
    "recommendation",
    refAtt,
    {
      subscribe: true,
      idField: "id",
    }
  );

  const [selectedCV, setSelectedCV] = useState(null);

  const [optimizer, setOptimizer] = useState(null);
  const [errors, setErrors] = useState({});
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    setBuyDialog((curr) => ({ ...curr, loading }));
  }, [loading, setBuyDialog]);

  useEffect(() => {
    if (cvId && list?.length > 0) {
      const findCV = list?.find?.((cv) => cv.id === cvId);

      if (findCV) {
        setSelectedCV({
          id: findCV.id,
          label: `${findCV?.title || "Untitled CV "} (${formatDate(
            findCV.createdAt?.toDate?.()
          )})`,
        });
      }
    }
  }, [cvId, list]);

  const transformList =
    list?.map?.((cv, index) => ({
      id: cv.id,
      label: `${cv?.title ?? "Untitled CV "} (${formatDate(
        cv.createdAt?.toDate?.()
      )})`,
    })) ?? [];

  // checkbox recommendation
  const listCheckbox = [
    {
      nama: "Pendidikan",
      field: "useEdu",
    },
    {
      nama: "Skills",
      field: "useSkill",
    },
    {
      nama: "Proyek",
      field: "useProyek",
    },
    {
      nama: "Kursus",
      field: "useCourse",
    },
    {
      nama: "Award",
      field: "useAward",
    },
    {
      nama: "Pengalaman Kerja",
      field: "useWorkExp",
    },
    {
      nama: "Sertifikasi",
      field: "useCert",
    },
    {
      nama: "Organisasi",
      field: "useOrg",
    },
    {
      nama: "Summary",
      field: "useSummary",
    },
  ];

  const [checked, setChecked] = useState({
    useEdu: false,
    useSkill: false,
    useProyek: false,
    useCourse: false,
    useAward: false,
    useWorkExp: false,
    useCert: false,
    useOrg: false,
    useSummary: false,
  });

  const [useJobCollection, setUseJobCollection] = useState(false);

  const handleChecked = (e) => {
    setChecked({
      ...checked,
      [e.target.name]: e.target.checked,
    });
  };
  const [industri, setIndustri] = useState();
  const listIndustri = [
    "Dirgantara",
    "Pertanian",
    "Transportasi",
    "Komputer",
    "Ecommerce",
    "Telekomunikasi",
    "Konstruksi",
    "Pendidikan",
    "Elektronik",
    "Hiburan",
    "Makanan",
    "Farmasi",
    "Manufaktur",
    "Pertambangan",
    "Media Berita",
  ];

  // console.log(industri, checked)

  const handleOptimize = async () => {
    try {
      const { data } = await cvOptimizer({
        cv_id: selectedCV.id,
        industry: industri,
        useJobCollection,
        ...checked,
      });

      // addOptimizer(
      //   {
      //     uid: user?.uid === null ? user?.tempUID : user?.uid,
      //     cv_id: selectedCV.id,
      //     input: {
      //       job_title: targetJobTitle,
      //       job_desc: targetJobDesc,
      //     },
      //     output: data,
      //     createdAt: serverTimestamp(),
      //   },
      //   {
      //     onSuccess() { },
      //     onError(err) {
      //       console.log(err);
      //     },
      //   }
      // );

      setOptimizer({ ...data });


      setBuyDialog({
        open: false,
        name: "",
        price: 0,
      });
    } catch (e) {
      enqueueSnackbar(`gagal,${e.message}`, { variant: "error" });
      if (e.message === "Koin Kurang") {
        setTopUpDialog(true);
      }
      setBuyDialog({
        open: false,
        name: "",
        price: 0,
      });
    }
  };

  const handleSelect = (item) => {
    setIndustri(item);

    // setTargetJobDesc(desc);

    setErrors((errors) => ({
      ...errors,
      industri: "",
    }));

    setDialog(false);
  };

  const handleOpenBuyDialog = async () => {
    let findErrors = {};

    if (!selectedCV) {
      findErrors.selectedCV = "CV wajib diisi.";
    }
    if (!industri) {
      findErrors.industri = "industri wajib diisi.";
    }

    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      trackClickEvent("recommender-Buy Button Clicked", {
        userId: user?.uid,
        tempUID: user?.tempUID,
      });

      setBuyDialog({
        open: true,
        name: `Akses 1x recommender untuk ${selectedCV?.label}`,
        price: 5,
        callFunction: handleOptimize,
        loading: loading,
        enable: selectedCV?.access,
      });
      // pixel
      //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_recommender&email=${userData?.email}`);

    }
  };

  return (
    <>
      <Box
        sx={{
          padding: "10px 0 0",
          // paddingBottom: 8,
          position: "relative",
        }}
      >
        {/* Title */}
        <Title content="Alternative Career Recommender" />

        {optimizer !== null ? (
          <OptimizerDetail
            data={{
              ...optimizer,
            }}
          />
        ) : (
          <>
            <Autocomplete
              loading={isLoading}
              fullWidth
              disablePortal
              value={selectedCV}
              onChange={(e, value) => {
                setSelectedCV(value);
                setErrors((errors) => ({
                  ...errors,
                  selectedCV: "",
                }));
              }}
              id="pilih-cv-combo"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={transformList}
              noOptionsText={
                <Button
                  component={Link}
                  to="/"
                  sx={{ textTransform: "none", padding: 0, color: "inherit" }}
                >
                  Kamu belum punya CV, buat CV baru
                  <span
                    style={{ color: theme.palette.primary.main, marginLeft: 4 }}
                  >
                    disini
                  </span>
                </Button>
              }
              renderInput={(params) => (
                <InputCustom
                  containerRef={params.InputProps.ref}
                  placeholder="CV Keren"
                  title="Pilih CV"
                  inputProps={params.inputProps}
                  fullWidth
                  error={errors?.selectedCV ? true : false}
                  helperText={errors?.selectedCV}
                />
              )}
            />
            <FormControl component="fieldset">
              <FormLabel>Pertimbangkan hal berikut:</FormLabel>
              <FormGroup aria-label="position" row>
                {listCheckbox?.map((chx) => {
                  return (
                    <FormControlLabel
                      key={chx?.field}
                      name={chx?.field}
                      value={chx?.field}
                      control={<Checkbox />}
                      label={chx?.nama}
                      onChange={handleChecked}
                    // labelPlacement="top"
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormLabel>Tampilkan Lowongan Pekerjaan Yang Sesuai</FormLabel>
              <FormControlLabel
                value={useJobCollection}
                onChange={(e) => setUseJobCollection(e.target.checked)}
                control={<Checkbox />}
                // label="Tampilkan Lowongan Pekerjaan Yang Sesuai"
                // labelPlacement="start"
                sx={{ marginLeft: "6px" }}
              />
            </FormControl>
            <InputCustom
              title="Industri"
              placeholder="Ecommerce"
              name="jobTitle"
              onChange={(e) => {
                setIndustri(e.target.value);
                setErrors((errors) => ({
                  ...errors,
                  industri: "",
                }));
              }}
              value={industri}
              disabled={loading}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "20px",
                      padding: "4px 10px",
                    }}
                    onClick={() => {
                      trackClickEvent(
                        "recommender-Pilih industri Button Clicked",
                        {
                          userId: user?.uid,
                          tempUID: user?.tempUID,
                        }
                      );
                      setDialog(true);
                    }}
                  >
                    Pilih Industri
                  </Button>
                </InputAdornment>
              }
              error={errors?.industri ? true : false}
              helperText={errors?.industri}
            />
            {/* Input Job Desc
            <InputCustom
              title="Job Description"
              placeholder="Enter Your Target Job Description here..."
              name="jobDesc"
              onChange={(e) => {
                setTargetJobDesc(e.target.value);
                setErrors((errors) => ({
                  ...errors,
                  targetJobDesc: "",
                }));
              }}
              value={targetJobDesc}
              multiline
              rows={5}
              error={errors?.targetJobDesc ? true : false}
              helperText={errors?.targetJobDesc}
              disabled={loading}
            /> */}
            <Button
              sx={{ marginY: 2 }}
              variant="contained"
              fullWidth
              onClick={
                user?.uid === null ? openSignInDialog : handleOpenBuyDialog
              }
              disabled={loading}
            >
              Rekomendasikan (5 Kredit)
            </Button>

            {/* History */}
            <Typography
              sx={{
                color: "textColor.neutral80",
                fontWeight: "600",
                fontSize: "16px",
                marginTop: "24px",
              }}
            >
              Riwayat Penggunaan Recommender
            </Typography>

            {/* List */}
            <List>
              {listRecommender?.map((item, idx) => {
                const cv = list?.find?.(
                  (cvlist) => cvlist?.id === item?.params?.cv_id
                );

                return (
                  <ListItem
                    key={idx}
                    divider
                    secondaryAction={
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          textTransform: "capitalize",
                          borderRadius: "20px",
                          padding: "4px 10px",
                        }}
                        onClick={() => {
                          setOptimizer({
                            ...item,
                          });
                        }}
                      >
                        Lihat Detail
                      </Button>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                        }}
                      >
                        <ArticleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={` (${cv?.title ?? "No CV"})`}
                      secondary={formatDate(item?.createdAt?.toDate?.())}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Box>

      <DialogJob
        open={dialog}
        handleClose={() => setDialog(false)}
        listIndustri={listIndustri}
        handleSelect={handleSelect}
      />
    </>
  );
}
