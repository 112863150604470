// React
import React, { useState, useEffect, useRef } from "react";
// mui
import {
  Box,
  Typography,
  Button,
  Container,
  FormControlLabel,
  Grid
} from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
// Components
import { InputCustom, DateRangeCustom, SavedItem } from "../../components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentData,
  useFirestoreDocumentMutation,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { cvsCollectionRef } from "../../contexts/FirebaseProvider";
import SwitchCustom from "../../components/SwitchCustom";
import { useSnackbar } from "notistack";
//import axios from "axios";
import TutorialVideo from "../../components/TutorialVideo";

const initialValues = {
  organizationName: "",
  position: "",
  startDate: null,
  endDate: null,
  positionDescription: "",
  isOnGoing: false,
};
const subcollection = "organizations";
const title = "Organisasi";
function OrganizationPage() {
  // context
  //const { userData } = useFirebase();
  // router hooks
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? "default";

  // firestore
  const cvDocRef = doc(cvsCollectionRef, params.cvId);
  const subCollectionRef = collection(cvDocRef, subcollection);
  const currDocRef = doc(subCollectionRef, id);
  const { mutate: addDoc, isLoading: addLoading } =
    useFirestoreCollectionMutation(subCollectionRef);
  const { data: list } = useFirestoreQueryData(
    [subcollection, params.cvId],
    subCollectionRef,
    { subscribe: true, idField: "id" }
  );
  const { data: item } = useFirestoreDocumentData(
    [subcollection, id],
    currDocRef,
    { subscribe: true, idField: "id" }
  );
  const { mutate: updateDoc, isLoading: updateLoading } =
    useFirestoreDocumentMutation(currDocRef, {
      merge: true,
    });
  // State
  const [
    {
      organizationName,
      position,
      startDate,
      endDate,
      positionDescription,
      isOnGoing,
    },
    setForm,
  ] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("create");
  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);
  const bottomsheetRef = useRef();

  const { enqueueSnackbar } = useSnackbar();
  // side effect
  useEffect(() => {
    if (item) {
      setForm({ ...initialValues, ...item });
    }
  }, [item]);

  useEffect(() => {
    if (id === "new") {
      setMode("create");
    } else if (id !== "default") {
      setMode("update");
    }
  }, [id]);

  useEffect(() => {
    if (id !== "default") {
      setBottomSheetOpen(true);
    } else {
      setBottomSheetOpen(false);
    }
  }, [id]);

  const handleDismissButtomsheet = () => {
    navigate({
      search: "",
    });
  };

  const handleDateChange = (value, type) => {
    if (type === "start") {
      setForm((form) => ({
        ...form,
        startDate: value,
      }));
      setErrors((errors) => ({
        ...errors,
        startDate: "",
      }));
    } else {
      setForm((form) => ({
        ...form,
        endDate: value,
      }));
      setErrors((errors) => ({
        ...errors,
        endDate: "",
      }));
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
  };

  const handleSwitchChange = (e) => {
    const { checked, name } = e.target;
    setForm((form) => ({
      ...form,
      [name]: checked,
    }));

    if (name === "isOnGoing") {
      setErrors((errors) => ({
        ...errors,
        endDate: "",
      }));
    }
  };

  const handleAdd = () => {
    if (addLoading) {
      return;
    }
    navigate({
      search: `?id=new`,
    });
  };

  const handleUpdate = async () => {
    if (updateLoading) {
      return;
    }
    let findErrors = {};

    if (!organizationName) {
      findErrors.organizationName = "Nama organisasi wajib diisi.";
    }

    if (!position) {
      findErrors.position = "Peran/posisi di organisasi wajib diisi.";
    }

    if (!startDate) {
      findErrors.startDate = "Tanggal mulai wajib diisi.";
    }

    if (!isOnGoing && !endDate) {
      findErrors.endDate = "Tanggal berhenti wajib diisi.";
    }

    if (!positionDescription) {
      findErrors.positionDescription =
        "Deskripsi peran anda di organisasi wajib diisi.";
    }

    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      if (mode === "create") {
        addDoc(
          {
            organizationName,
            position,
            startDate,
            endDate,
            positionDescription,
            isOnGoing,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess(data) {
              handleDismissButtomsheet();
              setForm(initialValues);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
            onError(err) {
              console.log(err);
            },
          }
        );
      } else {
        updateDoc(
          {
            organizationName,
            position,
            startDate,
            endDate,
            positionDescription,
            isOnGoing,
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialValues);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
          }
        );
      }
      // pixel
      //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_organization&email=${userData?.email}`);

    }
  };

  const handleDelete = async (selectedId) => {
    return await deleteDoc(doc(subCollectionRef, selectedId));
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {/* Saved Item */}
          <SavedItem
            type={title}
            list={list}
            titleField="position"
            subtitleField="organizationName"
            activeId={id}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            addLoading={addLoading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TutorialVideo src="https://www.youtube.com/embed/IveMo6j4R-k" />
        </Grid>
      </Grid>

      <BottomSheet
        initialFocusRef={false}
        open={bottomsheetOpen}
        ref={bottomsheetRef}
        onDismiss={handleDismissButtomsheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
        header={
          <Typography>
            {mode === "update" ? "Edit" : "Tambah"} {title}
          </Typography>
        }
        footer={
          <Button
            variant="contained"
            fullWidth
            // onClick={handleDismissButtomsheet}
            className="w-full"
            onClick={handleUpdate}
            disabled={updateLoading || addLoading}
          >
            Simpan
          </Button>
        }
      >
        <Container maxWidth="xs">
          {/* Input peran*/}
          <InputCustom
            title="Apa peran anda dalam Organisasi?"
            placeholder="Esport Player"
            name="position"
            onChange={handleChange}
            value={position}
            required
            error={errors?.position ? true : false}
            helperText={errors?.position}
          />

          {/* Input nama*/}
          <InputCustom
            title="Nama Organisasi?"
            placeholder="Esport Unpad"
            name="organizationName"
            onChange={handleChange}
            value={organizationName}
            required
            error={errors?.organizationName ? true : false}
            helperText={errors?.organizationName}
          />

          {/* Input Range */}
          <DateRangeCustom
            title="Berapa lama Anda tergabung?"
            onChangeStart={(value) => handleDateChange(value, "start")}
            valueStart={startDate?.toDate?.() ?? startDate}
            onChangeEnd={(value) => handleDateChange(value, "end")}
            valueEnd={endDate?.toDate?.() ?? endDate}
            disabledEnd={isOnGoing}
            placeholderEnd={isOnGoing ? "Sekarang" : "End Date"}
            errorStart={errors?.startDate ? true : false}
            errorEnd={errors?.endDate ? true : false}
            helperTextStart={errors?.startDate}
            helperTextEnd={errors?.endDate}
          />

          {/* 
      Switch isOnGoin
      */}
          <Box sx={{ marginTop: 1 }}>
            <FormControlLabel
              label="Saya masih berbagung di organisasi ini."
              control={
                <SwitchCustom
                  sx={{ m: 1 }}
                  name="isOnGoing"
                  checked={isOnGoing}
                  onChange={handleSwitchChange}
                />
              }
            />
          </Box>

          {/* Input alamat*/}
          {/* <InputCustom
        title="Dimana Organisasi tersebut berada?"
        placeholder="Universitas Padjadjaran"
        name="alamat"
        multiline
        maxRows={3}
      /> */}

          {/* Input partisipasi*/}
          <InputCustom
            title="Apa yang dilakukan dalam organisasi?"
            placeholder="Berpartisipasi dalam forum dan diskusi..."
            name="positionDescription"
            multiline
            maxRows={3}
            onChange={handleChange}
            value={positionDescription}
            required
            error={errors?.positionDescription ? true : false}
            helperText={errors?.positionDescription}
          />
        </Container>
      </BottomSheet>
    </>
  );
}

export default OrganizationPage;
