import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, DialogContent, Typography } from '@mui/material';
import GoogleLogo from '../assets/icon/google';


function SignInDialog(props) {

    const { handleSignInWithGoogle, ...restProps } = props;
    return (
        <Dialog maxWidth="xs"  {...restProps}>
            <DialogTitle>Sign in to Karir.ai</DialogTitle>
            <DialogContent>
                <Box sx={{
                    // display: 'flex',
                    // justifyContent: 'center'
                    textAlign: 'center'
                }}>
                    <Box>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                color: "rgba(0,0,0,0.54)",
                                marginTop: "10px",
                                "&: hover": {
                                    backgroundColor: "#eee",
                                },
                                "& .MuiButton-startIcon": {
                                    marginLeft: "0px",
                                    marginRight: "20px",
                                }
                            }}
                            size="large"
                            variant="contained"
                            startIcon={
                                <GoogleLogo sx={{ fontSize: "18px !important" }} />
                            }
                            onClick={handleSignInWithGoogle}
                        >Sign in with Google</Button>
                    </Box>
                    <Box>
                        <Typography>Atau</Typography>
                        <span
                            style={{
                                color: "#F26722",
                                cursor: "pointer",
                                textDecoration: "underline",
                            }}
                            onClick={() => {
                                window.location.pathname = '/signin'
                            }}
                        >
                            Login dengan email & password
                        </span>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

SignInDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default SignInDialog;