// React
import React, { useEffect, useRef, useState } from "react";

// MUI
import { Typography, Button, Grid } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
// Components
import { InputCustom, DateRangeCustom, SavedItem } from "../../components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentData,
  useFirestoreDocumentMutation,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { cvsCollectionRef } from "../../contexts/FirebaseProvider";
import { Container } from "@mui/system";
import { useSnackbar } from "notistack";
//import axios from "axios";
import TutorialVideo from "../../components/TutorialVideo";
const initialValues = {
  certificationName: "",
  issuingOrganization: "",
  startDate: null,
  endDate: null,
  // relevancy: ''
};
const subcollection = "certifications";
const title = "Sertifikasi";
function CertificationPage() {
  // context
  //const { userData } = useFirebase();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? "default";
  const cvDocRef = doc(cvsCollectionRef, params.cvId);
  const subCollectionRef = collection(cvDocRef, subcollection);
  const currDocRef = doc(subCollectionRef, id);
  const { mutate: addDoc, isLoading: addLoading } =
    useFirestoreCollectionMutation(subCollectionRef);
  const { data: list } = useFirestoreQueryData(
    [subcollection, params.cvId],
    subCollectionRef,
    { subscribe: true, idField: "id" }
  );
  const { data: item } = useFirestoreDocumentData(
    [subcollection, id],
    currDocRef,
    { subscribe: true, idField: "id" }
  );
  const { mutate: updateDoc, isLoading: updateLoading } =
    useFirestoreDocumentMutation(currDocRef, { merge: true });

  // State
  const [
    {
      certificationName,
      issuingOrganization,
      startDate,
      endDate,
      // relevancy
    },
    setForm,
  ] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("create");
  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);
  const bottomsheetRef = useRef();

  // snack
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (item) {
      setForm({ ...initialValues, ...item });
    }
  }, [item]);

  useEffect(() => {
    if (id === "new") {
      setMode("create");
    } else if (id !== "default") {
      setMode("update");
    }
  }, [id]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
  };

  const handleDateChange = (value, type) => {
    if (type === "start") {
      setForm((form) => ({
        ...form,
        startDate: value,
      }));

      setErrors((errors) => ({
        ...errors,
        startDate: "",
      }));
    } else {
      setForm((form) => ({
        ...form,
        endDate: value,
      }));
      setErrors((errors) => ({
        ...errors,
        endDate: "",
      }));
    }
  };

  useEffect(() => {
    if (id !== "default") {
      setBottomSheetOpen(true);
    } else {
      setBottomSheetOpen(false);
    }
  }, [id]);

  const handleDismissButtomsheet = () => {
    navigate({
      search: "",
    });
  };

  const handleAdd = () => {
    if (addLoading) {
      return;
    }
    navigate({
      search: `?id=new`,
    });
  };

  const handleUpdate = async () => {
    if (updateLoading) {
      return;
    }
    let findErrors = {};

    if (!certificationName) {
      findErrors.certificationName = "Nama sertifikasi wajib diisi.";
    }

    if (!issuingOrganization) {
      findErrors.issuingOrganization =
        "Lembaga/organisasi yang memberi sertifikat wajib diisi.";
    }

    if (!startDate) {
      findErrors.startDate = "Tanggal mulai berlaku wajib diisi.";
    }

    if (!endDate) {
      findErrors.endDate = "Tanggal akhir berlaku wajib diisi.";
    }

    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      if (mode === "create") {
        addDoc(
          {
            certificationName,
            issuingOrganization,
            startDate,
            endDate,
            // relevancy,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess(data) {
              handleDismissButtomsheet();
              setForm(initialValues);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
            onError(err) {
              console.log(err);
            },
          }
        );
      } else {
        updateDoc(
          {
            certificationName,
            issuingOrganization,
            startDate,
            endDate,
            // relevancy,
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialValues);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
          }
        );
      }
      // pixel
      //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_certification&email=${userData?.email}`);

    }
  };

  const handleDelete = async (selectedId) => {
    return await deleteDoc(doc(subCollectionRef, selectedId));
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {/* Saved Item */}
          <SavedItem
            type={title}
            list={list}
            titleField="certificationName"
            subtitleField="issuingOrganization"
            activeId={id}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            addLoading={addLoading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TutorialVideo src="https://www.youtube.com/embed/ZBi3L6SRJqI" />
        </Grid>
      </Grid>

      <BottomSheet
        initialFocusRef={false}
        open={bottomsheetOpen}
        ref={bottomsheetRef}
        onDismiss={handleDismissButtomsheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
        header={
          <Typography>
            {mode === "update" ? "Edit" : "Tambah"} {title}
          </Typography>
        }
        footer={
          <Button
            variant="contained"
            fullWidth
            // onClick={handleDismissButtomsheet}
            className="w-full"
            onClick={handleUpdate}
            disabled={updateLoading || addLoading}
          >
            Simpan
          </Button>
        }
      >
        <Container maxWidth="xs">
          {/* Input Nama Kursus */}
          <InputCustom
            title="Nama sertifikasi yang diikuti?"
            placeholder="Project Management Pro"
            name="certificationName"
            onChange={handleChange}
            value={certificationName}
            required
            error={errors?.certificationName ? true : false}
            helperText={errors?.certificationName}
          />
          {/* Input Nama Institusi Kursus */}
          <InputCustom
            title="Di mana Anda mendapatkan sertifikat ini?"
            placeholder="Pro Academy"
            name="issuingOrganization"
            onChange={handleChange}
            value={issuingOrganization}
            required
            error={errors?.issuingOrganization ? true : false}
            helperText={errors?.issuingOrganization}
          />

          {/* Input Range */}
          <DateRangeCustom
            title="Kapan masa berlaku sertifikat ini?"
            onChangeStart={(value) => handleDateChange(value, "start")}
            valueStart={startDate?.toDate?.() ?? startDate}
            onChangeEnd={(value) => handleDateChange(value, "end")}
            valueEnd={endDate?.toDate?.() ?? endDate}
            errorStart={errors?.startDate ? true : false}
            errorEnd={errors?.endDate ? true : false}
            helperTextStart={errors?.startDate}
            helperTextEnd={errors?.endDate}
          />

          {/* Input Relevancy*/}
          {/* <InputCustom
                        title="Relevansi sertifikat?"
                        placeholder="Tersertifikasi sebagai projek manajer profesional"
                        name="relevancy"
                        onChange={handleChange}
                        value={relevancy}
                        multiline
                        maxRows={3}
                    /> */}
        </Container>
      </BottomSheet>
    </>
  );
}

export default CertificationPage;
