import React from "react";

// Material UI
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Typography,
} from "@mui/material";

function MatchRate({ value = 0, jobTitle = '' }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          color: (theme) => theme.neutral.n40,
          fontSize: "24px",
          fontWeight: "700",
        }}
      >
        Match Rate
      </Typography>
      <Typography
        sx={{
          color: (theme) => theme.neutral.n40,
          fontSize: "40px",
          fontWeight: "700",
        }}
      >
      {jobTitle}
      </Typography>
      <Box
        sx={{ position: "relative", display: "inline-flex", marginTop: "10px" }}
      >
        <CustomCircularProgress value={value} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontSize: 27, color: (theme) => theme.accent.yellow }}
          >{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

const CustomCircularProgress = ({ value = 0 }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.grey[400],
        }}
        variant="determinate"
        size={150}
        thickness={7}
        value={100}
      />
      <CircularProgress
        sx={{
          color: (theme) => theme.accent.yellow,
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        variant="determinate"
        size={150}
        thickness={7}
        value={value}
      />
    </Box>
  );
};

export default MatchRate;
