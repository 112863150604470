'use client'
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    frontBox:{
        borderRadius: '10px', 
        width: '100%', 
        padding: '16px', 
        backgroundColor:'#fff', 
        border: 'solid 1px' + theme.borderColor , 
        display: 'flex', 
        '@media(max-width:526px)':{ padding: '4px 8px' }, 
        '@media(max-width:643px)':{ fontSize: '15px',}, 
        '@media(max-width:1366px)':{ padding: '10px',}, 
    },
    innerBox:{
        width: '100%', 
        display: 'flex', 
        flexFlow: 'column nowrap', 
        justifyContent: 'flex-start', 
        alignItems: 'center'
    },
    boxName:{
        color: '#f26722', 
        fontSize: '18px', 
        textTransform: 'uppercase', 
        fontFamily: 'glacial_indifferenceBold', 
        '@media(max-width:1024px)':{ fontSize: '16px' }, 
        '@media(max-width:626px)':{ fontSize: '15px', lineHeight: '16px', margin: 0, }, 
        '@media(max-width:494px)':{ minHeight: '45px', }, 
        '@media(max-width:300px)':{ fontSize: '12px',  }
    },
    boxImg:{
        display: 'block', margin: '15px auto',
        width: '100px',
        '@media(max-width:1366px)':{ width: '75px', margin: '5px auto' }, 
        '@media(max-width:1024px)':{ width: '50px', margin: '3px auto' }, 
        '@media(max-width:643px)':{ width: '50px', margin: '5px auto'}, 
        '@media(max-width:400px)':{ width: '40px', margin: '0 auto'}, 
        '@media(max-width:300px)':{ width: '30px'},   
        
    },
    boxContent:{
        // minHeight: '75px', 
        color: '#3f3f3f', 
        textAlign: 'center', 
        textTransform: 'capitalize', 
        fontWeight: 'normal',
        '@media(max-width:1536px)':{ minHeight: '50px' }, 
        '@media(max-width:1366px)':{ minHeight: '75px', }, 
        '@media(max-width:1024px)':{ minHeight: '55px', fontSize: '13px' }, 
        '@media(max-width:643px)':{ minHeight: '100px',}, 
        '@media(max-width:599px)':{ minHeight: '70px', fontSize: '12px' },
        '@media(max-width:473px)':{ minHeight: '78px', },
        '@media(max-width:390px)':{ minHeight: '90px', },   
        '@media(max-width:300px)':{ fontSize: '11px',minHeight: '105px', },   
    },
    boxBtn:{
        minHeight: '50px', 
        width: '92%', 
        margin: '15px auto',
        fontFamily: 'glacial_indifferenceBold', 
        paddingTop: '6px',
        '@media(max-width:1366px)':{ margin: '0 auto 15px',}, 
        '@media(max-width:1024px)':{ minHeight: '40px', margin: '0 auto 5px'}, 
        '@media(max-width:600px)':{ minHeight: '40px', margin: '5px auto'  },
        '@media(max-width:396px)':{ fontSize: '12px', width: '100%', margin: '3px auto' }, 
        '@media(max-width:300px)':{ fontSize: '11px',minHeight: '50px', },   
        
        
    }
    
}));

export default useStyles;
