import React, { useState } from "react";
//
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";
//
import { useFunctionsCall } from "@react-query-firebase/functions";
import { functions } from "../../contexts/FirebaseProvider";
//
import { useNavigate } from "react-router";
//
import { useSnackbar } from "notistack";
//import axios from "axios";

function DialogImportCv({ open, handleClose }) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  // context
  //const { userData } = useFirebase();

  const { mutateAsync: cvImport, isLoading: loading } = useFunctionsCall(
    functions,
    "cv-linkedinImport"
  );

  const [profile, setProfile] = useState("");

  const [error, setError] = useState("");

  const handleSubmit = async () => {
    let findErrors;

    if (!profile) {
      findErrors = "LinkedIn profile wajib diisi";

      setError(findErrors);
    } else {
      try {
        const { data } = await cvImport({
          linkedinProfile: profile,
        });

        enqueueSnackbar("Import CV dari LinkedIn profile berhasil.", {
          variant: "success",
        });

        navigate(`/cv/${data.cv_id}/basic-info`);
        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_importCVFromLinkedIn&email=${userData?.email}`);

      } catch (e) {
        setError(e?.message);
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Import CV From LinkedIn</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="LinkedIn Profile"
            variant="outlined"
            sx={{ mt: "16px" }}
            onChange={(e) => {
              setProfile(e.target.value);
              setError("");
            }}
            value={profile}
            error={error ? true : false}
            helperText={error}
            disabled={loading}
          />
          <Typography variant="subtitle2" color='GrayText'>Misal Url linked profil kamu adalah:</Typography>
          <Typography variant="subtitle2" color='GrayText'>https://www.linkedin.com/ in/steffi-luis-5a0444177/</Typography>
          <Typography variant="subtitle2" color='GrayText'>Maka masukkan 'steffi-luis-5a0444177' (tanpa kutip) kedalam field datas</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            sx={{ textTransform: "capitalize" }}
            onClick={handleClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DialogImportCv;
