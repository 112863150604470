import { useAuthSignInWithCustomToken } from "@react-query-firebase/auth"
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppLoading from "../../components/AppLoading";
import { auth } from "../../contexts/FirebaseProvider"

export default function LoginByToken() {
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirect = searchParams.get("redirect") ?? '/';
    const token = searchParams.get("token");
    const { mutate } = useAuthSignInWithCustomToken(auth);


    useEffect(() => {
        if (params?.token) {
            mutate(params?.token, {
                onSuccess() {
                    navigate(redirect);
                },
                onError() {
                    navigate("/");
                }
            })
        } else if (token) {
            mutate(token, {
                onSuccess() {
                    navigate(redirect);
                },
                onError() {
                    navigate("/");
                }
            })
        }
    }, [mutate, params?.token, navigate, redirect, token]);

    return <AppLoading />


}