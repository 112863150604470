import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Login,
  ChevronLeft,
  AccountCircleOutlined,
  Logout,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useFirebase } from "../contexts/FirebaseProvider";
import { useState } from "react";
import logo from "../assets/logo-full.svg";
import AlertDialog from "./AlertDialog";

function AppHeader() {
  // theme
  const theme = useTheme();
  const { user, openSignInDialog, handleSignOut } = useFirebase();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const [open, setOpen] = useState(false);

  // console.log(user);

  return (
    <Box
      sx={{
        height: "70px",
        width: "100%",
        padding: "12px 16px",
        boxShadow: "inset 0px -1px 0px #D6DDEB",
        position: "sticky",
        top: 0,
        background: theme.palette.common.white,
        zIndex: 2,
        flexGrow: 1,
      }}
    >
      <Grid alignItems="center" container spacing={3}>
        {location.pathname === "/" ? (
          <Grid item>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <img draggable={false} src={logo} alt="" width="150px" />
              <span
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: "2px 5px",
                  borderRadius: "100px",
                  color: "#fff",
                  fontSize: "10px",
                  marginLeft: "12px",
                  marginBottom: "5px",
                  height: "19px",
                }}
              >
                Beta
              </span>
            </div>
          </Grid>
        ) : (
          <>
            <Grid item xs sx={{ textAlign: "left" }}>
              <IconButton component={Link} to="/">
                <ChevronLeft
                  viewBox="2 2 20 20"
                  htmlColor={theme.palette.textColor.neutral100}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    margin: 0,
                    color: theme.palette.textColor.neutral100,
                  }}
                >
                  CV Maker
                </p>
                <span
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    padding: "2px 5px",
                    borderRadius: "100px",
                    color: "#fff",
                    fontSize: "10px",
                    marginLeft: "7px",
                    height: "19px",
                  }}
                >
                  Beta
                </span>
              </div>
            </Grid>
          </>
        )}

        <Grid item xs sx={{ textAlign: "right" }}>
          {user?.uid ? (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <AccountCircleOutlined
                  viewBox="2 2 20 20"
                  htmlColor={theme.palette.textColor.neutral100}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <ListItem sx={{ pb: 2 }} divider>
                  <ListItemAvatar>
                    <Avatar alt="P" src={user?.photoURL} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={user?.displayName}
                    secondary={user?.email}
                  />
                </ListItem>
                <MenuItem
                  sx={{ mt: 1 }}
                  onClick={() => {
                    setOpen(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText> Sign out</ListItemText>
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <IconButton onClick={openSignInDialog}>
              <Login
                viewBox="2 2 20 20"
                htmlColor={theme.palette.textColor.neutral100}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <AlertDialog
        title="Sign Out ?"
        open={open}
        handleClose={() => setOpen(false)}
        handleYes={() => {
          handleSignOut();
          setOpen(false);
        }}
      />
    </Box>
  );
}

export default AppHeader;
