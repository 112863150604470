// React
import React, { useCallback, useEffect, useState } from "react";

// MUI
import { Box, } from "@mui/material";

// Components
import {
  // StepperCustom, 
  PageJumper
} from "../../components";

// React Router Dom
import { Outlet, useMatches, useNavigate, useOutletContext, useParams } from "react-router-dom";

// Firebase
import { doc, getDoc } from "firebase/firestore";
import { cvAccessColectionRef, cvsCollectionRef, useFirebase } from "../../contexts/FirebaseProvider";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import Title from "../../components/Title";
import NewPageJumper from "../../components/newPageJumper";


// Pages
const pages = [
  {
    value: "basic-info",
    label: "Basic Info",
    counterField: "fullName",
  },
  {
    value: "work-experience",
    label: "Pengalaman",
    counterField: "workExperiences"
  },
  {
    value: "project",
    label: "Proyek",
    counterField: "projects"
  },
  {
    value: "education",
    label: "Pendidikan",
    counterField: "educations"
  },
  {
    value: "certification",
    label: "Sertifikasi",
    counterField: "certifications"
  },
  {
    value: "course",
    label: "Kursus",
    counterField: "courses"
  },
  {
    value: "organization",
    label: "Organisasi",
    counterField: "organizations"
  },
  {
    value: "skill",
    label: "Skill",
    counterField: "skills"
  },
  {
    value: "award",
    label: "Prestasi",
    counterField: "awards"
  },
  {
    value: "summary",
    label: "Summary",
    counterField: "summary"
  },
  {
    value: "download",
    label: "Selesaikan CV",
  },
];

function CVLayout() {

  // router hooks
  const navigate = useNavigate();
  const params = useParams();
  const matches = useMatches();
  const { user } = useFirebase();
  const { setBackdropLoading } = useOutletContext();
  const match = matches
    .find((match) => Boolean(match.handle?.getJumperValue));
  const page = match?.handle?.getJumperValue?.() ?? "basic-info";
  const nextPage = match?.handle?.next?.();
  const prevPage = match?.handle?.prev?.();
  const label = pages?.find?.(item => item.value === page)?.label;
  const value = pages?.find?.(item => item.value === page)?.value;

  // console.log(setBackdropLoading)
  // firestore
  const ref = doc(cvsCollectionRef, params.cvId);
  const { data: basicInfo, isLoading: basicInfoLoading } = useFirestoreDocumentData(
    ["cvs", params.cvId],
    ref,
    {
      subscribe: true,
      idField: 'id'
    }
  );
  console.log(basicInfo)
  const accessRef = doc(cvAccessColectionRef, params.cvId);
  const { data: access, isLoading: accessLoading } = useFirestoreDocumentData(
    ["cvAccess", params.cvId],
    accessRef,
    {
      subscribe: true,
      idField: 'id'
    }
  );
  useEffect(() => {
    if (params.cvId && user?.uid === null) {
      // check permission
      getDoc(doc(cvsCollectionRef, params.cvId)).catch((reason) => {
        navigate("/", { replace: true })
      })
    }
  }, [params.cvId, navigate, user?.uid])

  // const step = pages?.reduce?.((prev, curr) => {

  //   let counter = basicInfo?.[curr?.counterField] ?? 0;
  //   if (curr?.counterField === 'fullName' && basicInfo?.[curr?.counterField]) {
  //     counter = 1;
  //   } else if (curr?.counterField === 'summary' && basicInfo?.[curr?.counterField]) {
  //     counter = 1;
  //   }
  //   if (counter > 0) {

  //     return prev + 1;
  //   }
  //   return prev;
  // }, 0)

  const [layoutState, setLayoutState] = useState();

  const docRef = useCallback((ref, newId) => {
    if (newId) {
      return doc(ref, newId);
    }

    return null;
  }, []);

  return (
    <Box
      sx={{
        padding: "10px 0 0",
        // paddingBottom: 8,
        position: "relative",
      }}
    >

      <Box
      //  sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
      >
        <NewPageJumper
          basicInfo={basicInfo}
          page={page}
          pages={pages}
          nextPage={nextPage}
          prevPage={prevPage}
          access={access}
          setBackdropLoading={setBackdropLoading}

        />
      </Box>
      {/* Title */}
      <Title content={label} value={value} />
      {/* Stepper */}
      <Box sx={{ marginTop: { xs: '10px', sm: '0', md: '0' } }}>
        {/* <StepperCustom step={step} totalSteps={pages?.length - 1} /> */}
      </Box>
      {/* Outlet */}
      <Outlet
        context={{
          layoutState,
          setLayoutState,
          docRef,
          basicInfo,
          basicInfoLoading,
          access,
          accessLoading,
          setBackdropLoading
        }}
      />
      {/* <DefaultCV /> */}
      {/* Page Jumper */}
      <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
        <PageJumper
          pages={pages}
          basicInfo={basicInfo}
          page={page}
          nextPage={nextPage}
          prevPage={prevPage}
          access={access}
          setBackdropLoading={setBackdropLoading}
        />
      </Box>
    </Box>
  );
}

export default CVLayout;
