// React
import React, { useEffect, useRef, useState } from "react";

// MUI
import {
  Chip,
  Box,
  Stack,
  Typography,
  Button,
  Container,
  CircularProgress,
  Grid
} from "@mui/material";

// Components
import { InputChipSearch, SavedItem, InputCustom } from "../../components";

// React Router Dom
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

// Context
import { subCollectionRef } from "../../contexts/FirebaseProvider";

// React Query
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
  useFirestoreDocumentMutation,
  useFirestoreDocumentData,
} from "@react-query-firebase/firestore";

// Axios
import axios from "axios";

// Firebase
import { serverTimestamp, deleteDoc } from "firebase/firestore";

// Bottom Sheet
import { BottomSheet } from "react-spring-bottom-sheet";

// Notistack
import { useSnackbar } from "notistack";
import TutorialVideo from "../../components/TutorialVideo";

// Initial State
let initialState = {
  title: "",
  skills: null,
};

let subCollection = "skills";

let title = "Skill";

function SkillPage() {
  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  // Context
  const { docRef } = useOutletContext();
  //const { userData } = useFirebase();
  // Params
  const params = useParams();

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id") ?? "default";

  // Navigate
  const navigate = useNavigate();

  // Ref
  const ref = subCollectionRef(params.cvId, subCollection);

  // Mutate
  const { mutate: addSkills, isLoading: addLoading } =
    useFirestoreCollectionMutation(ref);

  const { mutate: updateSkills, isLoading: updateLoading } =
    useFirestoreDocumentMutation(docRef(ref, id), {
      merge: true,
    });

  // Get Data
  const { data: list } = useFirestoreQueryData(
    [subCollection, params.cvId],
    ref,
    {
      subscribe: true,
      idField: "id",
    }
  );

  const { data: item } = useFirestoreDocumentData(
    [subCollection, id],
    docRef(ref, id),
    {
      subscribe: true,
      idField: "id",
    }
  );

  // State
  const [form, setForm] = useState(initialState);

  const [errors, setErrors] = useState({});

  const [skills, setSkills] = useState([]);

  const [selectedSkills, setSelectedSkills] = useState([]);

  const [mode, setMode] = useState("create");

  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  // Ref
  const bottomsheetRef = useRef();

  // Handler
  const handleChange = (e) => {
    const { value, name } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
  };

  const handleClick = (skilss) => () => {
    setSelectedSkills((prev) => [...prev, skilss]);
  };

  const handleDismissButtomsheet = () => {
    setForm(initialState);
    setSelectedSkills([]);
    setSkills([]);

    navigate({
      search: "",
    });
  };

  const handleAdd = () => {
    if (addLoading) {
      return;
    }

    navigate({
      search: `?id=new`,
    });
  };

  const handleUpdate = async () => {
    if (updateLoading) {
      return;
    }

    let findErrors = {};

    if (!form.title) {
      findErrors.title = "Judul wajib diisi.";
    }

    if (selectedSkills.length === 0) {
      findErrors.skills = "Skills wajib diisi.";
    }

    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      if (mode === "create") {
        addSkills(
          {
            title: form.title,
            skills: selectedSkills,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialState);
              setSelectedSkills([]);
              setSkills([]);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
            onError(err) {
              console.log(err);
            },
          }
        );
      } else {
        updateSkills(
          {
            title: form.title,
            skills: selectedSkills,
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialState);
              setSelectedSkills([]);
              setSkills([]);
              enqueueSnackbar("Data berhasil di perbarui.", {
                variant: "success",
              });
            },
          }
        );
      }
      // pixel
      //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_skills&email=${userData?.email}`);

    }
  };

  const handleDelete = async (selectedId) => {
    return await deleteDoc(docRef(ref, selectedId));
  };

  // UseEffect
  useEffect(() => {
    if (item) {
      setForm((prev) => ({
        ...prev,
        title: item?.title,
      }));

      setSelectedSkills(item?.skills);
    }
  }, [item]);

  useEffect(() => {
    if (id === "new") {
      setMode("create");
    } else if (id !== "default") {
      setMode("update");
    }
  }, [id]);

  useEffect(() => {
    if (id !== "default") {
      setBottomSheetOpen(true);
    } else {
      setBottomSheetOpen(false);
    }
  }, [id]);

  useEffect(() => {
    const getRelated = async () => {
      try {
        setLoading(true);
        const { data } = await axios.post(
          "https://asia-southeast2-karirai-dev.cloudfunctions.net/relatedskills",
          {
            skills: form?.skills?.name,
          }
        );

        const related = data?.related?.map((item) => item?.["0"]);

        setSkills([...related]);

        setLoading(false);
      } catch (error) {
        console.log(error);

        setLoading(false);
      }
    };

    if (form.skills) {
      getRelated();
    }
  }, [form.skills]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {/* Saved Item */}
          <SavedItem
            type={title}
            list={list}
            titleField="title"
            subtitleField={[["skills", "arrayOfString"]]}
            activeId={id}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            addLoading={addLoading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TutorialVideo src="https://www.youtube.com/embed/S27cJOCLMSU" />
        </Grid>
      </Grid>


      {/* Bottom Sheet */}
      <BottomSheet
        initialFocusRef={false}
        open={bottomsheetOpen}
        ref={bottomsheetRef}
        onDismiss={handleDismissButtomsheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
        header={
          <Typography>
            {mode === "update" ? "Edit" : "Tambah"} {title}
          </Typography>
        }
        footer={
          <Button
            variant="contained"
            fullWidth
            className="w-full"
            onClick={handleUpdate}
            disabled={updateLoading || addLoading}
          >
            Simpan
          </Button>
        }
      >
        <Container maxWidth="xs">
          {/* Input Nama Projek */}
          <InputCustom
            title="Judul"
            placeholder="Skills Design"
            name="title"
            onChange={handleChange}
            value={form.title}
            required
            error={errors?.title ? true : false}
            helperText={errors?.title}
          />

          {/* Input Skill Set */}
          <InputChipSearch
            listSkills={selectedSkills}
            onSelected={(value) => {
              setSelectedSkills((prev) => [...prev, value]);
            }}
            onDeleteChip={(value) => {
              setSelectedSkills((prev) =>
                prev.filter((skill) => skill !== value)
              );
            }}
            onChange={(newValue) => {
              handleChange({
                target: {
                  name: "skills",
                  value: newValue,
                },
              });
            }}
            value={form?.skills}
            error={errors?.skills ? true : false}
            helperText={errors?.skills}
          />

          <Box>
            <Typography variant="h5" align="center" mt={2}>
              Related Skill
            </Typography>
            {loading ? (
              <Box
                sx={{
                  width: "100%",
                  height: 120,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  margin: "20px 0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: "wrap",
                    gap: 1,
                  }}
                >
                  {skills?.length > 0 ? (
                    skills
                      ?.filter((item) => {
                        for (let i = 0; i < selectedSkills.length; i++) {
                          const selected = selectedSkills[i];

                          if (item === selected) {
                            return false;
                          }
                        }

                        return true;
                      })
                      ?.map((item, idx) => {
                        return (
                          <Chip
                            key={idx}
                            label={item}
                            variant="fill"
                            color="primary"
                            size="small"
                            onClick={handleClick(item)}
                          />
                        );
                      })
                  ) : (
                    <Typography>No Skills Found</Typography>
                  )}
                </Stack>
              </Box>
            )}
          </Box>
        </Container>
      </BottomSheet>
    </>
  );
}

export default SkillPage;
