import React, { useState } from "react";

// Material UI
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

// Material Icons
import {
  DesignServicesOutlined,
  ExpandMore,
  CheckCircleOutline,
  CancelOutlined,
  //HelpOutline,
} from "@mui/icons-material";

// Components
import { MatchRate } from "../../components";
import trackClickEvent from "../../utils/trackClickEvent";
import { useFeedback } from "./../../contexts/FeedbackProvider";

function OptimizerDetail({
  data: {
    id,
    cv_id,
    job_title,
    job_desc,
    age_match,
    commonskill_match,
    activities_match,
    education_match,
    gpa_match,
    hardskill_match,
    joblevel_match,
    jobtitle_match,
    total_score,
    cv_path
  },
  setBuy,
  loading,
}) {
  const [expanded, setExpanded] = useState("panel1");
  // console.log(total_score)
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let hardskilss = Object.keys(hardskill_match?.items ?? {});
  let commonskills = Object.keys(commonskill_match?.items ?? {});
  let activities = Object.keys(activities_match?.items ?? {});

  const { openFeedback } = useFeedback();

  return (
    <div style={{ marginBottom: "50px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MatchRate value={total_score} jobTitle={job_title} />
      </Box>

      {/* Description */}
      <Typography
        sx={{
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "10px",
          color: (theme) => theme.palette.textColor.neutral60,
        }}
      >
        Semakin tinggi nilai kecocokan (MatchRate) antara CV dan Posisi, Kualifikasi & Deskripsi
        Pekerjaan, maka semakin besar peluang dipanggil ke tahap
        seleksi kerja berikutnya.
      </Typography>

      {/* Button Konsultasi CV */}
      <Button
        sx={{ marginY: 2 }}
        variant="contained"
        fullWidth
        onClick={() => setBuy({ id, job_title, job_desc, cv_id, cv_path })}
        disabled={loading}
      >
        Re Optimize (5 Kredit)
      </Button>

      {/* Panel */}
      <Box sx={{ marginTop: "16px" }}>
        {/* Basic Requirements */}
        <Accordion
          sx={{
            backgroundColor: (theme) => theme.neutral.n10i,
          }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1"
            id="panel1"
          >
            <Typography>Kualifikasi Umum</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* TOP */}
            <Box
              sx={{
                marginBottom: "10px",
                color: (theme) => theme.neutral.n40,
              }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Applicant Tracking Systems (ATS) adalah sistem komputer yang memproses
                CV kamu untuk mendapatkan informasi dengan tujuan mengetahui
                kecocokan terhadap suatu lowongan pekerjaan. Informasi yang dicari
                meliputi nama posisi pekerjaan, keterampilan, pendidikan, dll.
              </Typography>
              <br />
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Tips: Cek list hijau menunjukan point yang sudah terpenuhi, sedangkan
                tanda silang merah menunjukan informasi yang belum ditemukan. Tambahkan
                semua informasi ini untuk membuat CV kamu lebih relevan dan tertarget.
              </Typography>
            </Box>

            {/* Job Title */}
            <Box
              sx={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid",
                borderColor: (theme) => theme.neutral.n20,
              }}
            >
              {/* Title */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 6,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "textColor.neutral60",

                    width: "145px",
                  }}
                >
                  Kecocokan Posisi Pekerjaan
                </Typography>

                {/* <HelpOutline
                  sx={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "9px",
                    color: (theme) => theme.neutral.n20,
                  }}
                /> */}
              </div>
              {/* Content */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {jobtitle_match?.score > 0 ? (
                  <CheckCircleOutline
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.green,
                      marginRight: "12px",
                    }}
                  />
                ) : (
                  <CancelOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.red,
                      marginRight: "12px",
                    }}
                  />
                )}
                {jobtitle_match?.score > 0 ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Riwayat posisi pekerjaan {jobtitle_match?.most_related?.jobtitle} ditemukan dalam CV kamu.
                    , dan sesuai dengan tujuan karir kamu yaitu {job_title}.
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Riwayat pekerjaan {job_title} tidak ditemukan dalam CV. Memiliki riwayat posisi pekerjaan yang serupa dapat membantu CV kamu lebih tertarget.
                  </Typography>
                )}
              </div>
            </Box>

            {/* Education - Degree */}
            <Box
              sx={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid",
                borderColor: (theme) => theme.neutral.n20,
              }}
            >
              {/* Title */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 6,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "textColor.neutral60",

                    width: "145px",
                  }}
                >
                  Kecocokan Tingkat Pendidikan
                </Typography>

                {/* <HelpOutline
                  sx={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "9px",
                    color: (theme) => theme.neutral.n20,
                  }}
                /> */}
              </div>
              {/* Content */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {education_match?.degree_score > 0 ? (
                  <CheckCircleOutline
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.green,
                      marginRight: "12px",
                    }}
                  />
                ) : (education_match?.degree_matching?.target_degree !== null && education_match?.degree_score === 0) ?
                  (
                    <CancelOutlined
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: (theme) => theme.accent.red,
                        marginRight: "12px",
                      }}
                    />
                  ) : education_match?.degree_matching?.target_degree === null && education_match?.degree_score === 0 ? (
                    <CheckCircleOutline
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: (theme) => theme.accent.green,
                        marginRight: "12px",
                      }}
                    />
                  ) : ""}

                {education_match?.degree_score > 0 ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini mensyaratkan tingkat pendidikan minimal {education_match?.degree_matching?.target_degree_title} ,
                    dan sesuai dengan tingkat pendidikan di CV kamu {education_match?.degree_matching?.cv_degree_title}.
                  </Typography>
                ) : (education_match?.degree_matching !== "" && education_match?.degree_score > 0) ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini tidak mensyaratkan tingkat pendidikan tertentu.
                  </Typography>
                ) : ''}
              </div>
            </Box>

            {/* Education - Major */}
            <Box
              sx={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid",
                borderColor: (theme) => theme.neutral.n20,
              }}
            >
              {/* Title */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 6,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "textColor.neutral60",

                    width: "145px",
                  }}
                >
                  Kecocokan Jurusan Pendidikan
                </Typography>

                {/* <HelpOutline
                  sx={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "9px",
                    color: (theme) => theme.neutral.n20,
                  }}
                /> */}
              </div>
              {/* Content */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {education_match?.major_score > 0 ? (
                  <CheckCircleOutline
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.green,
                      marginRight: "12px",
                    }}
                  />
                ) : (education_match?.most_related_major?.target_major !== null && education_match?.major_score === 0) ? (
                  <CancelOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.red,
                      marginRight: "12px",
                    }}
                  />
                ) : (<CheckCircleOutline
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: (theme) => theme.accent.green,
                    marginRight: "12px",
                  }}
                />)}

                {education_match?.major_score > 0 ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini mensyaratkan pendidikan {" "}
                    jurusan '{education_match?.most_related_major?.target_major}',
                    dan sesuai dengan jurusan di CV kamu{" "}
                    {education_match?.most_related_major?.cv_major}.
                  </Typography>
                ) : (education_match?.most_related_major?.target_major !== null && education_match?.major_score === 0) ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pendidikan kamu {education_match?.most_related_major?.cv_major} tidak sesuai dengan syarat pekerjaan ini yaitu {education_match?.most_related_major?.target_major}.
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini tidak mensyaratkan pendidikan jurusan tertentu.
                  </Typography>)}
              </div>
            </Box>

            {/* GPA */}
            <Box
              sx={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid",
                borderColor: (theme) => theme.neutral.n20,
              }}
            >
              {/* Title */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 6,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "textColor.neutral60",

                    width: "145px",
                  }}
                >
                  Kecocokan IPK
                </Typography>

                {/* <HelpOutline
                  sx={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "9px",
                    color: (theme) => theme.neutral.n20,
                  }}
                /> */}
              </div>
              {/* Content */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {gpa_match?.score > 0 ? (
                  <CheckCircleOutline
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.green,
                      marginRight: "12px",
                    }}
                  />
                ) : gpa_match?.target_gpa !== null && joblevel_match?.score === 0 ? (
                  <CancelOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.red,
                      marginRight: "12px",
                    }}
                  />
                ) : (
                  <CheckCircleOutline
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.green,
                      marginRight: "12px",
                    }}
                  />
                )}

                {gpa_match?.score > 0 ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini mensyaratkan IPK {gpa_match?.target_gpa} ,
                    dan cocok dengan IPK kamu {gpa_match?.cv_gpa}.
                  </Typography>
                ) : gpa_match?.target_gpa !== null && joblevel_match?.score === 0 ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini mensyaratkan IPK {gpa_match?.target_gpa},
                    dan tidak sesuai dengan IPK CV kamu {gpa_match?.cv_gpa}.
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini tidak mensyaratkan IPK
                  </Typography>)}
              </div>
            </Box>

            {/* Working Experience */}
            <Box
              sx={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid",
                borderColor: (theme) => theme.neutral.n20,
              }}
            >
              {/* Title */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 6,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "textColor.neutral60",

                    width: "145px",
                  }}
                >
                  Kecocokan Pengalaman Kerja
                </Typography>

              </div>
              {/* Content */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {joblevel_match?.score > 0 ? (
                  <CheckCircleOutline
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.green,
                      marginRight: "12px",
                    }}
                  />
                ) : (joblevel_match?.target_exp_min !== null || joblevel_match?.target_exp_max !== null) && joblevel_match?.score === 0 ? (
                  <CancelOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.red,
                      marginRight: "12px",
                    }}
                  />
                ) : (joblevel_match?.target_exp_min === null && joblevel_match?.target_exp_max === null) && (joblevel_match?.score === 0) ? (
                  <CheckCircleOutline
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.green,
                      marginRight: "12px",
                    }}
                  />
                ) : ''}

                {joblevel_match?.score > 0 ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini mensyaratkan {joblevel_match?.target_exp_min ? `minimal ${joblevel_match?.target_exp_min} tahun` : ""} {joblevel_match?.target_exp_max ? `maximal ${joblevel_match?.target_exp_max} tahun` : ""}
                    , dan sesuai dengan pengalaman di CV kamu {joblevel_match?.cv_exp_years ? `${Math.floor(joblevel_match?.cv_exp_years)} tahun` : ''}.
                  </Typography>
                ) : (joblevel_match?.target_exp_min !== null || joblevel_match?.target_exp_max !== null) && joblevel_match?.score === 0 ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pengalaman kerja kamu {Math.floor(joblevel_match?.cv_exp_years)} tahun tidak sesuai dengan syarat posisi pekerjaan ini yaitu {joblevel_match?.target_exp_min ? `minimum ${joblevel_match?.target_exp_min} tahun` : ''} {joblevel_match?.target_exp_max ? `maximum ${joblevel_match?.target_exp_max} tahun` : ''}
                  </Typography>
                ) : (joblevel_match?.target_exp_min === null && joblevel_match?.target_exp_max === null) && (joblevel_match?.score === 0) ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini tidak mensyaratkan jumlah tahun pengalaman kerja.
                  </Typography>
                ) : ""}
              </div>
            </Box>

            {/* Age */}
            <Box
              sx={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid",
                borderColor: (theme) => theme.neutral.n20,
              }}
            >
              {/* Title */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 6,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "textColor.neutral60",

                    width: "145px",
                  }}
                >
                  Kecocokan Usia
                </Typography>

                {/* <HelpOutline
                  sx={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "9px",
                    color: (theme) => theme.neutral.n20,
                  }}
                /> */}
              </div>
              {/* Content */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {age_match?.score > 0 ? (
                  <CheckCircleOutline
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.green,
                      marginRight: "12px",
                    }}
                  />
                ) : (age_match?.target_age_min !== null ||
                  age_match?.target_age_max !== null) && age_match?.score === 0 ? (
                  <CancelOutlined
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.red,
                      marginRight: "12px",
                    }}
                  />
                ) : (age_match?.target_age_min === null ||
                  age_match?.target_age_max === null) && age_match?.score === 0 ? (<CheckCircleOutline
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: (theme) => theme.accent.green,
                      marginRight: "12px",
                    }}
                  />) : ""}

                {(age_match?.target_age_min !== null ||
                  age_match?.target_age_max !== null) && age_match?.score > 0 ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Pekerjaan ini mensyaratkan usia {age_match?.target_age_min ? `minimum ${age_match?.target_age_min} tahun` : ``}{" "}
                    {age_match?.target_age_max
                      ? ` maximum ${age_match?.target_age_max} tahun`
                      : ""}{" "}
                    tahun, dan sesuai dengan usia kamu {age_match?.cv_age} tahun
                  </Typography>
                ) : (age_match?.target_age_min !== null ||
                  age_match?.target_age_max !== null) && age_match?.score === 0 ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.neutral.n40,
                    }}
                  >
                    Usia kamu {age_match?.cv_age} tahun tidak sesuai dengan syarat posisi pekerjaan ini yaitu {age_match?.target_age_min ? `minimum ${age_match?.target_age_min} tahun` : ''} {age_match?.target_age_max ? `maximum ${age_match?.target_age_max} tahun` : ''}
                  </Typography>
                ) : (<Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: (theme) => theme.neutral.n40,
                  }}
                >
                  Pekerjaan ini tidak mensyaratkan usia.
                </Typography>)}
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Hard Skills */}
        <Accordion
          sx={{
            backgroundColor: (theme) => theme.neutral.n10i,
          }}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2"
            id="panel2"
          >
            <Typography>Hard Skills</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* TOP */}
            <Box
              sx={{
                marginBottom: "10px",
                color: (theme) => theme.neutral.n40,
              }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Hard skills memungkinkan kamu melakukan tugas dan tanggung jawab
                yang spesifik untuk suatu pekerjaan. Keterampilan hardskills
                biasanya terkait keterampilan teknis terkait penggunaan peralatan
                atau software tertentu, dan biasanya merupakan keterampilan yang
                dapat dipelajari di kelas, pelatihan, atau di pekerjaan. Hard skills
                memiliki pengaruh yang besar terhadap nilai kecocokan / match-score kamu.
              </Typography>
              <br />
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Tips: Tambahkan keterampilan teknis kamu yang sesuai dengan job description.
                Prioritaskan keterampilan yang paling sering disebutkan pada job description.
              </Typography>
              <br />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "right",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={openFeedback("Hard Skills")}
              >
                Berikan saranmu?
              </Typography>
            </Box>

            {/* Job Title */}
            <Box
              sx={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid",
                borderColor: (theme) => theme.neutral.n20,
              }}
            >
              {/* Content */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {hardskilss?.map((item) => {
                  let score = hardskill_match?.items[item][0];

                  return (
                    <div
                      key={item}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderTop: "1px solid #7C8493",
                        padding: "10px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "textColor.neutral60",
                        }}
                      >
                        {item}
                      </Typography>
                      {score > 0 ? (
                        <CheckCircleOutline
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: (theme) => theme.accent.green,
                          }}
                        />
                      ) : (
                        <CancelOutlined
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: (theme) => theme.accent.red,
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Soft Skills */}
        <Accordion
          sx={{
            backgroundColor: (theme) => theme.neutral.n10i,
          }}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel3"
            id="panel3"
          >
            <Typography>Soft Skills</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* TOP */}
            <Box
              sx={{
                marginBottom: "10px",
                color: (theme) => theme.neutral.n40,
              }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Soft skills merujuk pada kemampuan non-teknis yang melekat pada
                kepribadian seseorang. Seperti halnya hard skills, soft skills dapat
                dipelajari dan ditingkatkan dengan latihan dan pengalaman.
              </Typography>
              <br />
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Tips: Tambahkan informasi soft skill kamu yang paling relevan dengan
                pekerjaan yang sedang ingin kamu lamar.
              </Typography>
              <br />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "right",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={openFeedback("Hard Skills")}
              >
                Berikan saranmu?
              </Typography>
            </Box>

            {/* Job Title */}
            <Box
              sx={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid",
                borderColor: (theme) => theme.neutral.n20,
              }}
            >

              {/* Content */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {commonskills?.map((item) => {
                  let score = commonskill_match?.items[item][0];

                  return (
                    <div
                      key={item}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderTop: "1px solid #7C8493",
                        padding: "10px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "textColor.neutral60",
                        }}
                      >
                        {item}
                      </Typography>
                      {score > 0 ? (
                        <CheckCircleOutline
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: (theme) => theme.accent.green,
                          }}
                        />
                      ) : (
                        <CancelOutlined
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: (theme) => theme.accent.red,
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Activities Responsibilities */}
        <Accordion
          sx={{
            backgroundColor: (theme) => theme.neutral.n10i,
          }}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel4"
            id="panel4"
          >
            <Typography>Aktivitas Utama</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* TOP */}
            <Box
              sx={{
                marginBottom: "10px",
                color: (theme) => theme.neutral.n40,
              }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Daftar aktivitas menunjukan hal-hal utama yang perlu dilakukan
                dalam pekerjaan ini. Rekruter biasanya ingin melihat riwayat
                pengalaman atau aktivitas serupa yang pernah kamu lakukan sebelumnya.
              </Typography>
              <br />
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Tips: Tuliskan riwayat aktivitas kamu yang serupa akan membantu CV kamu
                lebih relevan bagi pekerjaan yang sendang ingin kamu lamar.
              </Typography>
              <br />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "right",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={openFeedback("Activities")}
              >
                Berikan saranmu?
              </Typography>
            </Box>

            {/* Job Title */}
            <Box
              sx={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#FFFFFF",
                borderTop: "1px solid",
                borderColor: (theme) => theme.neutral.n20,
              }}
            >
              {/* Content */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {activities?.map((item) => {
                  let score = activities_match?.items[item][0];

                  return (
                    <div
                      key={item}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderTop: "1px solid #7C8493",
                        padding: "10px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "textColor.neutral60",
                        }}
                      >
                        {item}
                      </Typography>
                      {score > 0 ? (
                        <CheckCircleOutline
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: (theme) => theme.accent.green,
                          }}
                        />
                      ) : (
                        <CancelOutlined
                          sx={{
                            width: "20px",
                            height: "20px",
                            color: (theme) => theme.accent.red,
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Button Desain CV */}
      <Button
        sx={{ textTransform: "capitalize", marginTop: "20px" }}
        onClick={() => {
          window.location.href = "https://karir.ai/matchrate-score/";
          trackClickEvent(`MatchRate tips button clicked`);
        }}
        startIcon={<DesignServicesOutlined />}
        variant="contained"
        fullWidth
      >
        Tingkatkan MatchRate
      </Button>
    </div>
  );
}

export default OptimizerDetail;
