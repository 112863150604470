import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  // useRef,
  useState,
} from "react";
import {
  useAuthSignInWithRedirect,
  useAuthReauthenticateWithRedirect,
  useAuthSignOut,
  useAuthUser,
} from "@react-query-firebase/auth";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";
import firebaseConfig from "../config/firebaseConfig";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import AppLoading from "../components/AppLoading";
import useTempUID from "../hooks/useTempUID";
import SignInDialog from "../components/SignInDialog";
import trackClickEvent from "../utils/trackClickEvent";
import localforage from "localforage";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
// import { googleOAuthClientId } from "../config/google";
import { getStorage } from "firebase/storage"
import SignInDialogAI from "../components/signInAI";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "asia-southeast2");
if (process.env.REACT_APP_BACK_ENV === "local") {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}
export const FirebaseContext = createContext();

// collection references
export const cvsCollectionRef = collection(firestore, "cvs");
export const cvAccessColectionRef = collection(firestore, "cvAccess");
export const jobTrendCollectionRef = collection(firestore, "jobtrend");
export const optimizerCollectionRef = collection(firestore, "optimizer");
export const subCollectionRef = (docId, subCollection) =>
  collection(firestore, `cvs/${docId}/${subCollection}`);

export function useFirebase() {
  return useContext(FirebaseContext);
}
export default function FirebaseProvider(props) {
  const [isOpenSignInDialog, setOpenSignInDialog] = useState(false);
  const [isOpenSignInDialogAI, setOpenSignInDialogAI] = useState(false);

  const user = useAuthUser(["user"], auth);
  // const googleOAuthClient = useRef(null);
  const authMutation = useAuthSignInWithRedirect(auth);
  const reAuthMutation = useAuthReauthenticateWithRedirect();
  const signoutMutation = useAuthSignOut(auth);
  const temp = useTempUID();
  const handleCloseSignInDialog = () => setOpenSignInDialog(false);
  const handleCloseSignInDialogAI = () => setOpenSignInDialogAI(false);

  const openSignInDialog = useCallback(() => {
    setOpenSignInDialog(true);
    trackClickEvent("SignIn Dialog Opened");
  }, []);

  const openSignInDialogAI = useCallback(() => {
    setOpenSignInDialogAI(true);
    trackClickEvent("SignIn Dialog AI Opened");
  }, []);

  useEffect(() => {
    if (user?.data?.uid && temp?.tempUID) {
      async function claimTempCVs() {
        const q = query(
          cvsCollectionRef,
          where("userId", "==", null),
          where("tempUID", "==", temp?.tempUID)
        );
        const snapshots = await getDocs(q);
        snapshots.forEach((doc) => {
          setDoc(
            doc.ref,
            {
              userId: user?.data?.uid,
              tempUID: null,
            },
            { merge: true }
          );
        });
      }

      claimTempCVs();
    }
  }, [user?.data?.uid, temp?.tempUID]);

  useEffect(() => {
    if (user?.data?.uid) {
      window.mixpanel?.identify(user?.data?.uid);
    }
  }, [user?.data?.uid]);


  const setLockedParamsToFirestore = useCallback(() => {
    if (user?.data?.uid) {
      localforage.getItem("lockedParams").then((lockedParams) => {
        if (Object.keys(lockedParams).length > 0) {
          setDoc(
            doc(firestore, `users/${user?.data?.uid}`),
            { ...lockedParams },
            { merge: true }
          );
        }
      });
    }
  }, [user?.data?.uid]);


  // useEffect(() => {
  //   getRedirectResult(auth).then((credentials) => {
  //     if (credentials) {

  //       const { _tokenResponse, providerId, user: { uid } } = credentials;
  //       const providerName = providerId?.split(".")?.[0];
  //       setDoc(
  //         doc(firestore, `credentials/${uid}`), {
  //         [providerName]: _tokenResponse
  //       }, {
  //         merge: true
  //       }
  //       )
  //     }
  //   }).catch((e) => {
  //     console.log(e);
  //   });

  // }, [])

  // useEffect(() => {

  //   window.onload = () => {
  //     googleOAuthClient.current = window.google.accounts.oauth2.initCodeClient({
  //       client_id: googleOAuthClientId,
  //       scope: 'https://www.googleapis.com/auth/gmail.send',
  //       ux_mode: 'redirect',
  //       redirect_uri: `${window.location.origin}/__/auth/handler`,
  //       state: window.location.pathname
  //     });
  //   }
  // }, [])
  const handleSignInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/gmail.send");
    // provider.setCustomParameters({
    //   access_type: "offline",
    //   prompt: "consent"
    // })
    authMutation.mutate(
      {
        provider: provider,
      },
      {
        onSuccess() {
          setOpenSignInDialog(false);
        },
      }
    );
  };
  // const handleSignInWithGoogle = () => {
  //   googleOAuthClient.current?.requestCode?.();
  // }
  const requestGmailScopes = () => {
    const provider = new GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/gmail.send");
    // provider.setCustomParameters({
    //   access_type: "offline",
    //   prompt: "consent"
    // })
    reAuthMutation.mutate(
      {
        user: auth.currentUser,
        provider: provider
      }
    );
  }

  const handleSignOut = () => {
    signoutMutation.mutate();
  };

  // user doc
  const usersDoc = doc(firestore, `users/${user?.data?.uid}`);
  const { data: userData, } = useFirestoreDocumentData(['users', user?.data?.uid], usersDoc, {
    subscribe: true,
    idField: "id",
  });

  if (user.isLoading || temp.isLoading) {
    return <AppLoading />;
  }

  if (user?.data?.uid) {
    return (
      <FirebaseContext.Provider
        value={{
          user: { tempUID: null, ...user.data },
          openSignInDialog,
          handleSignOut,
          setLockedParamsToFirestore,
          requestGmailScopes,
          userData
        }}
      >
        {props.children}
      </FirebaseContext.Provider>
    );
  }

  return (
    <FirebaseContext.Provider
      value={{
        user: { uid: null, tempUID: temp.tempUID },
        openSignInDialog,
        openSignInDialogAI
      }}
    >
      {props.children}
      <SignInDialog
        open={isOpenSignInDialog}
        onClose={handleCloseSignInDialog}
        handleSignInWithGoogle={handleSignInWithGoogle}
      />
      <SignInDialogAI
        open={isOpenSignInDialogAI}
        onClose={handleCloseSignInDialogAI}
        handleSignInWithGoogle={handleSignInWithGoogle}
      />
    </FirebaseContext.Provider>
  );
}
