import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import AddIcon from "@mui/icons-material/Add";
// import FindReplace from "@mui/icons-material/FindReplace";
// import SendIcon from '@mui/icons-material/Send';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  useFirebase,
  // functions,
} from "../../contexts/FirebaseProvider";

import { Link, useNavigate } from "react-router-dom";
import trackClickEvent from "../../utils/trackClickEvent";
// import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import GuideDialog from "../../components/GuideDialog";
import DialogImportCv from "./DialogImportCv";
import iconCV from "../../assets/icon-cv.png";
import iconATS from "../../assets/icon-ats.png";
import iconSearch from "../../assets/icon-search.png";
import iconLI from "../../assets/icon-import.png";
import useStyles from './styles';

// accordion




// Styled Component
const MenuBtn = styled(Button)((theme) => ({
  borderRadius: "8px",
  minHeight: "100px",
  paddingTop: "12px",
}));
const Name = styled("p")(() => ({
  fontSize: 14,
  fontWeight: 700,
  marginTop: 5,
  marginBottom: 0,
  textTransform: "none",
  textAlign: "center",
  '@media(max-width:340px)': {
    fontSize: 12,
  },
}));



export default function HomePage() {
  // const { mutate: reqCustomToken, isLoading: isLoadingCustomToken } = useFunctionsCall(functions, "user-reqCustomToken");
  const navigate = useNavigate();
  const classes = useStyles();


  // state

  // firebase and firestore things
  const { user } = useFirebase();



  // console.log(list)






  const [dialogGuide, setDialogGuide] = useState(false);

  const [dialogimport, setDialogImport] = useState(false);



  // console.log(user)

  return (
    <>

      <Paper elevation={0} sx={{ '@media(max-width:767px)': { paddingBottom: '100px' } }}>
        <Grid container spacing={2} sx={{ marginTop: '16px', '@media(max-width:767px)': { marginTop: '0' } }}>
          {/* <img
            src={`https://auto.karir.ai/mtracking.gif?page_title=cvMaker_home&email=${userData?.email}`} alt=''
          /> */}
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2} sx={{ padding: 0 }}>
              <Grid xs={6} sm={6} item sx={{ display: 'flex', height: '100%', }}>
                <Box className={classes.frontBox}
                // onClick={handleCreateCV} 
                >
                  <Box className={classes.innerBox}>
                    {/* <AddIcon fontSize="large" /> */}
                    <Name className={classes.boxName}>CV Maker</Name>
                    <img className={classes.boxImg} draggable={false} src={iconCV} alt="" />
                    <Typography className={classes.boxContent}>Buat CV Agar ATS-Friendly dan Mendapatkan Lebih Banyak Interview</Typography>
                    <MenuBtn
                      onClick={() => {
                        trackClickEvent("Create CV Button Clicked", {
                          userId: user?.uid,
                          tempUID: user?.tempUID,
                        });
                        navigate('/cv-menu')
                      }}
                      variant="contained"
                      disableElevation
                      className={classes.boxBtn}>
                      CV Maker
                    </MenuBtn>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6} sm={6} item sx={{ display: 'flex', height: '100%', }}>
                <Box
                  // component={Link}
                  // to="https://hack.karir.ai/application"
                  // href="https://hack.karir.ai" 
                  // variant="contained"
                  // disableElevation
                  className={classes.frontBox}
                >
                  <Box className={classes.innerBox}  >
                    {/* <SendIcon fontSize="large" /> */}
                    <Name className={classes.boxName}>Kirim Lamaran Otomatis</Name>
                    <img className={classes.boxImg} draggable={false} src={iconSearch} alt="" />
                    <Typography className={classes.boxContent}>Kirim secara otomatis CV ke banyak perusahaan secara sekaligus hanya dengan 1x klik.</Typography>
                    <MenuBtn
                      color="primary"
                      // component={Link}
                      // to="https://hack.karir.ai/application"
                      href="https://hack.karir.ai"
                      onClick={() => {
                        trackClickEvent("Kirim lamaran otomatis Button Clicked", {
                          userId: user?.uid,
                          tempUID: user?.tempUID,
                        });
                      }}
                      variant="contained"
                      disableElevation
                      className={classes.boxBtn}
                    >Kirim Sekarang</MenuBtn>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6} sm={6} item sx={{ display: 'flex', height: '100%', }}>
                <Box
                  // component={Link}
                  // to="/optimizer"
                  // disableElevation
                  className={classes.frontBox}
                >
                  <Box className={classes.innerBox}>
                    {/* <FindReplace fontSize="large" /> */}
                    <Name className={classes.boxName}>CV ATS Optimizer</Name>
                    <img className={classes.boxImg} draggable={false} src={iconATS} alt="" />
                    <Typography className={classes.boxContent}>Dengan Teknologi AI yang canggih untuk menemukan hal-hal yang perlu ditambahkan ke dalam CV.</Typography>
                    <MenuBtn
                      component={Link}
                      to="/optimizer"
                      variant="contained"
                      disableElevation
                      className={classes.boxBtn}
                      onClick={() => {
                        trackClickEvent("Optimizer Button Clicked", {
                          userId: user?.uid,
                          tempUID: user?.tempUID,
                        });
                      }}
                    >Optimasi CV</MenuBtn>
                  </Box>
                </Box>
              </Grid>

              <Grid xs={6} sm={6} item sx={{ display: 'flex', height: '100%', }}>
                <Box
                  // onClick={() => {
                  //   setDialogImport(true);
                  // }}
                  // disableElevation
                  className={classes.frontBox}
                >
                  <Box className={classes.innerBox}>
                    {/* <LinkedInIcon fontSize="large" /> */}
                    <Name className={classes.boxName}>Import CV LinkedIn</Name>
                    <img className={classes.boxImg} draggable={false} src={iconLI} alt="" />
                    <Typography className={classes.boxContent}>Buat CV Dari informasi yang ada di akun linkedin kamu</Typography>
                    <MenuBtn
                      aria-label="upload-cv"
                      variant="contained"
                      onClick={() => {
                        trackClickEvent("Import CV Button Clicked", {
                          userId: user?.uid,
                          tempUID: user?.tempUID,
                        });
                        setDialogImport(true);
                      }}
                      disableElevation
                      className={classes.boxBtn}
                    >Import CV</MenuBtn>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Paper>

      <GuideDialog
        open={dialogGuide}
        handleClose={() => {
          setDialogGuide(false);
        }}
        content="Welcome"
        idTutorial="welcome"
      />
      <DialogImportCv
        open={dialogimport}
        handleClose={() => {
          setDialogImport(false);
        }}
      />
    </>
  );
}
