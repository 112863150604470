// MUI
import { Container, Box, Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";

// React Router Dom
import { Outlet, useLocation } from "react-router-dom";
import AppHeader from "./components/appHeader";

import { ScrollRestoration } from "react-router-dom";
import BottomNav from "./components/BottomNav";
import { useFirebase } from "./contexts/FirebaseProvider";
import usePersistQueryString from "./hooks/usePersistQueryString";
function App() {
  const [backdropLoading, setBackdropLoading] = useState(false);
  const { user } = useFirebase();
  const location = useLocation();
  usePersistQueryString();
  return (
    <Container sx={{ padding: 0, maxWidth: 'inherit !important'
    }}>
      <ScrollRestoration />
      <AppHeader />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          padding: "0 16px",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Outlet context={{ backdropLoading, setBackdropLoading }} />
      </Box>
      {user?.uid && location.pathname === "/" && (
        <Box>
          <BottomNav setBackdropLoading={setBackdropLoading} />
        </Box>
      )}

      <Backdrop style={{ zIndex: 1000 }} open={backdropLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Container>
  );
}

export default App;
