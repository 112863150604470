import React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import InputCustom from './InputCustom';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            unmask={true}
            mask="+## #### #### ####"
            definitions={{
                '#': /[0-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};



export default function InputPhoneNumber({ ...restProps }) {


    return <InputCustom
        {...restProps}
        inputComponent={TextMaskCustom}
    />
}