import React from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
    BottomNavigation,
    BottomNavigationAction,
    styled,
} from "@mui/material";
import IcDashboard from "../assets/icon/dashboard";
// import IcPin from "../assets/icon/pin";
import IcPeople from "../assets/icon/people";
import IcCredits from "../assets/icon/credits";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { functions } from "../contexts/FirebaseProvider";
import { dashboardBaseUrl } from "../config/baseUrls";

// Styled Component
const NavCon = styled(BottomNavigation)(() => ({
    position: "fixed",
    bottom: "0px",
    left: "0px",
    right: "0px",
    borderTop: "solid 1px #ddd",
    paddingTop: "5px",
    backgroundColor: "#fff",
    zIndex: 999,
    width: "100%",
    height: "72px !important",
}));

const NavItem = styled(BottomNavigationAction)(() => ({
    maxWidth: "160px",
    fontSize: 12,
    "& .MuiBottomNavigationAction-label": {
        fontSize: "11px !important",
        marginTop: "10px",
    },
}));
function BottomNav({ setBackdropLoading }) {

    const { mutateAsync: reqCustomToken, isLoading: isLoadingCustomToken } = useFunctionsCall(functions, "user-reqCustomToken");

    // if (loading) {
    //     return <Backdrop style={{ zIndex: 1000 }} open={loading}>
    //         <CircularProgress color="secondary" />
    //     </Backdrop>
    // }
    return (
        <>

            <NavCon>
                <NavItem
                    showLabel
                    label="Dashboard"
                    icon={<IcDashboard />}
                    disabled={isLoadingCustomToken}
                    onClick={async () => {
                        setBackdropLoading(true)
                        const response = await reqCustomToken()
                        // const response = await getTokenReasec()
                        // console.log(response)
                        if (response?.customToken) {
                            window.location.href = `${dashboardBaseUrl}/login-by-token/${response?.customToken}`

                        }
                    }}
                //   component={Link}
                //   to="/home"
                //   value="/home"
                />
                <NavItem
                    showLabel
                    label="Ajak Teman"
                    icon={<IcPeople />}
                    onClick={async () => {
                        setBackdropLoading(true)
                        const response = await reqCustomToken()
                        // const response = await getTokenReasec()
                        // console.log(response)
                        if (response?.customToken) {
                            window.location.href = `${dashboardBaseUrl}/login-by-token/${response?.customToken}/ajakTeman`

                        }
                    }}
                //   component={Link}
                //   to="/misi"
                //   value="/misi"
                />
                <NavItem
                    showLabel
                    label="Kredit Saya"
                    icon={<IcCredits />}
                    onClick={async () => {
                        setBackdropLoading(true)
                        const response = await reqCustomToken()
                        // const response = await getTokenReasec()
                        // console.log(response)
                        if (response?.customToken) {
                            window.location.href = `${dashboardBaseUrl}/login-by-token/${response?.customToken}/myCredit`

                        }
                    }}
                //   component={Link}
                //   to="/myCoin"
                //   value="/myCoin"
                />
                <NavItem
                    showLabel
                    label="Setelan"
                    icon={<SettingsOutlinedIcon viewBox="2 2 20 20" />}
                    onClick={async () => {
                        setBackdropLoading(true)
                        const response = await reqCustomToken()
                        // const response = await getTokenReasec()
                        // console.log(response)
                        if (response?.customToken) {
                            window.location.href = `${dashboardBaseUrl}/login-by-token/${response?.customToken}/settings`

                        }
                    }}
                //   component={Link}
                //   to="/settings"
                //   value="/settings"
                />
            </NavCon>
        </>
    );
}

export default BottomNav;
