import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, DialogContent, Typography } from '@mui/material';
import GoogleLogo from '../assets/icon/google';
import freeCoins from '../assets/icon-free-coins.png'

function SignInDialogAI(props) {

    const { handleSignInWithGoogle, ...restProps } = props;
    return (
        <Dialog maxWidth="lg" sx={{
            // width: '150vw'
        }}  {...restProps}>
            <DialogTitle align='center'>Sign in to Karir.ai</DialogTitle>
            <DialogContent sx={{ padding: 0, }}>
                <Box sx={{
                    width: '100%',
                }}> 
                    <img
                        src={freeCoins}
                        alt=''
                        style={{ display: 'block', margin: '0 auto 20px', }}
                    />
                    <Typography sx={{
                        margin: 'auto', padding: '10px 20px',  backgroundColor: '#F26722', borderTop: '2px dotted #fff', borderBottom: '2px dotted #fff', color: '#fff', textAlign: 'center',
                    }}>Dapatkan 10 coin gratis dengan login ke karir.ai</Typography>
                </Box>
                <Box sx={{
                    margin: '20px 0',
                    textAlign: 'center',
                }}>
                    <Box>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                color: "rgba(0,0,0,0.54)",
                                marginTop: "10px",
                                "&: hover": {
                                    backgroundColor: "#eee",
                                },
                                "& .MuiButton-startIcon": {
                                    marginLeft: "0px",
                                    marginRight: "20px",
                                }
                            }}
                            size="large"
                            variant="contained"
                            startIcon={
                                <GoogleLogo sx={{ fontSize: "18px !important" }} />
                            }
                            onClick={handleSignInWithGoogle}
                        >Sign in with Google</Button>
                    </Box>
                    <Box>
                        <Typography sx={{ marginTop: '13px' }}>Atau</Typography>
                        <span
                            style={{
                                color: "#F26722",
                                cursor: "pointer",
                                textDecoration: "underline",
                            }}
                            onClick={() => {
                                window.location.pathname = '/signin'
                            }}
                        >
                            Login dengan email & password
                        </span>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

SignInDialogAI.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default SignInDialogAI;