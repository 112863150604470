// React
import React, { useEffect, useState, useRef } from "react";
// mui
import { Typography, Button, Container, Grid } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
// Components
import { InputCustom, DateRangeCustom, SavedItem } from "../../components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentData,
  useFirestoreDocumentMutation,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { cvsCollectionRef } from "../../contexts/FirebaseProvider";
import { useSnackbar } from "notistack";
//import axios from "axios";
import TutorialVideo from "../../components/TutorialVideo";

const initialValues = {
  courseName: "",
  courseInstitution: "",
  startDate: null,
  endDate: null,
  whatYouLearn: "",
  // howDoYouUse: ''
};
const subcollection = "courses";
const title = "Kursus";

function CoursePage() {
  // context
  //const { userData } = useFirebase();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? "default";
  const cvDocRef = doc(cvsCollectionRef, params.cvId);
  const subCollectionRef = collection(cvDocRef, subcollection);
  const currDocRef = doc(subCollectionRef, id);
  const { mutate: addDoc, isLoading: addLoading } =
    useFirestoreCollectionMutation(subCollectionRef);
  const { data: list } = useFirestoreQueryData(
    [subcollection, params.cvId],
    subCollectionRef,
    { subscribe: true, idField: "id" }
  );
  const { data: item } = useFirestoreDocumentData(
    ["subcollection", id],
    currDocRef,
    { subscribe: true, idField: "id" }
  );
  const { mutate: updateDoc, isLoading: updateLoading } =
    useFirestoreDocumentMutation(currDocRef, { merge: true });
  // const { data } = useFirestoreDocumentData(["cvs", params.cvId], ref);

  // State
  const [
    {
      courseName,
      courseInstitution,
      startDate,
      endDate,
      whatYouLearn,
      // howDoYouUse
    },
    setForm,
  ] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("create");
  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);
  const bottomsheetRef = useRef();

  // snack
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (item) {
      setForm({ ...initialValues, ...item });
    }
  }, [item]);

  useEffect(() => {
    if (id === "new") {
      setMode("create");
    } else if (id !== "default") {
      setMode("update");
    }
  }, [id]);

  useEffect(() => {
    if (id !== "default") {
      setBottomSheetOpen(true);
    } else {
      setBottomSheetOpen(false);
    }
  }, [id]);

  const handleDismissButtomsheet = () => {
    navigate({
      search: "",
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
  };

  const handleDateChange = (value, type) => {
    if (type === "start") {
      setForm((form) => ({
        ...form,
        startDate: value,
      }));
      setErrors((errors) => ({
        ...errors,
        startDate: "",
      }));
    } else {
      setForm((form) => ({
        ...form,
        endDate: value,
      }));
      setErrors((errors) => ({
        ...errors,
        endDate: "",
      }));
    }
  };

  const handleAdd = () => {
    if (addLoading) {
      return;
    }

    navigate({
      search: `?id=new`,
    });
  };

  const handleUpdate = async () => {
    if (updateLoading) {
      return;
    }
    let findErrors = {};

    if (!courseName) {
      findErrors.courseName = "Nama kursus wajib diisi.";
    }

    if (!courseInstitution) {
      findErrors.courseInstitution = "Tempat kursus wajib diisi.";
    }

    if (!startDate) {
      findErrors.startDate = "Tanggal mulai wajib diisi.";
    }

    if (!endDate) {
      findErrors.endDate = "Tanggal selesai wajib diisi.";
    }

    if (!whatYouLearn) {
      findErrors.whatYouLearn = "Apa yang dipelajari wajib diisi.";
    }
    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      if (mode === "create") {
        addDoc(
          {
            courseName,
            courseInstitution,
            startDate,
            endDate,
            whatYouLearn,
            // howDoYouUse,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess(data) {
              handleDismissButtomsheet();
              setForm(initialValues);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
            onError(err) {
              console.log(err);
            },
          }
        );
      } else {
        updateDoc(
          {
            courseName,
            courseInstitution,
            startDate,
            endDate,
            whatYouLearn,
            // howDoYouUse,
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialValues);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
          }
        );
      }
      // pixel
      //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_course&email=${userData?.email}`);

    }
  };

  const handleDelete = async (selectedId) => {
    return await deleteDoc(doc(subCollectionRef, selectedId));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {/* Saved Item */}
          <SavedItem
            type={title}
            list={list}
            titleField="courseName"
            subtitleField="courseInstitution"
            activeId={id}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            addLoading={addLoading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TutorialVideo src="https://www.youtube.com/embed/hqwiX04QpkA" />
        </Grid>

      </Grid>

      <BottomSheet
        initialFocusRef={false}
        open={bottomsheetOpen}
        ref={bottomsheetRef}
        onDismiss={handleDismissButtomsheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
        header={
          <Typography>
            {mode === "update" ? "Edit" : "Tambah"} {title}
          </Typography>
        }
        footer={
          <Button
            variant="contained"
            fullWidth
            // onClick={handleDismissButtomsheet}
            className="w-full"
            onClick={handleUpdate}
            disabled={updateLoading || addLoading}
          >
            Simpan
          </Button>
        }
      >
        <Container maxWidth="xs">
          {/* Input Nama Kursus */}
          <InputCustom
            title="Nama kursus yang diikuti?"
            placeholder="Bootstrap Crash Course"
            name="courseName"
            onChange={handleChange}
            value={courseName}
            required
            error={errors?.courseName ? true : false}
            helperText={errors?.courseName}
          />
          {/* Input Nama Institusi Kursus */}
          <InputCustom
            title="Di mana Anda mengikuti kursus?"
            placeholder="SuperCoder Academy"
            name="courseInstitution"
            onChange={handleChange}
            value={courseInstitution}
            required
            error={errors?.courseInstitution ? true : false}
            helperText={errors?.courseInstitution}
          />

          {/* Input Range */}
          <DateRangeCustom
            title="Kapan Anda mengikuti kursus tersebut?"
            onChangeStart={(value) => handleDateChange(value, "start")}
            valueStart={startDate?.toDate?.() ?? startDate}
            onChangeEnd={(value) => handleDateChange(value, "end")}
            valueEnd={endDate?.toDate?.() ?? endDate}
            errorStart={errors?.startDate ? true : false}
            errorEnd={errors?.endDate ? true : false}
            helperTextStart={errors?.startDate}
            helperTextEnd={errors?.endDate}
          />

          {/* Input whatYouLearn*/}
          <InputCustom
            title="Keterampilan apa yang didapatkan?"
            placeholder="Memahami cara menggunakan bootstrap"
            name="whatYouLearn"
            onChange={handleChange}
            value={whatYouLearn}
            multiline
            rows={5}
            error={errors?.whatYouLearn ? true : false}
            helperText={errors?.whatYouLearn}
          />
          {/* Input howDoYouUse*/}
          {/* <InputCustom
                        title="Bagaimana keterampilan itu digunakan?"
                        placeholder="Membuat tampilan web dengan bootstrap"
                        name="howDoYouUse"
                        onChange={handleChange}
                        value={howDoYouUse}
                        multiline
                        maxRows={3}
                    /> */}
        </Container>
      </BottomSheet>
    </>
  );
}

export default CoursePage;
