import { useState } from "react";
import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharp from "@mui/icons-material/ArrowForwardIosSharp";
import YouTube from "@mui/icons-material/YouTube";
import { Typography } from "@mui/material";

const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    // "&:not(:last-child)": {
    //   borderBottom: 0
    // },
    "&:before": {
        display: "none"
    }
}))

const AccordionSummary = styled(props => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)"
}))
export default function TutorialVideo({ src }) {
    const [expanded, setExpanded] = useState("panel1")

    const handleChange2 = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    return (
        <Accordion sx={{ margin: '20px 0 95px', }} expanded={expanded === 'panel1'} onChange={handleChange2('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography sx={{ display: 'flex', alignItems: 'center', }}><YouTube sx={{ marginRight: '5px', color: '#f00' }} />Video Tutorial</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ width: '100%' }}>
                    <iframe width="100%" height="315" src={src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}