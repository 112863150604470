import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  List,
  ListItem,
  Typography,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
  CircularProgress,
  Menu,
  MenuList,
  MenuItem
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
  useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";
import {
  query,
  where,
  orderBy,
  serverTimestamp,
  doc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { InputCustom } from "../../components";
import Title from "../../components/Title";
import {
  cvsCollectionRef,
  functions,
  jobTrendCollectionRef,
  optimizerCollectionRef,
  storage,
  useFirebase,
} from "../../contexts/FirebaseProvider";
import formatDate from "../../utils/formatDate";
import OptimizerDetail from "./OptimizerDetail";
import DialogJob from "./DialogJob";
import { usePaywall } from "../../contexts/PaywallProvider";
import { useFunctionsCall } from "@react-query-firebase/functions";
import trackClickEvent from "../../utils/trackClickEvent";
import ArticleIcon from "@mui/icons-material/Article";
//import axios from "axios";
import { ref, uploadBytesResumable } from "firebase/storage";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MenuIcon from '@mui/icons-material/Menu';
import DialogImportCv from "../home/DialogImportCv";
// accordion
const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0
  // },
  "&:before": {
    display: "none"
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}))

export default function OptimizerPage() {
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState("panel1")
  const [expanded2, setExpanded2] = React.useState("panel2")

  const handleChange2 = panel => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false)
    }

    const handleChange3 = panel => (event, newExpanded) => {
      setExpanded2(newExpanded ? panel : true)
    }

  const { mutateAsync: cvOptimizer, isLoading: loading } = useFunctionsCall(
    functions,
    "cv-cvOptimizer",
    { timeout: 540000 }
  );

  const cvId = searchParams.get("cvId") ?? null;

  const { user, openSignInDialog } = useFirebase();

  const { setBuyDialog, setTopUpDialog } = usePaywall();

  let q = query(
    cvsCollectionRef,
    where("tempUID", "==", user?.tempUID),
    where("userId", "==", user?.uid),
    orderBy("updatedAt", "desc")
  );

  const { data: list, isLoading } = useFirestoreQueryData(
    [
      "cvs",
      {
        tempUID: user?.tempUID,
        userId: user?.uid,
      },
    ],
    q,
    { subscribe: true, idField: "id" }
  );

  const { data: listJob } = useFirestoreQueryData(
    "jobtrend",
    jobTrendCollectionRef,
    {
      subscribe: true,
      idField: "id",
    }
  );

  let qo = query(
    optimizerCollectionRef,
    where("uid", "==", user?.uid === null ? user?.tempUID : user?.uid),
    orderBy("createdAt", "desc")
  );

  const { data: listHistory } = useFirestoreQueryData(
    [
      "optimizer",
      {
        uid: user?.uid === null ? user?.tempUID : user?.uid,
      },
    ],
    qo,
    { subscribe: true, idField: "id" }
  );

  const { mutate: addOptimizer } = useFirestoreCollectionMutation(
    optimizerCollectionRef
  );

  const id = searchParams.get("id") ?? "default";

  const currDocRef = doc(optimizerCollectionRef, id);

  const { mutate: updateOptimizer } = useFirestoreDocumentMutation(currDocRef, {
    merge: true,
  });

  const [selectedCV, setSelectedCV] = useState(null);

  const [targetJobTitle, setTargetJobTitle] = useState("");

  const [targetJobDesc, setTargetJobDesc] = useState("");

  const [optimizer, setOptimizer] = useState(null);
  const [errors, setErrors] = useState({});
  const [dialog, setDialog] = useState(false);
  const [namaFile, setNamaFile] = useState();
  const [loadingFile, setLoadingFile] = useState(false)

  useEffect(() => {
    setBuyDialog((curr) => ({ ...curr, loading }));
  }, [loading, setBuyDialog]);

  useEffect(() => {
    if (cvId && list?.length > 0) {
      const findCV = list?.find?.((cv) => cv.id === cvId);

      if (findCV) {
        setSelectedCV({
          id: findCV.id,
          label: `${findCV?.title || "Untitled CV "} (${formatDate(
            findCV.createdAt?.toDate?.()
          )})`,
        });
      }
    }
  }, [cvId, list]);

  const handleUploadCV = async (e) => {
    const file = e.target.files[0];

    // if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {

    //     setError(errorDua => ({
    //         ...errorDua,
    //         url: `Tipe file tidak didukung: ${file.type}`
    //     }))
    // }
    // else if (file.size >= 512000) {
    //     setError(errorDua => ({
    //         ...errorDua,
    //         url: `Ukuran file terlalu besar > 500KB`
    //     }))
    // } else {

    const reader = new FileReader();

    reader.onabort = () => {

      setErrors(errorDua => ({
        ...errorDua,
        url: `Proses pembacaan file dibatalkan`
      }))
    }

    reader.onerrorDua = () => {

      setErrors(errorDua => ({
        ...errorDua,
        url: 'File tidak bisa dibaca'
      }))
    }

    reader.onload = async () => {
      setErrors(errorDua => ({
        ...errorDua,
        url: ''
      }))
      setLoadingFile(true);
      try {
        // console.log(file.type)
        const bannerStorageRef = ref(storage, `attachment/${user?.uid}`);

        // const urlExt = file.name.substring(file.name.lastIndexOf('.'));
        const namaFile = file.name
        const urlRef = ref(bannerStorageRef, namaFile);
        await uploadBytesResumable(urlRef, file);
        // console.log(file.name)
        setNamaFile(file.name)
        // const url = await getDownloadURL(uploadTask.ref)
        // console.log(url)

        // const urlSnapshot = await urlRef.putString(reader.result, 'data_url');


        // const urlUrl = await urlSnapshot.ref.getDownloadURL();

        // setForm(currentForm => ({
        //   ...currentForm,
        //   url: url,
        //   nama_file_bawaan: file.name,
        //   type: urlExt
        // }));

        // setSomethingChange(true);
      } catch (e) {
        console.log(e.message)
        setErrors(errorDua => ({
          ...errorDua,
          url: e.message
        }))


      }

      setLoadingFile(false);

    }

    reader.readAsDataURL(file);

  }
  // console.log(errors)
  const transformList =
    list?.map?.((cv, index) => ({
      id: cv.id,
      label: `${cv?.title ?? "Untitled CV "} (${formatDate(
        cv.createdAt?.toDate?.()
      )})`,
    })) ?? [];

  const handleOptimize = async () => {
    try {
      const { data } = await cvOptimizer({
        cvId: selectedCV?.id ? selectedCV?.id : '',
        jobTitle: targetJobTitle,
        jobDesc: targetJobDesc,
        cv_path: namaFile ? `attachment/${user?.uid}/${namaFile}` : ''
      });

      addOptimizer(
        {
          uid: user?.uid === null ? user?.tempUID : user?.uid,
          cv_id: selectedCV?.id ? selectedCV?.id : '',
          input: {
            job_title: targetJobTitle,
            job_desc: targetJobDesc,
          },
          output: data,
          createdAt: serverTimestamp(),
          cv_path: namaFile ? `attachment/${user?.uid}/${namaFile}` : '',

        },
        {
          onSuccess(dataOp) {
            setOptimizer({
              id: dataOp?.id,
              cv_id: selectedCV?.id ? selectedCV.id : '',
              job_title: targetJobTitle,
              job_desc: targetJobDesc,
              cv_path: namaFile ? `attachment/${user?.uid}/${namaFile}` : '',
              ...data,
            });
            // navigate(`/optimizer?id=${dataOp?.id}`)
          },
          onError(err) {
            console.log(err);
          },
        }
      );

      setBuyDialog({
        open: false,
        name: "",
        price: 0,
      });
    } catch (error) {
      console.log(error)
      setBuyDialog({
        open: false,
        name: "",
        price: 0,
      });

      setTopUpDialog(true);
    }
  };

  const handleReOptimize = async (id, job_title, job_desc, cv_id, cv_path) => {
    // console.log(cv_path)
    try {
      const { data } = await cvOptimizer({
        cvId: cv_id || '',
        jobTitle: job_title,
        jobDesc: job_desc,
        cv_path: cv_path || ''

      });

      updateOptimizer(
        {
          input: {
            job_title: job_title,
            job_desc: job_desc,
          },
          output: data,
          updatedAt: serverTimestamp(),
        },
        {
          onSuccess() { },
          onError(err) {
            console.log(err);
          },
        }
      );

      setOptimizer({
        id: id,
        cv_id: cv_id,
        job_title: job_title,
        job_desc: job_desc,
        cv_path: cv_path ? cv_path : '',
        ...data,
      });

      setBuyDialog({
        open: false,
        name: "",
        price: 0,
      });
    } catch (error) {
      setBuyDialog({
        open: false,
        name: "",
        price: 0,
      });

      setTopUpDialog(true);
    }
  };

  const handleSelect = ({ title, desc }) => {
    setTargetJobTitle(title);

    setTargetJobDesc(desc);

    setErrors((errors) => ({
      ...errors,
      targetJobTitle: "",
      targetJobDesc: "",
    }));

    setDialog(false);
  };

  const handleOpenBuyDialog = async () => {
    let findErrors = {};

    if (!selectedCV && !namaFile) {
      findErrors.selectedCV = "salah satu wajib diisi";
      findErrors.url = "salah satu wajib diisi"
    }
    if (!targetJobTitle) {
      findErrors.targetJobTitle = "Job Title wajib diisi.";
    }

    if (!targetJobDesc) {
      findErrors.targetJobDesc = "Job Desc wajib diisi.";
    }

    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      trackClickEvent("Optimizer-Buy Button Clicked", {
        userId: user?.uid,
        tempUID: user?.tempUID,
      });

      setBuyDialog({
        open: true,
        name: `Akses 1x Optimizer untuk ${selectedCV?.label || namaFile}`,
        price: 5,
        callFunction: handleOptimize,
        loading: loading,
        enable: selectedCV?.access,
      });
      // pixel
      //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_optimize&email=${userData?.email}`);

    }
  };

  const handleOpenReBuyDialog = ({ id, job_title, job_desc, cv_id, cv_path }) => {
    trackClickEvent("Optimizer-Buy Button Clicked", {
      userId: user?.uid,
      tempUID: user?.tempUID,
    });

    setBuyDialog({
      open: true,
      name: `Akses 1x Optimizer untuk ${job_title}`,
      price: 5,
      callFunction: () => handleReOptimize(id, job_title, job_desc, cv_id, cv_path),
      loading: loading,
      enable: false,
    });
  };
  // console.log(optimizer)

  const [dialogimport, setDialogImport] = useState(false);

  const [anchorEl2, setAnchorEl2] = React.useState(null)
    const openMe = Boolean(anchorEl2)
    const handleClick = event => {
    setAnchorEl2(event.currentTarget)
    }
    const handleClose = () => {
    setAnchorEl2(null)
    }

  return (
    <>
      <Box
        sx={{
          padding: "10px 0 0",
          // paddingBottom: 8,
          position: "relative",
        }}
      >
        {/* Title */}
        <Title content="CV ATS Optimizer" />

        <Grid item xs={12} md={2} >
                {/* mobile nav */}
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', } }}>
                    <Button
                        sx={{ width: '100%' }}
                        id="basic-button"
                        aria-controls={openMe ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMe ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Typography sx={{ marginRight: '5px', fontWeight: 'bold' }}>Menu</Typography>
                        <MenuIcon />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl2}
                        open={openMe}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                        sx={{ display: 'flex', width: '100%' }}
                    >
                        <MenuList
                            style={{
                                color: '#F26722', 
                                display: 'flex',
                                flexFlow: 'column wrap',
                                width: '100%'
                            }}
                        // autoFocusItem={open}
                        // id="composition-menu"
                        // aria-labelledby="composition-button"
                        // onKeyDown={handleListKeyDown}
                        >
                            <MenuItem sx={{
                                textAlign: 'right', fontSize: "14px", fontWeight: 'bold', width: '100%'
                            }} onClick={() => {
                                navigate('/cv-menu')
                            }}>CV MAKER</MenuItem>
                            <MenuItem sx={{
                                textAlign: 'right', fontSize: "14px", fontWeight: 'bold', width: '100%'
                            }} onClick={() => {
                                window.location.replace("https://hack.karir.ai")
                            }}>KIRIM LAMARAN OTOMATIS</MenuItem>
                            <MenuItem sx={{
                                textAlign: 'right', fontSize: "14px", fontWeight: 'bold', width: '100%'
                            }} onClick={() => {
                                setDialogImport(true);
                            }}>IMPORT CV LINKEDIN</MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
                {/* desktop nav */}
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block',  } }}>
                <MenuList
                    style={{
                        color: '#F26722',
                        margin: 'auto',
                        width: 'fit-content',
                        display: 'flex'
                    }}
                // autoFocusItem={open}
                // id="composition-menu"
                // aria-labelledby="composition-button"
                // onKeyDown={handleListKeyDown}
                >
                    <MenuItem sx={{
                        textAlign: 'right', fontSize: "14px", float: 'right', fontWeight: 'bold'
                    }} onClick={() => {
                        navigate('/cv-menu')
                    }}>CV MAKER</MenuItem>
                    <MenuItem sx={{
                        textAlign: 'right', fontSize: "14px", float: 'right', fontWeight: 'bold'
                    }} onClick={() => {
                        window.location.replace("https://hack.karir.ai")
                    }}>KIRIM LAMARAN OTOMATIS</MenuItem>
                    <MenuItem sx={{
                        textAlign: 'right', fontSize: "14px", float: 'right', fontWeight: 'bold'
                    }} onClick={() => {
                        setDialogImport(true);
                    }}>IMPORT CV LINKEDIN</MenuItem>
                </MenuList>
                </Box>
                
            </Grid>

        {optimizer !== null ? (
          <OptimizerDetail
            data={{
              ...optimizer,
            }}
            setBuy={(data) => handleOpenReBuyDialog(data)}
          />
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} sx={{ margin: '30px 0' }}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange2('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      <Typography sx={{ display: 'flex', alignItems: 'center', }}><YouTubeIcon sx={{ marginRight: '5px', color: '#f00' }} /> Video Tutorial</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ width:'100%' }}>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/Xw9x0RK5eRw?si=gOhZ8-N2iDncEYu7" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </Box>
                    </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={5.5} margin='normal'>
                <Autocomplete
                  loading={isLoading}

                  disablePortal
                  value={selectedCV}
                  onChange={(e, value) => {
                    setSelectedCV(value);
                    setErrors((errors) => ({
                      ...errors,
                      selectedCV: "",
                    }));
                  }}
                  id="pilih-cv-combo"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={transformList}
                  noOptionsText={
                    <Button
                      component={Link}
                      to="/"
                      sx={{ textTransform: "none", padding: 0, color: "inherit" }}
                    >
                      Kamu belum punya CV, buat CV baru
                      <span
                        style={{ color: theme.palette.primary.main, marginLeft: 4 }}
                      >
                        disini
                      </span>
                    </Button>
                  }
                  renderInput={(params) => (
                    <InputCustom
                      containerRef={params.InputProps.ref}
                      placeholder="CV Keren"
                      title="Pilih CV"
                      inputProps={params.inputProps}
                      fullWidth
                      error={errors?.selectedCV ? true : false}
                      helperText={errors?.selectedCV}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <p
                  style={{
                    fontSize: '12px',
                    color: '#515b6f',
                    textAlign: 'center',
                    marginTop: '60px'
                  }}
                >Atau</p>
              </Grid>
              <Grid item xs={5.5}
              // sx={{ paddingLeft: '7px' }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    marginBottom: '5px',
                    color: '#515b6f',
                    marginTop: '19px'
                  }}
                >CV Manual</p>
                <input
                  style={{ display: 'none' }}
                  id="upload-url-file"
                  type='file'
                  onChange={handleUploadCV}
                ></input>
                <label htmlFor="upload-url-file">
                  <Button
                    name='url'
                    fullWidth
                    variant='outlined'
                    size='large'
                    sx={{
                      padding: '15px',
                      borderRadius: '0px'
                    }}
                    component="span"
                  // onChange={handleUploadCV}
                  >
                    {loadingFile ? <CircularProgress size={25} /> : ' Upload CV'}

                  </Button>

                </label>
                {
                  namaFile && <p style={{
                    padding: "0px",
                    margin: "0px",
                    fontSize: '12px',
                    color: '#a2a2a2',
                  }}>{namaFile}</p>
                }
              </Grid>
            </Grid>
            <InputCustom
              title="Job Title"
              placeholder="Data Scientist"
              name="jobTitle"
              onChange={(e) => {
                setTargetJobTitle(e.target.value);
                setErrors((errors) => ({
                  ...errors,
                  targetJobTitle: "",
                }));
              }}
              value={targetJobTitle}
              disabled={loading}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "20px",
                      padding: "4px 10px",
                    }}
                    onClick={() => {
                      trackClickEvent(
                        "Optimizer-Pilih Profesi Trending Button Clicked",
                        {
                          userId: user?.uid,
                          tempUID: user?.tempUID,
                        }
                      );
                      setDialog(true);
                    }}
                  >
                    Pilih Profesi Trending
                  </Button>
                </InputAdornment>
              }
              error={errors?.targetJobTitle ? true : false}
              helperText={errors?.targetJobTitle}
            />
            {/* Input Job Desc*/}
            <Box sx={{margin: '30px 0'}}>
              <Accordion expanded2={expanded2 === 'panel2'} onChange={handleChange3('panel1')}>
                  <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" sx={{ display: 'flex', alignItems: 'center', }}>
                      <Typography sx={{ display: 'flex', alignItems: 'center', }}><YouTubeIcon sx={{ marginRight: '5px', color: '#f00' }} /> Video Tutorial</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      <Box sx={{ width:'100%' }}>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/oGaTczF7Ou8?si=E93lrWdU8KRYu38W" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                      </Box>
                  </AccordionDetails>
              </Accordion>
            </Box>
            <InputCustom
              title="Job Description"
              placeholder="Enter Your Target Job Description here..."
              name="jobDesc"
              onChange={(e) => {
                setTargetJobDesc(e.target.value);
                setErrors((errors) => ({
                  ...errors,
                  targetJobDesc: "",
                }));
              }}
              value={targetJobDesc}
              multiline
              rows={5}
              error={errors?.targetJobDesc ? true : false}
              helperText={errors?.targetJobDesc}
              disabled={loading}
            />
            <Button
              sx={{ marginY: 2 }}
              variant="contained"
              fullWidth
              onClick={
                user?.uid === null ? openSignInDialog : handleOpenBuyDialog
              }
              disabled={loading}
            >
              Optimize (5 Kredit)
            </Button>

            {/* History */}
            <Typography
              sx={{
                color: "textColor.neutral80",
                fontWeight: "600",
                fontSize: "16px",
                marginTop: "24px",
              }}
            >
              Riwayat Penggunaan Optimizer
            </Typography>

            {/* List */}
            <List>
              {listHistory?.map((item, idx) => {
                const cv = list?.find?.((cvlist) => cvlist?.id === item?.cv_id);
                const namaCV = item?.cv_path?.split('/')?.[2]
                return (
                  <ListItem
                    key={idx}
                    divider
                    secondaryAction={
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          textTransform: "capitalize",
                          borderRadius: "20px",
                          padding: "4px 10px",
                        }}
                        onClick={() => {
                          navigate({
                            search: `?id=${item?.id}`,
                          });
                          setOptimizer({
                            job_title: item?.input?.job_title,
                            job_desc: item?.input?.job_desc,
                            id: item?.id,
                            cv_id: item?.cv_id || '',
                            cv_path: item?.cv_path || '',
                            ...item?.output,
                          });
                        }}
                      >
                        Lihat Detail
                      </Button>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                        }}
                      >
                        <ArticleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${item?.input?.job_title ?? "No Title"} (${cv?.title ? cv?.title : namaCV || ''
                        })`}
                      secondary={formatDate(item?.createdAt?.toDate?.())}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Box>
      <DialogImportCv
                open={dialogimport}
                handleClose={() => {
                    setDialogImport(false);
                }}
            />

      <DialogJob
        open={dialog}
        handleClose={() => setDialog(false)}
        listJob={listJob}
        handleSelect={handleSelect}
      />
    </>
  );
}
