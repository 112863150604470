// React
import React, { useCallback, useEffect, useState } from "react";

// MUI
import { Avatar, Badge, Box, IconButton, Typography, useTheme, Button, Autocomplete, Grid } from "@mui/material";

// Components
import { InputCustom } from "../../components";
import { useOutletContext, useParams } from "react-router-dom";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { doc, serverTimestamp } from "firebase/firestore";
import { cvsCollectionRef } from "../../contexts/FirebaseProvider";
import InputPhoneNumber from "../../components/InputPhoneNumber";
import UploadFile from "../../components/UploadFile";
import { Close } from "@mui/icons-material";
import InputLocationSearch from "../../components/InputLocationSearch";
// import SwitchCustom from "../../components/SwitchCustom";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
import { useSnackbar } from "notistack";
import DatePickerCustom from "../../components/DatePickerCustom";
//import axios from "axios";

const langOptions = [{ label: 'Bahasa Indonesia', value: 'ID' }, { label: 'Bahasa Inggris', value: 'EN' }];

const initialValues = {
    title: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    address: '',
    photoUrl: '',
    location: {},
    linkedin: '',
    birthDate: null,
    lang: langOptions[0]

};

function BasicInfoPage() {
    // State
    const theme = useTheme();
    const params = useParams();
    //const { userData } = useFirebase();
    // console.log(userData)
    const { basicInfo: data } = useOutletContext();
    const ref = doc(cvsCollectionRef, params.cvId);
    const { mutate: updateBasicInfo, isLoading: updateLoading } = useFirestoreDocumentMutation(ref, { merge: true });

    const [{ title, fullName, email, phoneNumber, address, photoUrl, location, linkedin, birthDate, lang }, setForm] = useState(initialValues)
    const [errors, setErrors] = useState({});


    // snack
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        if (data) {
            setForm({ ...initialValues, ...data });
        }
    }, [data]);


    const handleChange = (e) => {
        const { value, name } = e.target;
        setForm(form => ({
            ...form,
            [name]: value
        }))

        setErrors(errors => ({
            ...errors,
            [name]: ''
        }))
    };

    const handleChangeSwitch = (name, value) => {
        setForm(form => ({
            ...form,
            location: {
                ...form.location,
                [name]: value
            }
        }))

    }

    const onUploadSuccess = useCallback((url) => {
        setForm(form => ({ ...form, photoUrl: url }));
    }, [])

    const handleUpdate = async () => {
        let findErrors = {};

        if (!fullName) {
            findErrors.fullName = 'Nama Lengkap wajib diisi.';
        }

        if (email && !isEmail(email)) {
            findErrors.email = 'Email tidak valid';
        }

        if (linkedin && !isURL(linkedin)) {
            findErrors.linkedin = "URL tidak valid."
        }

        if (Object.values(findErrors).some(message => message !== "")) {
            setErrors(findErrors);
        } else {
            updateBasicInfo({
                title,
                fullName,
                email,
                phoneNumber,
                address,
                photoUrl,
                location,
                linkedin,
                birthDate,
                lang,
                updatedAt: serverTimestamp()
            }, {
                onSuccess() {
                    enqueueSnackbar("Basic info berhasil diperbarui.", { variant: 'success' })
                }
            });
            // pixel
            //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_basicInfo&email=${userData?.email}`);

        }
    }


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {/* <Item></Item>
                     */}
                    {/* Input Title */}
                    <Box>
                        <InputCustom
                            title="Judul CV"
                            placeholder="CV John Cena"
                            name="title"
                            onChange={handleChange}
                            value={title}
                            required
                            error={errors?.title ? true : false}
                            helperText={errors?.title}
                        />
                    </Box>
                    <Box>
                        {/* Input Bahasa CV */}
                        <Autocomplete
                            fullWidth
                            disablePortal
                            value={lang}
                            onChange={(e, value) => {

                                handleChange({
                                    target: {
                                        name: 'lang',
                                        value
                                    }
                                })

                            }}
                            id="pilih-level-education"
                            options={langOptions}
                            renderInput={(params) => <InputCustom
                                containerRef={params.InputProps.ref}
                                placeholder="Bahasa Indonesia"
                                title="Pilih Bahasa CV"
                                inputProps={params.inputProps}
                                fullWidth
                                error={errors?.level ? true : false}
                                helperText={errors?.schoolName}
                            />}
                        />
                    </Box>
                    <Box>
                        {/* Input Nama Lengkap */}
                        <InputCustom
                            title="Nama Lengkap"
                            placeholder="John Cena"
                            name="fullName"
                            onChange={handleChange}
                            value={fullName}
                            required
                            error={errors?.fullName ? true : false}
                            helperText={errors?.fullName}
                        />
                    </Box>
                    <Box>
                        {/* Tanggal lahir */}

                        <DatePickerCustom
                            InputCustomProps={{
                                title: "Tanggal Lahir",
                                placeholder: "dd/MM/YYY"
                            }}
                            disableFuture
                            onChange={(newValue) => {
                                handleChange({
                                    target: {
                                        name: 'birthDate',
                                        value: newValue
                                    }
                                })
                            }}
                            value={birthDate?.toDate?.() ?? birthDate}

                        />
                    </Box>
                    <Box>
                        {/* Input Alamat Email */}
                        <InputCustom
                            title="Alamat Email"
                            placeholder="example@email.com"
                            name="email"
                            onChange={handleChange}
                            value={email}
                            error={errors?.email ? true : false}
                            helperText={errors?.email}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* <Item></Item> */}
                    <Box>
                        {/* Input Phone Number */}
                        <InputPhoneNumber
                            title="Nomor HP / Whatsapp"
                            placeholder="+62 8218 3457 7890"
                            name="phoneNumber"
                            onChange={handleChange}
                            value={phoneNumber}
                        />
                    </Box>
                    <Box>
                        {/* Input Linkedin */}
                        <InputCustom
                            title="LinkedIn"
                            placeholder="https://www.linkedin.com/in/username"
                            name="linkedin"
                            onChange={handleChange}
                            value={linkedin}
                            error={errors?.linkedin ? true : false}
                            helperText={errors?.linkedin}
                        />
                    </Box>
                    {/* Input Alamat*/}
                    <Box>
                        <InputCustom
                            title="Alamat"
                            placeholder="Jl. Cinta No.1"
                            name="address"
                            onChange={handleChange}
                            value={address}
                            multiline
                            maxRows={3}
                        />
                    </Box>
                    <Box>
                        <InputLocationSearch
                            onChange={(newValue) => {
                                handleChange({
                                    target: {
                                        name: 'location',
                                        value: newValue
                                    }
                                })
                            }}
                            value={location}
                            switchProps={{
                                onChange: (event) => {
                                    handleChangeSwitch('showSubdistrict', event.target.checked)
                                },
                                checked: location?.showSubdistrict ?? false
                            }}
                        />
                        {location?.subdistrict_name && <>
                            <InputCustom
                                title="Kecamatan"
                                switchEnabled={false}
                                switchLabel="Tampilkan dalam CV"
                                value={location?.district_name ?? ''}
                                readOnly
                                switchProps={{
                                    onChange: (event) => {
                                        handleChangeSwitch('showDistrict', event.target.checked)
                                    },
                                    checked: location?.showDistrict ?? false
                                }}
                            />
                            <InputCustom
                                title="Kota/Kabupaten"
                                switchEnabled={false}
                                switchLabel="Tampilkan dalam CV"
                                value={location?.city_name ?? ''}
                                readOnly
                                switchProps={{
                                    onChange: (event) => {
                                        handleChangeSwitch('showCity', event.target.checked)
                                    },
                                    checked: location?.showCity ?? false
                                }}
                            />
                            <InputCustom
                                title="Provinsi"
                                switchEnabled={false}
                                switchLabel="Tampilkan dalam CV"
                                value={location?.province_name ?? ''}
                                readOnly
                                switchProps={{
                                    onChange: (event) => {
                                        handleChangeSwitch('showProvince', event.target.checked)
                                    },
                                    checked: location?.showProvince ?? false
                                }}
                            />
                        </>}

                    </Box>
                    <Box sx={{ paddingTop: '20px' }}>
                        {!photoUrl &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: "20px",
                                    gap: 2
                                }}
                            >
                                <Typography sx={{
                                    color: theme.palette.textColor.neutral80,
                                    fontWeight: "600",
                                    fontSize: "14px",
                                }}>Upload Foto</Typography>
                                <UploadFile directory="cvs/photos/" id={params.cvId} onSuccess={onUploadSuccess} />
                            </Box>}

                        {photoUrl &&
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: "20px",
                                gap: 2
                            }}>
                                <Typography sx={{
                                    color: theme.palette.textColor.neutral80,
                                    fontWeight: "600",
                                    fontSize: "14px",
                                }}>Pertinjau Foto</Typography>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }} >
                                    <Badge onClick={() => setForm(form => ({ ...form, photoUrl: "" }))} badgeContent={<IconButton size="small" ><Close /></IconButton>}>
                                        <Avatar sx={{ width: 100, height: "auto" }} variant="square" src={photoUrl} />
                                    </Badge>
                                </Box>
                            </Box>}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        sx={{ marginY: 2, marginBottom: "80px" }}
                        variant="contained"
                        fullWidth
                        // onClick={handleDismissButtomsheet}
                        className="w-full"
                        onClick={handleUpdate}
                        disabled={updateLoading}
                    >
                        Simpan
                    </Button>
                </Grid>
            </Grid>














        </>
    );
}

export default BasicInfoPage;
