// React
import React, { useEffect, useState } from "react";

// React Router Dom
import { useOutletContext, useParams } from "react-router-dom";

// React Query
import {
    useFirestoreDocumentData,
    useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";

// Firebase
import { httpsCallable } from "firebase/functions";
import { doc, serverTimestamp } from "firebase/firestore";

// Notistack
import { useSnackbar } from "notistack";

// Typewriter Effect
import TypewriterComponent from "typewriter-effect";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// MUI Icons
//import Replay from "@mui/icons-material/Replay";
import PsychologyIcon from '@mui/icons-material/Psychology';
import Check from "@mui/icons-material/Check";

// Components
import { InputCustom } from "../../components";
import IcCredits from "../../assets/icon/credits";

// Context
import {
    cvsCollectionRef,
    useFirebase,
    functions,
    firestore,
} from "../../contexts/FirebaseProvider";

// Utils
import trackClickEvent from "../../utils/trackClickEvent";
import { usePaywall } from "../../contexts/PaywallProvider";
//import axios from "axios";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { dashboardBaseUrl } from "../../config/baseUrls";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { useMediaQuery, Grid } from "@mui/material";
// Initial Values
const initialValues = {
    summary: "",
    profession: "",
};
// accordion
const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    // "&:not(:last-child)": {
    //   borderBottom: 0
    // },
    "&:before": {
        display: "none"
    }
}))

const AccordionSummary = styled(props => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)"
}))


function SummaryPage() {
    // State
    const params = useParams();
    // media query mui
    const matches = useMediaQuery('(min-width:600px)');
    const { user, openSignInDialog } = useFirebase();
    const { setTopUpDialog } = usePaywall();

    const { basicInfo: data, setBackdropLoading } = useOutletContext();
    const ref = doc(cvsCollectionRef, params.cvId);
    const { mutate: updateBasicInfo, isLoading: updateLoading } =
        useFirestoreDocumentMutation(ref, { merge: true });

    const [{ summary, profession }, setForm] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const [loadingAI, setLoadingAI] = useState(false);

    const creditDocRef = doc(firestore, "koin_user", user?.uid ?? user?.tempUID);
    const creditData = useFirestoreDocumentData(
        ["koin_user", user.uid],
        creditDocRef,
        {
            subscribe: true,
        }
    );

    const [expanded, setExpanded] = React.useState("panel1")

    const handleChange2 = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }


    // console.log(creditData?.data?.coin || 0);

    // snack
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (data) {
            setForm({ ...initialValues, ...data });
        }
    }, [data]);

    const handleChange = (e) => {
        const { value, name } = e.target;
        setForm((form) => ({
            ...form,
            [name]: value,
        }));

        setErrors((errors) => ({
            ...errors,
            [name]: "",
        }));
    };

    const handleUpdate = async () => {
        let findErrors = {};

        if (!summary) {
            findErrors.summary = "Ringkasan profesional wajib diisi.";
        }

        if (!profession) {
            findErrors.profession = "Nama Profesi wajib diisi.";
        }

        if (Object.values(findErrors).some((message) => message !== "")) {
            setErrors(findErrors);
        } else {
            updateBasicInfo(
                {
                    summary,
                    profession,
                    updatedAt: serverTimestamp(),
                },
                {
                    onSuccess() {
                        trackClickEvent("Summary Button Clicked", {
                            userId: user?.uid,
                            tempUID: user?.tempUID,
                        });
                        enqueueSnackbar("Summary berhasil disimpan.", {
                            variant: "success",
                        });
                    },
                }
            );
            // pixel
            //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_summary&email=${userData?.email}`);

        }
    };

    // snackbar action
    const { mutateAsync: reqCustomToken, isLoading: isLoadingCustomToken } = useFunctionsCall(functions, "user-reqCustomToken");

    const action = snackbarId => (
        <>
            <Button onClick={async () => {
                const response = await reqCustomToken()

                window.location.href = `${dashboardBaseUrl}/login-by-token/${response?.customToken}/myCredit`
            }}>
                Riwayat Penggunaan Coin
            </Button>

        </>
    );
    // Handle AI Assistant
    const [aiSummary, setAiSummary] = useState("");
    const handleAi = async () => {
        try {
            setLoadingAI(true);
            setAiSummary("");

            const aiWriter = httpsCallable(functions, "cv-aiwriter_summary");
            const res = await aiWriter({
                cvId: params?.cvId,
                jobTitle: profession,
            });

            // setForm((form) => ({
            //     ...form,
            //     summary: res?.data?.data,
            // }));
            setAiSummary(res?.data?.data);

            // console.log(res?.data?.data);

            if (res?.data?.data) {
                setLoadingAI(false);
            }
            enqueueSnackbar(`Anda telah menggunakan 3 coin,`, {
                action,
                autoHideDuration: 5000
            })
        } catch (error) {
            if (!profession) {
                setErrors({
                    profession: "Nama profesi belum diisi",
                    summary:
                        "Kamu harus mengisi kolom nama profesi terlebih dahulu untuk menggunakan asisten AI",
                });
            }
            else if (!data?.lang?.value) {
                setErrors({
                    ...errors,
                    summary:
                        "Kamu harus memilih bahasa CV terlebih dahulu di halaman Basic Info untuk menggunakan asisten AI",
                });
            }
            else if (error.message === 'Koin Anda Kurang') {
                setTopUpDialog(true);
            }

            console.log(error);

            setLoadingAI(false);
        }
    };

    const handleSetSummary = () => {
        setForm((form) => ({
            ...form,
            summary: summary.concat(summary ? "\n" + aiSummary : aiSummary),
        }));
        setAiSummary("");
    };

    useEffect(() => {
        if (isLoadingCustomToken) {
            setBackdropLoading(true)
        }
    }, [isLoadingCustomToken, setBackdropLoading])
    // console.log(user);

    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
               {/* Input Summary*/}
                <InputCustom
                    title="Tulis Nama Profesi"
                    placeholder="Web Designer"
                    name="profession"
                    onChange={handleChange}
                    value={profession}
                    error={errors?.profession ? true : false}
                    helperText={errors?.profession}
                />

                <Box
                    sx={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        color: "#993300",


                    }}
                >
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={
                            loadingAI ? (
                                <CircularProgress size={18} color="inherit" />
                            ) : (
                                <PsychologyIcon />
                            )
                        }
                        disableElevation
                        disabled={loadingAI}
                        sx={{
                            backgroundColor: "#993300",
                            borderRadius: 0,
                            "&:hover": {
                                backgroundColor: "#782800",
                            },
                            "&[disabled]": {
                                color: "#FFF",
                                backgroundColor: "#993300",
                            },
                        }}
                        onClick={user?.uid ? handleAi : openSignInDialog}
                    >
                        Asisten AI
                    </Button>
                    {/* <div style={{ margin: '5px 5px 0px 3px', color: "#993300" }}> */}
                    {user?.uid && <>
                        <p style={{ fontSize: matches ? 15 : 10, marginRight: '-7px' }}>Harga = 3</p>
                        <IcCredits fontSize="10px" /> |

                        <p style={{ fontSize: matches ? 15 : 10 }}>Coin Saya</p>
                        <p style={{ fontSize: matches ? 15 : 10, marginRight: '-7px' }}>{creditData?.data?.coin || 0}</p>
                        <IcCredits fontSize="10px" /></>
                    }

                    {/* </Box> */}

                    {/* <p style={{ fontSize: 12 }}>Coin Saya</p> */}

                </Box>

                {aiSummary && (
                    <div
                        style={{
                            userSelect: "none",
                            color: "#7C8493",
                            fontSize: "15px",
                        }}
                    >
                        <TypewriterComponent
                            options={{
                                strings: aiSummary,
                                autoStart: true,
                                // loop: true,
                                cursor: "",
                                delay: 50,
                            }}
                        />
                    </div>
                )}
                <Button
                    // variant="contained"
                    size="small"
                    startIcon={<Check />}
                    color="success"
                    disableElevation
                    disabled={!aiSummary}
                    sx={{
                        marginLeft: "30px",
                        borderRadius: 0,
                    }}
                    onClick={handleSetSummary}
                >
                    Terapkan
                </Button>
                {/* Input Summary*/}
                <InputCustom
                    title="Tulis Ringkasan Profesional"
                    placeholder="Seorang web designer handal dengan pengalaman 15 tahun lebih mengerjakan berbagai enterprise level proyek."
                    name="summary"
                    onChange={handleChange}
                    value={summary}
                    disabled={loadingAI}
                    multiline
                    rows={5}
                    error={errors?.summary ? true : false}
                    helperText={errors?.summary}
                />
                <Button
                sx={{ marginY: 2, marginBottom: "100px" }}
                variant="contained"
                fullWidth
                onClick={handleUpdate}
                disabled={updateLoading}
            >
                Simpan
            </Button>
            </Grid>
            <Grid item xs={12} md={4}>
                <Accordion sx={{ margin: '20px 0 95px' }} expanded={expanded === 'panel1'} onChange={handleChange2('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography sx={{ display: 'flex', alignItems: 'center', }}><YouTubeIcon sx={{ marginRight: '5px', color: '#f00' }} />Video Tutorial</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ width: '100%' }}>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/--qCSlQIxjs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
           
            

            
        </>
    );
}

export default SummaryPage;
