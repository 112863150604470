// React
import React, { useEffect, useState } from "react";

// MUI
import {
    Box,
    //TextField,
    Menu,
    MenuItem,
    Grid,
    //useTheme,
    //styled,
    //Typography,
    ListItemIcon,
    //IconButton,
    Button,
    ListItemText,
    //ListItem,
    //List,
    MenuList,
} from "@mui/material";

// MUI Icons
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
// import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

// React Router Dom
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Article, Download, PictureAsPdf } from "@mui/icons-material";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { functions, useFirebase } from "../contexts/FirebaseProvider";
import { saveAs } from "file-saver";
import trackClickEvent from "../utils/trackClickEvent";
import { usePaywall } from "../contexts/PaywallProvider";
import formatDate from "../utils/formatDate";
//import axios from "axios";
//import { Stack } from "@mui/system";
// import useStyles from './style';

// const StyledTextField = styled(TextField)({
//     "& .MuiSelect-select": {
//         display: "flex",
//         gap: 4,
//     },
// });

function NewPageJumper({
    pages,
    basicInfo,
    page,
    // nextPage,
    // prevPage,
    access,
    setBackdropLoading
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    //const theme = useTheme();

    const params = useParams();
    // const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.pathname.split('/')?.[3])
    // firebase and firestore things
    const { user, openSignInDialog } = useFirebase();
    const { mutate: cvDownload, isLoading: downloading } = useFunctionsCall(functions, 'cv-download');
    const { mutateAsync: purchaseCvAccess, isLoading: purchasing } = useFunctionsCall(functions, 'cv-purchaseCvAccess');
    // paywall
    const { setBuyDialog, setTopUpDialog } = usePaywall();

    useEffect(() => {
        setBuyDialog(curr => ({ ...curr, loading: purchasing }))
    }, [setBuyDialog, purchasing])

    useEffect(() => {

        setBackdropLoading(downloading);

    }, [downloading, setBackdropLoading])

    // const handleChange = (data) => {
    //     navigate(`${params.cvId}/${data}`);
    //     trackClickEvent("CV Bottom Navigation Option Clicked", { to: `${params.cvId}/${data}` })
    // };

    // const handlePrev = () => {
    //     navigate(`${params.cvId}/${prevPage}`)
    //     trackClickEvent("CV Prev Navigation Clicked", { to: `${params.cvId}/${prevPage}` })

    // }
    // const handleNext = () => {
    //     navigate(`${params.cvId}/${nextPage}`);
    //     trackClickEvent("CV Next Navigation Clicked", { to: `${params.cvId}/${nextPage}` })
    // }
    const handleCloseDownload = () => {
        setAnchorEl(null);
    }
    const handleDownload = (event) => {
        if (!user?.uid) {
            return openSignInDialog();
        }
        setAnchorEl(event.currentTarget);

    }
    const handleDownloadPDF = async () => {
        cvDownload({
            cvId: params.cvId,
            type: 'pdf'
        },
            {
                onSuccess(data) {
                    fetch(`data:application/pdf;base64,${data.base64Design}`)
                        .then(res => res.blob())
                        .then((blob) => {
                            saveAs(blob, `${basicInfo?.title ?? "cv-download"}.pdf`);
                        })

                }
            }
        )


        handleCloseDownload()
        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_downloadPDF&email=${userData?.email}`);

        trackClickEvent("Download CV PDF Clicked", {
            cvId: params.cvId,
            type: 'pdf'
        })
    }
    const handleDownloadDocx = async () => {
        cvDownload({
            cvId: params.cvId,
            type: 'docx'
        },
            {
                onSuccess(data) {
                    fetch(`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${data.base64Design}`)
                        .then(res => res.blob())
                        .then((blob) => {
                            saveAs(blob, `${basicInfo?.title ?? "cv-download"}.docx`);
                        })
                }
            }
        )
        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_downloadDocx&email=${userData?.email}`);


        trackClickEvent("Download CV DOCX Clicked", {
            cvId: params.cvId,
            type: 'docx'
        })
    }

    const handleBuyDownloadDocx = async () => {
        try {
            const purchaseReq = await purchaseCvAccess({
                cvId: params.cvId,
                type: 'docx'
            })

            if (purchaseReq.status === 'success') {
                handleDownloadDocx();
                setBuyDialog({
                    open: false,
                    name: "",
                    price: 0,
                });
            }
        } catch (e) {
            setBuyDialog({
                open: false,
                name: "",
                price: 0,
            });
            setTopUpDialog(true);
        }
    }

    const handleClickDownloadDocx = () => {
        if (!access?.docx) {
            setBuyDialog({ open: true, name: `Akses download file DOCX untuk ${basicInfo?.title ?? "Untitled CV "} (${formatDate(basicInfo.createdAt?.toDate?.())}) selamanya.`, price: 10, callFunction: handleBuyDownloadDocx, loading: purchasing, enable: access?.docx })
        } else {
            handleDownloadDocx();
        }
        handleCloseDownload();
    }

    // const [anchorEl2, setAnchorEl2] = React.useState(null)
    // const openMe = Boolean(anchorEl2)
    // const handleClick = event => {
    // setAnchorEl2(event.currentTarget)
    // }
    // const handleClose = () => {
    // setAnchorEl2(null)
    // }



    return (
        <Box
            sx={{
                width: "fit-content",
                background: "#000",
                margin: "15px auto",
                // padding: "10px",
                // position: "fixed",
                left: 0,
                // bottom: "-15px",
                borderTop: "solid 1px #ddd",
                borderRadius: 10,
                // padding: '0 15px'
            }}
        >
            <Grid justifyContent="center" alignItems="center" container marginLeft="auto" marginRight="auto">
                {/* <Grid justifyContent="center" item xs sx={{ display: "flex", }}>
                    <IconButton disabled={!prevPage} onClick={handlePrev} ><ChevronLeftRoundedIcon /></IconButton>
                </Grid> */}
                <Grid item xs={12}>

                    {page === 'download' ?
                        <>
                            <Box sx={{ position: 'relative' }}>
                                {/* mobile nav */}

                                {/* desktop nav */}
                                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', } }}>
                                    <MenuList >
                                        {pages?.map((option) => {
                                            const currentPath = location.pathname.split('/')?.[3]
                                            const matchPath = currentPath === option?.value
                                            // let counter = basicInfo?.[option?.counterField] ?? 0;
                                            // if (option?.counterField === 'fullName' && basicInfo?.[option?.counterField]) {
                                            //     counter = 1;
                                            // } else if (option?.counterField === 'summary' && basicInfo?.[option?.counterField]) {
                                            //     counter = 1;
                                            // }
                                            // if (counter > 0) {

                                            return <MenuItem
                                                sx={{
                                                    display: 'inline-block',
                                                    color: matchPath > 0 ? '#f26722' : '#fff'
                                                }}
                                                onClick={() => {
                                                    navigate(`${params.cvId}/${option?.value}`);
                                                    trackClickEvent("CV Bottom Navigation Option Clicked", { to: `${params.cvId}/${option?.value}` })

                                                }}

                                            >
                                                {option?.label}
                                            </MenuItem>
                                            // }



                                        })}

                                    </MenuList>
                                </Box>
                            </Box>
                            <Button
                                onClick={handleDownload}
                                variant="contained"
                                fullWidth
                                startIcon={<Download />}
                                disabled={downloading}
                                sx={{ display: 'none', visibility: 'hidden' }}
                            >
                                Download
                            </Button>
                            <Menu
                                id="download-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseDownload}
                                MenuListProps={{
                                    'aria-labelledby': 'download-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={handleClickDownloadDocx}>
                                    <ListItemIcon>
                                        <Article />
                                    </ListItemIcon>
                                    <ListItemText>
                                        DOCX Document ({access?.docx ? 'Purchased' : `10 Kredit`})
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleDownloadPDF} >
                                    <ListItemIcon>
                                        <PictureAsPdf />
                                    </ListItemIcon>
                                    <ListItemText>
                                        PDF Document
                                    </ListItemText>

                                </MenuItem>
                            </Menu>
                            {/* <Backdrop
                sx={{ color: '#fff', zIndex: 9999 }}
                open={downloading}
              >
                <CircularProgress color="inherit" />
              </Backdrop> */}
                        </>
                        :
                        <Box sx={{ position: 'relative' }}>
                            {/* mobile nav */}

                            {/* desktop nav */}
                            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', } }}>
                                <MenuList >
                                    {pages?.map((option) => {
                                        const currentPath = location.pathname.split('/')?.[3]
                                        const matchPath = currentPath === option?.value
                                        // let counter = basicInfo?.[option?.counterField] ?? 0;
                                        // if (option?.counterField === 'fullName' && basicInfo?.[option?.counterField]) {
                                        //     counter = 1;
                                        // } else if (option?.counterField === 'summary' && basicInfo?.[option?.counterField]) {
                                        //     counter = 1;
                                        // }
                                        // if (counter > 0) {

                                        return <MenuItem
                                            sx={{
                                                display: 'inline-block',
                                                color: matchPath > 0 ? '#f26722' : '#fff'
                                            }}
                                            onClick={() => {
                                                navigate(`${params.cvId}/${option?.value}`);
                                                trackClickEvent("CV Bottom Navigation Option Clicked", { to: `${params.cvId}/${option?.value}` })

                                            }}

                                        >
                                            {option?.label}
                                        </MenuItem>
                                        // }



                                    })}

                                </MenuList>
                            </Box>
                        </Box>}

                    {/* </StyledTextField>} */}

                </Grid>
                {/* <Grid justifyContent="center" item xs sx={{ display: "flex", }}>
                    <IconButton disabled={!nextPage} onClick={handleNext}><ChevronRightRoundedIcon /></IconButton>
                </Grid> */}
            </Grid>
        </Box>
    );
}

export default NewPageJumper;
