// React Router Dom
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

// Pages
import {
  EducationPage,
  HomePage,
  ProjectPage,
  WorkExperiencePage,
  CVLayout,
  BasicInfoPage,
  SkillPage,
  OrganizationPage,
  AwardsPage,
} from "./pages";

// App
import App from "./App";
import CoursePage from "./pages/course";
import CertificationPage from "./pages/certification";
import LoginByToken from "./pages/login/token";
import SummaryPage from "./pages/summary";
import DownloadPage from "./pages/download";
import OptimizerPage from "./pages/optimizer";
import AuthHandler from "./pages/auth/handler";
import Recommender from "./pages/recommender";
import Signup from "./pages/signup";
import Signin from "./pages/signin";
import CVMenu from "./pages/cv-menu";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<HomePage />} />
      <Route path="signup" element={<Signup />} />
      <Route path="signin" element={<Signin />} />
      <Route path="login-by-token/:token" element={<LoginByToken />} />
      <Route path="login-by-token" element={<LoginByToken />} />
      <Route path="__/auth/handler" element={<AuthHandler />} />
      <Route path="optimizer" element={<OptimizerPage />} />
      <Route path="recommender" element={<Recommender />} />
      <Route path="cv-menu" element={<CVMenu />} />

      <Route path="cv" element={<CVLayout />}>

        <Route
          path=":cvId/basic-info"
          element={<BasicInfoPage />}
          handle={{ getJumperValue: () => "basic-info", next: () => 'work-experience' }}
        />
        <Route
          path=":cvId/work-experience"
          element={<WorkExperiencePage />}
          handle={{ getJumperValue: () => "work-experience", next: () => 'project', prev: () => 'basic-info' }}
        />
        <Route
          path=":cvId/project"
          element={<ProjectPage />}
          handle={{ getJumperValue: () => "project", next: () => 'education', prev: () => 'work-experience' }}
        />
        <Route
          path=":cvId/education"
          element={<EducationPage />}
          handle={{ getJumperValue: () => "education", next: () => 'certification', prev: () => 'project' }}
        />
        <Route
          path=":cvId/certification"
          element={<CertificationPage />}
          handle={{ getJumperValue: () => "certification", next: () => 'course', prev: () => 'education' }}
        />
        <Route
          path=":cvId/course"
          element={<CoursePage />}
          handle={{ getJumperValue: () => "course", next: () => 'organization', prev: () => 'certification' }}
        />

        <Route
          path=":cvId/organization"
          element={<OrganizationPage />}
          handle={{ getJumperValue: () => "organization", next: () => 'skill', prev: () => 'course' }}
        />
        <Route
          path=":cvId/skill"
          element={<SkillPage />}
          handle={{ getJumperValue: () => "skill", next: () => 'award', prev: () => 'organization' }}
        />

        <Route
          path=":cvId/award"
          element={<AwardsPage />}
          handle={{ getJumperValue: () => "award", next: () => 'summary', prev: () => 'skill' }}
        />

        <Route
          path=":cvId/summary"
          element={<SummaryPage />}
          handle={{ getJumperValue: () => "summary", next: () => 'download', prev: () => 'award' }}
        />

        <Route
          path=":cvId/download"
          element={<DownloadPage />}
          handle={{ getJumperValue: () => "download", prev: () => 'summary' }}
        />
      </Route>
    </Route>
  )
);

export default router;
