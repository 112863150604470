import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { auth, useFirebase } from "../../contexts/FirebaseProvider";

export default function Signin() {
    const { user } = useFirebase()
    const [form, setForm] = useState({
        email: "",
        password: "",
        showPassword: false,
    });
    const [error, setError] = useState({
        email: "",
        password: "",
    })
    const handleClickShowPassword = () => {
        setForm({ ...form, showPassword: !form.showPassword });
    };

    const [isSubmitting, setSubmitting] = useState(false);

    //   On change
    const handleChange = (e) => {
        const { name, value } = e.target;

        // if (value === "") {
        //   setSubmitting(true);
        // } else {
        //   setSubmitting(false);
        // }

        setForm({
            ...form,
            [name]: value,
        });

        setError({
            ...error,
            [name]: "",
        });
    };

    const validate = () => {
        const newError = { ...error };
        const { email, password, } = form;

        if (!email) {
            newError.email = "Email wajib di isi";
        } else if (!isEmail(email)) {
            newError.email = "Email tidak valid";
        }

        if (!password) {
            newError.password = "Password wajib di isi";
        }
        if (password?.length < 6) {
            newError.password = "Password terlalu lemah";
        }
        // if (!passwordConfirm) {
        //     newError.passwordConfirm = "Masukkan ulang password wajib diisi"
        // }
        // if (passwordConfirm !== password) {
        //     newError.passwordConfirm = "Password tidak sama"
        // }
        return newError;
    };
    const handleSignin = async (e) => {
        e.preventDefault();
        const findErrors = validate();
        if (Object.values(findErrors).some((err) => err !== "")) {
            setError(findErrors);
        } else {
            try {
                setSubmitting(true)
                const createUser = await signInWithEmailAndPassword(auth, form?.email, form?.password)
                console.log(createUser)
                setSubmitting(false)
            } catch (e) {
                setSubmitting(false)

                console.log(e.message)
                const newError = {};

                switch (e.code) {
                    case "auth/user-not-found":
                        newError.email = "Alamat Email Tidak Terdaftar";
                        break;
                    case "auth/invalid-email":
                        newError.email = "Alamat Email Tidak Valid";
                        break;
                    case "auth/wrong-password":
                        newError.password = "Kata Sandi Salah";
                        break;
                    case "auth/user-disabled":
                        newError.email = "User Di Blokir";
                        break;
                    default:
                        newError.email = "Terjadi Kesalahan Silahkan Coba Lagi";
                        break;
                }
                setError(newError);
            }
        }
    }
    console.log(user)
    if (user?.uid !== null) {
        return <Navigate to='/' replace={true} />
    }
    return (
        <Box>
            <div className=''>
                <p className='{classes.label}' style={{ margin: 0 }}>Email</p>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Masukkan Email Anda"
                    id="email"
                    name="email"
                    // className={classes.textField}
                    value={form.email}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    helperText={error?.email}
                    error={error?.email ? true : false}
                />
                {/* {error?.email && <p className={classes.error}>{error?.email}</p>} */}
            </div>
            <div className='' style={{
                marginTop: '20px'
            }}>
                <div className='{classes.passw}'>
                    <p className='{classes.label}' style={{ margin: 0 }}>
                        Password
                    </p>
                    {/* <Button
                        color="primary"
                        className='{classes.forgetBtn}'
                        // onClick={() => props.history.push("/lupapassword")}
                    >
                        Lupa Password ?
                    </Button> */}
                </div>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Masukkan Password Anda"
                    id="password"
                    name="password"
                    // className={classes.textField}
                    value={form.password}
                    type={form.showPassword ? "text" : "password"}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    helperText={error?.password}
                    error={error?.password ? true : false}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    aria-label="toggle password visibilityOutlined"
                                    onClick={handleClickShowPassword}
                                >
                                    {form.showPassword ? (
                                        <VisibilityOutlined />
                                    ) : (
                                        <VisibilityOffOutlined />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {/* {error?.password && (
                    <p className={classes.error}>{error?.password}</p>
                )} */}
            </div>

            <div className='' style={{
                marginTop: '50px'
            }}>
                <Button
                    // className={classes.loginBtn}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={handleSignin}
                >
                    Masuk
                </Button>
            </div>
            <div style={{
                display: 'flex'
            }}>
                <p>belum punya akun?</p>
                <span
                    style={{
                        color: "#F26722",
                        cursor: "pointer",
                        textDecoration: "underline",
                        marginTop: '15px',
                        marginLeft: '5px'
                    }}
                    onClick={() => {
                        window.location.pathname = '/signup'
                    }}
                >
                    Daftar disini
                </span>
            </div>
        </Box>
    )
}