import desain1WithImg from './EN/desain-1-with-img.png';
import desain1WithoutImg from './EN/desain-1-without-img.png';
import desain2WithImg from './EN/desain-2-with-img.png';
import desain2WithoutImg from './EN/desain-2-without-img.png';
import desain3WithImg from './EN/desain-3-with-img.png';
import desain3WithoutImg from './EN/desain-3-without-img.png';
import desain4WithImg from './EN/desain-4-with-img.png';
import desain4WithoutImg from './EN/desain-4-without-img.png';
import desain5WithImg from './EN/desain-5-with-img.png';
import desain5WithoutImg from './EN/desain-5-without-img.png';
import desain6WithImg from './EN/desain-6-with-img.png';
import desain6WithoutImg from './EN/desain-6-without-img.png';
import desain7WithImg from './EN/desain-7-with-img.png';
import desain7WithoutImg from './EN/desain-7-without-img.png';
import desain8WithImg from './EN/desain-8-with-img.png';
import desain8WithoutImg from './EN/desain-8-without-img.png';
import desain1WithImgID from './ID/desain-1-with-img.png';
import desain1WithoutImgID from './ID/desain-1-without-img.png';
import desain2WithImgID from './ID/desain-2-with-img.png';
import desain2WithoutImgID from './ID/desain-2-without-img.png';
import desain3WithImgID from './ID/desain-3-with-img.png';
import desain3WithoutImgID from './ID/desain-3-without-img.png';
import desain4WithImgID from './ID/desain-4-with-img.png';
import desain4WithoutImgID from './ID/desain-4-without-img.png';
import desain5WithImgID from './ID/desain-5-with-img.png';
import desain5WithoutImgID from './ID/desain-5-without-img.png';
import desain6WithImgID from './ID/desain-6-with-img.png';
import desain6WithoutImgID from './ID/desain-6-without-img.png';
import desain7WithImgID from './ID/desain-7-with-img.png';
import desain7WithoutImgID from './ID/desain-7-without-img.png';
import desain8WithImgID from './ID/desain-8-with-img.png';
import desain8WithoutImgID from './ID/desain-8-without-img.png';

const thumbs = {

    EN: [
        {
            id: 1,
            label: 'Desain 1',
            withImg: desain1WithImg,
            withoutImg: desain1WithoutImg
        },
        {
            id: 2,
            label: 'Desain 2',
            withImg: desain2WithImg,
            withoutImg: desain2WithoutImg
        },
        {
            id: 3,
            label: 'Desain 3',
            withImg: desain3WithImg,
            withoutImg: desain3WithoutImg
        },
        {
            id: 4,
            label: 'Desain 4',
            withImg: desain4WithImg,
            withoutImg: desain4WithoutImg
        },
        {
            id: 5,
            label: 'Desain 5',
            withImg: desain5WithImg,
            withoutImg: desain5WithoutImg
        },
        {
            id: 6,
            label: 'Desain Pro 1',
            withImg: desain6WithImg,
            withoutImg: desain6WithoutImg
        },
        {
            id: 7,
            label: 'Desain Pro 2',
            withImg: desain7WithImg,
            withoutImg: desain7WithoutImg
        },
        {
            id: 8,
            label: 'Desain Pro 3',
            withImg: desain8WithImg,
            withoutImg: desain8WithoutImg
        }
    ],
    ID: [
        {
            id: 1,
            label: 'Desain 1',
            withImg: desain1WithImgID,
            withoutImg: desain1WithoutImgID
        },
        {
            id: 2,
            label: 'Desain 2',
            withImg: desain2WithImgID,
            withoutImg: desain2WithoutImgID
        },
        {
            id: 3,
            label: 'Desain 3',
            withImg: desain3WithImgID,
            withoutImg: desain3WithoutImgID
        },
        {
            id: 4,
            label: 'Desain 4',
            withImg: desain4WithImgID,
            withoutImg: desain4WithoutImgID
        },
        {
            id: 5,
            label: 'Desain 5',
            withImg: desain5WithImgID,
            withoutImg: desain5WithoutImgID
        },
        {
            id: 6,
            label: 'Desain Pro 1',
            withImg: desain6WithImgID,
            withoutImg: desain6WithoutImgID
        },
        {
            id: 7,
            label: 'Desain Pro 2',
            withImg: desain7WithImgID,
            withoutImg: desain7WithoutImgID
        },
        {
            id: 8,
            label: 'Desain Pro 3',
            withImg: desain8WithImgID,
            withoutImg: desain8WithoutImgID
        }
    ]
};

export default thumbs;