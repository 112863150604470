// React
import React, { useState } from "react";

// MUI
import { Box, Button, IconButton } from "@mui/material";

// MUI Icons
import EditIcon from "@mui/icons-material/EditOutlined";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { Add, DeleteOutlined } from "@mui/icons-material";
import AlertDialog from "./AlertDialog";
import { formatDateToYear } from "../utils/formatDate";
import get from "lodash/get";
import trackClickEvent from "../utils/trackClickEvent";

// const activeSx = { borderBottom: "solid 1px #ddd", borderLeft: "solid 2px #D6DDEB", backgroundColor: '#F8F8FD' };
const activeSx = {
  borderTop: "solid 1px #ddd",
  borderBottom: "solid 1px #ddd",
  borderLeft: "solid 2px #F26722",
  // marginY: 1,
};

function formatContent(content, type, last) {
  switch (type) {
    case "year":
      return formatDateToYear(content?.toDate?.());
    case "arrayOfString":
      return `${content}${last ? "," : ""}`;
    case "string":
    default:
      return content;
  }
}

function SavedItem({
  activeId,
  type,
  list,
  titleField,
  subtitleField,
  handleAdd,
  handleDelete,
  addLoading,
}) {
  const [selectedId, setSelectedId] = useState("default");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleCloseConfirmDelete = () => {
    setConfirmDeleteOpen(false)
    trackClickEvent(`Cancel Delete ${type} Clicked`)
  };

  const handleOpenConfirmDelete = (item) => (e) => {
    setSelectedId(item.id);
    setConfirmDeleteOpen(true);
    trackClickEvent(`Icon Delete ${type} Clicked`)
  };
  const handleDeleteCV = () => {
    setDeleteLoading(true);
    handleDelete?.(selectedId)?.finally?.(() => {
      setDeleteLoading(false);
      setConfirmDeleteOpen(false);
    });

    trackClickEvent(`Yes Delete ${type} Clicked`)
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "15px 0",
        }}
      >
        {/* <Accordion sx={{ bgcolor: "#F8F8FD", }} expanded={isExpanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"

        > */}
        <Box sx={{ display: "flex", alignItems: "center", flexFlow: 'row wrap' }}>
          <Typography sx={{ flex: 1,
          '@media(max-width: 600px)':{ width: '100%', textAlign: 'center' } }}>
            Daftar {type} ({list?.length ?? 0})
          </Typography>
          <Button
            variant="contained"
            disabled={addLoading || list?.length >= 5}
            onClick={() => {
              handleAdd();
              trackClickEvent(`Icon Add ${type} Clicked`)

            }}
            aria-label="add"
            color="primary"
            sx={{ '@media(max-width: 600px)':{ width: '100%', margin: '10px auto' } }}
          >
            <Add sx={{ width: '16px', height: '16px', margin: '-5px 5px 0 0' }} /> Tambah Baru
          </Button>
        </Box>
        {/* </AccordionSummary>
        <AccordionDetails> */}
        {list?.length > 0 ? (
          <List
            sx={{
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
              padding: 0,
            }}
          >
            {list?.map?.((item) => {
              return (
                <ListItem
                  key={item.id}
                  sx={activeSx}
                  secondaryAction={
                    <>
                      <IconButton
                        component={Link}
                        to={{
                          search: `?id=${item.id}`,
                        }}
                        aria-label="comment"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={handleOpenConfirmDelete(item)}
                        aria-label="comment"
                      >
                        <DeleteOutlined />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{ fontSize: "14px", margin: 0 }}
                        component="h5"
                        color="#7C8493"
                      >
                        {Array.isArray(titleField)
                          ? titleField?.map((value, index) => {
                            if (Array.isArray(value)) {
                              const [field, type, separator] = value;
                              const content = formatContent(
                                get(item, field),
                                type,
                                index === titleField?.length
                              );
                              return (
                                <span key={index}>
                                  {content}
                                  {separator}
                                </span>
                              );
                            }

                            return <span key={index}>{item?.[value]}</span>;
                          })
                          : get(item, titleField) ?? "Untitled"}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        sx={{ display: "inline", fontSize: "12px", margin: 0 }}
                        component="span"
                        color="#A8ADB7"
                      >
                        {Array.isArray(subtitleField)
                          ? // item?.[subtitleField?.[0]]?.[subtitleField?.[1]]
                          subtitleField?.map((value, index) => {
                            if (Array.isArray(value)) {
                              const [field, type, separator] = value;
                              const content = formatContent(
                                get(item, field),
                                type
                              );
                              return (
                                <span key={index}>
                                  {content}
                                  {separator}
                                </span>
                              );
                            }

                            return <span key={index}>{item?.[value]}</span>;
                          })
                          : get(item, subtitleField) ?? "No Subtitile"}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography>Silakan tambahkan {type} yang Anda miliki!</Typography>
        )}
        {/* </AccordionDetails>
      </Accordion> */}
      </Box>

      <AlertDialog
        open={confirmDeleteOpen}
        handleClose={handleCloseConfirmDelete}
        handleYes={handleDeleteCV}
        title={`Hapus ${type}`}
        loading={deleteLoading}
        description={`Anda yaking ingin menghapus ${type} ini?`}
      />
    </>
  );
}

export default SavedItem;
