// Material UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Button,
} from "@mui/material";

function DialogJob({ open, handleClose, listJob, handleSelect }) {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Pilih Job Trending</DialogTitle>
      <DialogContent>
        <List>
          {listJob?.map((item, idx) => {
            return (
              <ListItem
                key={idx}
                button
                onClick={() =>
                  handleSelect({
                    title: item?.jobtitle,
                    desc: item?.jobdesc,
                  })
                }
              >
                <ListItemText primary={item?.jobtitle} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogJob;
