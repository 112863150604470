// React
import React, { useEffect, useRef, useState } from "react";

// Components
import { InputCustom, DateRangeCustom, SavedItem } from "../../components";

// React Router Dom
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

// Context
import { subCollectionRef } from "../../contexts/FirebaseProvider";

// React Query
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
  useFirestoreDocumentMutation,
  useFirestoreDocumentData,
} from "@react-query-firebase/firestore";

// Draft JS
// import { EditorState, convertToRaw, ContentState } from "draft-js";

// // Draft To HTML
// import draftToHtml from "draftjs-to-html";

// // HTML To Draft
// import htmlToDraft from "html-to-draftjs";

// Firebase
import { deleteDoc, serverTimestamp } from "firebase/firestore";

// Bottom Sheet
import { BottomSheet } from "react-spring-bottom-sheet";

// MUI
import { Button, Container, Typography, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
//import axios from "axios";
import TutorialVideo from "../../components/TutorialVideo";

// Initial State
let initialState = {
  projectName: "",
  organization: "",
  startDate: null,
  endDate: null,
  projectDescription: "", //EditorState.createEmpty(),
};

let subCollection = "projects";
const title = "Proyek";
function Project() {
  // Context
  const { docRef } = useOutletContext();

  //const { userData } = useFirebase();

  // Params
  const params = useParams();

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id") ?? "default";

  // Navigate
  const navigate = useNavigate();

  // Ref
  const ref = subCollectionRef(params.cvId, subCollection);

  // Mutate
  const { mutate: addProject, isLoading: addLoading } =
    useFirestoreCollectionMutation(ref);

  const { mutate: updateProject, isLoading: updateLoading } =
    useFirestoreDocumentMutation(docRef(ref, id), {
      merge: true,
    });

  // Get Data
  const { data: list } = useFirestoreQueryData(
    [subCollection, params.cvId],
    ref,
    {
      subscribe: true,
      idField: "id",
    }
  );

  const { data: item } = useFirestoreDocumentData(
    [subCollection, id],
    docRef(ref, id),
    {
      subscribe: true,
      idField: "id",
    }
  );

  // State
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("create");

  const [bottomsheetOpen, setBottomSheetOpen] = useState(false);

  // Ref
  const bottomsheetRef = useRef();

  // snack
  const { enqueueSnackbar } = useSnackbar();

  // Handler
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
    setErrors((errors) => ({
      ...errors,
      [name]: "",
    }));
  };

  const handleDateChange = (value, type) => {
    if (type === "start") {
      setForm((form) => ({
        ...form,
        startDate: value,
      }));
      setErrors((errors) => ({
        ...errors,
        startDate: "",
      }));
    } else {
      setForm((form) => ({
        ...form,
        endDate: value,
      }));
      setErrors((errors) => ({
        ...errors,
        endDate: "",
      }));
    }
  };

  const handleDismissButtomsheet = () => {
    navigate({
      search: "",
    });
  };

  const handleAdd = () => {
    if (addLoading) {
      return;
    }

    navigate({
      search: `?id=new`,
    });
  };

  const handleUpdate = async () => {
    if (updateLoading) {
      return;
    }

    const {
      projectName,
      organization,
      startDate,
      endDate,
      projectDescription,
    } = form;

    let findErrors = {};

    if (!projectName) {
      findErrors.projectName = "Nama proyek wajib diisi.";
    }

    if (!organization) {
      findErrors.organization = "Nama organisasi wajib diisi.";
    }

    if (!startDate) {
      findErrors.startDate = "Tanggal mulai wajib diisi.";
    }

    if (!endDate) {
      findErrors.endDate = "Tanggal berhenti wajib diisi.";
    }

    if (!projectDescription) {
      findErrors.projectDescription = "Deskripsi proyek wajib diisi";
    }
    if (Object.values(findErrors).some((message) => message !== "")) {
      setErrors(findErrors);
    } else {
      if (mode === "create") {
        addProject(
          {
            projectName,
            organization,
            startDate,
            endDate,
            projectDescription,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess(data) {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
          }
        );
      } else {
        updateProject(
          {
            projectName,
            organization,
            startDate,
            endDate,
            projectDescription,
            updatedAt: serverTimestamp(),
          },
          {
            onSuccess() {
              handleDismissButtomsheet();
              setForm(initialState);
              enqueueSnackbar(`${title} berhasil disimpan.`, {
                variant: "success",
              });
            },
          }
        );

        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=cvmaker_addProject&email=${userData?.email}`);

      }
    }
  };

  // UseEffect
  useEffect(() => {
    if (item) {
      const { startDate, endDate, ...prev } = item;

      // const contentBlock = htmlToDraft(projectDescription || "");
      // let savedEditorState = EditorState.createEmpty();
      // if (contentBlock) {
      //   const contentState = ContentState.createFromBlockArray(
      //     contentBlock.contentBlocks
      //   );
      //   savedEditorState = EditorState.createWithContent(contentState);
      // }

      setForm({
        ...initialState,
        ...prev,
        startDate: startDate?.toDate?.() ?? null,
        endDate: endDate?.toDate?.() ?? null,
      });
    }
  }, [item]);

  useEffect(() => {
    if (id === "new") {
      setMode("create");
    } else if (id !== "default") {
      setMode("update");
    }
  }, [id]);

  useEffect(() => {
    if (id !== "default") {
      setBottomSheetOpen(true);
    } else {
      setBottomSheetOpen(false);
    }
  }, [id]);

  const handleDelete = async (selectedId) => {
    return await deleteDoc(docRef(ref, selectedId));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {/* Saved Item */}
          <SavedItem
            type={title}
            list={list}
            titleField="projectName"
            subtitleField="organization"
            activeId={id}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            addLoading={addLoading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TutorialVideo src="https://www.youtube.com/embed/Zqz4Kwl_ae4" />
        </Grid>
      </Grid>


      {/* Bottom Sheet */}
      <BottomSheet
        initialFocusRef={false}
        open={bottomsheetOpen}
        ref={bottomsheetRef}
        onDismiss={handleDismissButtomsheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.9]}
        header={
          <Typography>
            {mode === "update" ? "Edit" : "Tambah"} {title}
          </Typography>
        }
        footer={
          <Button
            variant="contained"
            fullWidth
            className="w-full"
            onClick={handleUpdate}
            disabled={updateLoading || addLoading}
          >
            Simpan
          </Button>
        }
      >
        <Container maxWidth="xs">
          {/* Input Nama Projek */}
          <InputCustom
            title="Nama proyek yang telah dikerjakan"
            placeholder="Profil Perusahaan"
            name="projectName"
            onChange={handleChange}
            value={form.projectName}
            required
            error={errors?.projectName ? true : false}
            helperText={errors?.projectName}
          />

          {/* Input Organisasi */}
          <InputCustom
            title="Dalam Organisasi apa?"
            placeholder="Google"
            name="organization"
            onChange={handleChange}
            value={form.organization}
            required
            error={errors?.organization ? true : false}
            helperText={errors?.organization}
          />

          {/* Input Range */}
          <DateRangeCustom
            title="Kapan anda mengerjakan proyek tersebut?"
            onChangeStart={(value) => handleDateChange(value, "start")}
            valueStart={form.startDate}
            onChangeEnd={(value) => handleDateChange(value, "end")}
            valueEnd={form.endDate}
            errorStart={errors?.startDate ? true : false}
            errorEnd={errors?.endDate ? true : false}
            helperTextStart={errors?.startDate}
            helperTextEnd={errors?.endDate}
          />

          {/* Input Rich Text */}
          {/* <RichTextEditorCustom
            title="Jelaskan apa saja yang anda kerjakan"
            editorState={form.projectDescription}
            onEditorStateChange={(value) => {
              setForm((form) => ({
                ...form,
                projectDescription: value,
              }));
            }}
          />
           */}
          {/* Input Job Desc*/}
          <InputCustom
            title="Jelaskan apa saja yang anda kerjakan"
            placeholder="Merancang enterpise level profil perusahaan..."
            name="projectDescription"
            onChange={handleChange}
            value={form.projectDescription}
            multiline
            rows={5}
            error={errors?.projectDescription ? true : false}
            helperText={errors?.projectDescription}
          // disabled={loading}
          />
        </Container>
      </BottomSheet>
    </>
  );
}

export default Project;
